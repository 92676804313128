import { JsonArray } from 'type-fest';
import * as z from 'zod';

export const UserAccessKeyElementSchema = z.object({
    id: z.number(),
    name: z.string(),
    createdAt: z.string().pipe(z.coerce.date()),
    lastUsed: z.union([z.string().pipe(z.coerce.date()), z.null()]).optional(), // null or date
    enabled: z.boolean(),
    code: z.string().optional(),
});

export type UserAccessKeyElement = z.infer<typeof UserAccessKeyElementSchema>;

export const parseUserAccessKey = (data: JsonArray): UserAccessKeyElement[] => {
    return z.array(UserAccessKeyElementSchema).parse(data);
};
