import { Stack } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { NodeItem } from '@/features/admin/components/nodes/NodeItem';

import { NodeType } from '../../types/nodeType';

interface NodesListProps {
    nodes: NodeType[];
}

export const NodesList: FunctionComponent<NodesListProps> = ({ nodes }) => {
    return (
        <Stack spacing="xs">
            {nodes.map((node) => (
                <NodeItem key={node.id} node={node} />
            ))}
        </Stack>
    );
};
