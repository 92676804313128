import React, { FC } from 'react';

import { useAdminStyles } from '@/features/admin/components/admin-styles';
import { JobsPanel } from '@/features/admin/components/jobs/JobsPanel';

export const AdminJobs: FC = () => {
    const { classes } = useAdminStyles();

    return (
        <div className={classes.container}>
            <JobsPanel />
        </div>
    );
};
