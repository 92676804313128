import { useQuery } from '@tanstack/react-query';

import { JobError } from '@/features/models/types/job-error';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { JOB_ERROR } from '@/types/query-keys';

export const getJobError = async (id: string): Promise<JobError> => {
    return (await axios.get(`/jobs/${id}/error`)).data;
};

type QueryFnType = typeof getJobError;

type UseJobErrorOptions = {
    id: string;
    config?: QueryConfig<QueryFnType>;
};

export const useJobError = ({ id, config }: UseJobErrorOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [JOB_ERROR, id],
        queryFn: () => getJobError(id),
        ...config,
    });
};
