import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';

import { getLicenseName, License } from '@/features/admin/types/license';

interface LicenseRowProps {
    license: License;
}

const getCreatedBy = (license: License) => {
    switch (license.createdBy) {
        case 'SYSTEM':
            return 'System';
        case 'ADMIN':
            return 'Admin';
        case 'VOUCHER':
            return 'Voucher';
        case 'PURCHASE':
            return 'Purchase';
        default:
            return 'Unknown';
    }
};

export const LicenseRow: FunctionComponent<LicenseRowProps> = ({ license }) => {
    const createdAt = dayjs(license.createdAt).format('YYYY-MM-DD HH:mm:ss');

    const { limits } = license;

    const validUntil = limits.finish ? dayjs(limits.finish).format('YYYY-MM-DD HH:mm:ss') : '∞';
    const imagesLimit = limits.images ? `${limits.used}/${limits.images}` : '∞';
    const createdBy = getCreatedBy(license);
    return (
        <tr>
            <td>{license.name}</td>
            <td>{createdAt}</td>
            <td>{getLicenseName(license.type)}</td>
            <td>{imagesLimit}</td>
            <td>{validUntil}</td>
            <td>{createdBy}</td>
        </tr>
    );
};
