import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Dialog, Group, Text } from '@mantine/core';
import React from 'react';
export const InsightsDeleteConfirmDialog = NiceModal.create(() => {
    const modal = useModal();
    const { hide, visible, resolve, reject } = modal;

    return (
        <Dialog onClose={() => hide()} opened={visible} radius="md" size="lg" withBorder>
            <Text size="sm" style={{ marginBottom: 10 }}>
                Are you sure you want to delete insight?
            </Text>

            <Group position="right">
                <Button
                    color="red"
                    onClick={() => {
                        resolve(true);
                        hide();
                    }}
                >
                    Yes
                </Button>
                <Button
                    color="red"
                    onClick={() => {
                        reject();
                        hide();
                    }}
                    variant="outline"
                >
                    No
                </Button>
            </Group>
        </Dialog>
    );
});
