import { Stack } from '@mantine/core';
import React, { FC } from 'react';

import { InsightsPanel } from './InsightsPanel';
import { InsightsTable } from './InsightsTable';

export const Insights: FC = () => {
    return (
        <Stack>
            <InsightsPanel></InsightsPanel>
            <InsightsTable></InsightsTable>
        </Stack>
    );
};
