import { Container, createStyles, Group } from '@mantine/core';
import preval from 'preval.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LangSelector } from '@/components/LangSelector';
import { useConfig } from '@/config';

import { MiviaLogoSimple } from './MiviaLogo';

const useStyles = createStyles((theme) => ({
    footer: {
        // backgroundColor: theme.white,
        borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[4]
        }`,
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingTop: theme.spacing.sm,
        paddingBottom: theme.spacing.sm,

        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column',
        },
    },

    groups: {
        width: '100%',
    },

    links: {
        [theme.fn.smallerThan('xs')]: {
            marginTop: theme.spacing.md,
        },
    },

    version: {
        color: '#b4b4b4',
        fontSize: 12,
        textAlign: 'center',
    },
}));

export function Footer() {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const config = useConfig();

    const buildDate = preval`const dayjs = require('dayjs');
                             const date = dayjs().format("YYYY-MM-DD");
                             module.exports = date;`;

    const deployEnv = config.getConfig('deploymentEnv');
    const version = deployEnv != 'production' ? `${buildDate} / ${deployEnv}` : buildDate;

    return (
        <div className={classes.footer}>
            <Container
                className={classes.inner}
                size="xl"
                style={{ paddingLeft: 0, paddingRight: 0 }}
            >
                <Group className={classes.groups} position="apart">
                    <div>
                        <MiviaLogoSimple width={75} />
                    </div>

                    <div className={classes.version}>
                        <span>MiViA (version: {version})</span> |{' '}
                        <Link to={'/terms'}>{t('common.terms')}</Link> |{' '}
                        <Link to={'/imprint'}>{t('common.imprint')}</Link> |{' '}
                        <Link to={'/licenses'}>{t('common.licenses')}</Link>
                    </div>

                    <div>
                        <LangSelector size={20} />
                        <img
                            alt="test-tracker"
                            src="https://track.mivia.ai/track.png"
                            style={{ width: 1, height: 1 }}
                        ></img>
                    </div>
                </Group>
            </Container>
        </div>
    );
}
