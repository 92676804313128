import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useVote } from '@/features/models/api/vote';
import {
    UserFeedbackForm,
    UserFeedbackFormValues,
} from '@/features/models/component/UserFeedbackForm';
import { UserFeedback } from '@/features/models/types/userJobType';

interface UserFeedbackModalProps {
    feedback?: UserFeedback;
    jobId: string;
}

export const UserFeedbackModal = NiceModal.create<UserFeedbackModalProps>(({ feedback, jobId }) => {
    const { t } = useTranslation();
    const modal = useModal();
    const vote = useVote({ jobId });

    const { remove, visible } = modal;

    const handleSubmit = (values: UserFeedbackFormValues) => {
        vote.mutate(values);
        remove();
    };

    return (
        <Modal
            centered
            onClose={() => remove()}
            opened={visible}
            size={500}
            title={t('model.rating.title')}
        >
            <UserFeedbackForm
                initFeedback={feedback}
                onCancel={() => remove()}
                onSubmit={handleSubmit}
            />
        </Modal>
    );
});
