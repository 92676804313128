import React, { FC } from 'react';

import { MiviaLoader } from '@/components/MiviaLoader';

interface ContentMiviaLoaderProps {
    size?: string;
}

export const ContentMiviaLoader: FC<ContentMiviaLoaderProps> = ({ size = '30%' }) => {
    return (
        <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
            <MiviaLoader width={size}></MiviaLoader>
        </div>
    );
};
