import React from 'react';

import { Insights } from '@/features/admin/components/insights/Insights';

import { useAdminStyles } from '../components/admin-styles';

export function AdminInsights() {
    const { classes } = useAdminStyles();

    return (
        <div className={classes.container}>
            <Insights />
        </div>
    );
}
