import { useModal } from '@ebay/nice-modal-react';
import { Button, Group } from '@mantine/core';
import React, { FC } from 'react';

import { useResetInsights } from '@/features/admin/components/insights/api/reset-insights';

import { InsightsModal } from './InsightsModal';

export const InsightsPanel: FC = () => {
    const dialog = useModal(InsightsModal);
    const resetInsights = useResetInsights();

    return (
        <Group mt={5} position="right">
            <Button onClick={() => resetInsights.mutate(undefined)} variant="light">
                Reset visibility
            </Button>
            <Button onClick={() => dialog.show()}>Add</Button>
        </Group>
    );
};
