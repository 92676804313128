import { Loader } from '@mantine/core';
import React, { FC } from 'react';

import { useUserStats } from '../api/user-stats';
import { useUserProfile } from '../api/users-profile';
import { UserProfile } from '../components/users/UserProfile';

interface AdminUserProfileProps {
    userId: string | undefined;
}

export const AdminUserProfile: FC<AdminUserProfileProps> = ({ userId }) => {
    const profileQuery = useUserProfile({ userId });
    const statsQuery = useUserStats({ userId });

    if (!profileQuery.data || !statsQuery.data) return <Loader size="xl" variant="dots" />;

    return <UserProfile profile={profileQuery.data} stats={statsQuery.data}></UserProfile>;
};
