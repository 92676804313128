import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { ACTIVE_JOBS, JOB } from '@/types/query-keys';

import { showJobFailedNotification, showJobStartedNotification } from '../api/notifications';
import { UserJobType } from '../types/userJobType';

export interface UseMutationRunModelDto {
    imageIds: string[];
    requestMasks?: boolean;
    replaceJobId?: string;
}

export const createJob = async (
    data: UseMutationRunModelDto,
    modelId: string
): Promise<UserJobType[]> => {
    return (await axios.post('/jobs', { ...data, modelId })).data;
};

type UseCreateJobOptions = {
    modelId: string;
    config?: MutationConfig<typeof createJob>;
};

export const useCreateJob = ({ config, modelId }: UseCreateJobOptions) => {
    return useMutation({
        ...config,
        onSuccess: async (data: UserJobType[], ...rest) => {
            await queryClient.invalidateQueries({ queryKey: [ACTIVE_JOBS, modelId] });
            showJobStartedNotification();
            config?.onSuccess?.(data, ...rest);
        },
        onError: (error, ...rest) => {
            showJobFailedNotification();
        },
        mutationFn: (data) => createJob(data, modelId),
    });
};
