import { z } from 'zod';
import {histogramResult, HistogramResultType} from "./histogramOutput";
import {dataTableResult, DataTableResultType} from "./dataTableOutput";
import {barChartResult, BarChartResultType} from "./barChartOutput";
import {MessageResultType} from "./messageOutput";

/**
 * Naming convention of types and schemas of model results:
 *  - xRawResultConfig: zod schema for raw model result, used for validation of Admin config (eg. imageRawResultConfig)
 *  - XRawResultType: Ts type for raw model result (eg. imageRawResultType)
 *  - XResultType: Ts type for served backend result (eg. ImageResultType)
 */


// Image type from model
const imageRawResultConfig = z.object({
    type: z.literal('image'),
    data: z.optional(z.string()),
});

export type ImageRawResultType = z.infer<typeof imageRawResultConfig>;



// For validation model config!
export const modelRawConfig = z.object({
    results: z.array(z.discriminatedUnion('type', [barChartResult, imageRawResultConfig, dataTableResult, histogramResult])),
    feedback: z.boolean(),
});


export type ImageResultType = Omit<z.infer<typeof imageRawResultConfig>, 'data'> & {
    filename: string;
};


export const feedback = z.object({
    name: z.string(),
    value: z.boolean(),
    score: z.number(),
})

export type FeedbackType = z.infer<typeof feedback>;


export const mask = z.object({
    label: z.string(),
    data: z.string(),
})

export type MaskType = z.infer<typeof mask>;

// Results passed from backend to frontend
export type ResultType = BarChartResultType | ImageResultType | DataTableResultType | HistogramResultType | MessageResultType;

export type ComputeModelOutput = {
    feedback: FeedbackType[];
    results: ResultType[];
};

// Results passed from model to backend
export type ResultRawType = BarChartResultType | ImageRawResultType | DataTableResultType | HistogramResultType | MessageResultType;
export type ComputeModelRawOutput = {
    feedback: FeedbackType[];
    results: ResultRawType[];
    masks?: MaskType[];
}

export const isBarChartResult = (x: any): x is BarChartResultType => {
    return (x as BarChartResultType).type === 'bar-chart';
};

export const isImageResult = (x: any): x is ImageResultType => {
    return (x as ImageResultType).type === 'image';
};

export const isRawImageResult = (x: ResultRawType): x is ImageRawResultType => {
    return (x as ImageRawResultType).type === 'image';
};


export const isDataTableResult = (x: ResultType): x is DataTableResultType => {
    return x.type === 'data-table';
};

export const isHistogramResult = (x: ResultType): x is HistogramResultType => {
    return x.type === 'histogram';
}

export const isMessageResult = (x: ResultType): x is MessageResultType => {
    return x.type === 'message';
}

export type ComputeModelAccessType = 'PUBLIC' | 'PRIVATE';

export interface Model {
    id: string;
    name: string;
    displayName: string;
    accessType: ComputeModelAccessType;
}
