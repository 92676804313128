import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { removeEmpty } from '@/lib/helpers';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { CURRENT_USER, PROFILE } from '@/types/query-keys';

import {
    showProfileFailUpdateNotification,
    showProfileUpdatedNotification,
} from '../api/notifications';
import { UserProfile } from '../types/user-profile';

export interface UseMutationUpdateAuthDto {
    oldPassword: string;
    newPassword?: string;
    email: string;
    name: string;
    surname: string;
    subscribe: boolean;
    trainingConsent: boolean;
}

export const updateAuth = async (data: UseMutationUpdateAuthDto): Promise<UserProfile> => {
    return (await axios.patch('/users/me/auth', removeEmpty(data))).data;
};

type UseUpdateAuthOptions = {
    config?: MutationConfig<typeof updateAuth>;
};

export const useUpdateAuth = ({ config }: UseUpdateAuthOptions = {}) => {
    return useMutation({
        ...config,
        onSuccess: (data: UserProfile, ...rest) => {
            queryClient.setQueryData<UserProfile>([PROFILE, 'me'], (prevData) => data);
            queryClient.invalidateQueries({ queryKey: [CURRENT_USER] });
            config?.onSuccess?.(data, ...rest);
            showProfileUpdatedNotification();
        },
        onError: (error, ...rest) => {
            const dErr = error.response?.data as any;
            const errMsg = dErr?.message;
            showProfileFailUpdateNotification(errMsg);
        },
        mutationFn: updateAuth,
    });
};
