import { useQuery } from '@tanstack/react-query';

import { Voucher } from '@/features/admin/types/voucher';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { VOUCHERS } from '@/types/query-keys';
import { removeNullFields } from '@/utils/removeNullFields';

export const queryVouchers = async (
    page: number,
    pageSize: number,
    sortBy: string,
    sortOrder: 'asc' | 'desc'
): Promise<{ vouchers: Voucher[]; total: number }> => {
    let params: any = { sortBy, sortOrder, page, pageSize };
    params = removeNullFields(params);
    const { data, headers } = await axios.get<Voucher[]>(`/admin/vouchers/`, {
        params,
    });
    // TODO: Lets try to get rid of this default value - header should be defined always!
    return { vouchers: data, total: parseInt(headers['x-total-count'] ?? '10') };
};

type QueryFnType = typeof queryVouchers;

type UseVouchersOptions = {
    page: number;
    pageSize?: number;
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
    config?: QueryConfig<QueryFnType>;
};

export const useQueryVouchers = ({
    config,
    pageSize = 9,
    page = 1,
    sortBy = 'createdAt',
    sortOrder = 'asc',
}: UseVouchersOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [VOUCHERS, page, sortBy, sortOrder],
        // queryKey: [VOUCHERS, page, sortBy, sortOrder],
        queryFn: () => queryVouchers(page, pageSize, sortBy, sortOrder),
        // ...config,
    });
};
