import { useMutation } from '@tanstack/react-query';

import { UserAccessKeyElement } from '@/features/user-access-keys/types/UserAccessKey';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { ACCESS_KEYS } from '@/types/query-keys';

export interface UseMutationCreateAccessKeyDto {
    name?: string;
}

const createAccessKey = async (
    data: UseMutationCreateAccessKeyDto
): Promise<UserAccessKeyElement> => {
    return (await axios.post('access-keys', data)).data;
};

type UseCreateAccessKeyOptions = {
    config?: MutationConfig<typeof createAccessKey>;
};

export const useCreateAccessKey = ({ config }: UseCreateAccessKeyOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: createAccessKey,
        onSuccess: async (data: UserAccessKeyElement) => {
            await queryClient.invalidateQueries({ queryKey: [ACCESS_KEYS] });
        },
    });
};
