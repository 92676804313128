import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { JOB } from '@/types/query-keys';

export interface UseMutationDeleteJobFeedbackDto {
    id: number;
}

const deleteJobFeedback = async (data: UseMutationDeleteJobFeedbackDto): Promise<any> => {
    return (await axios.delete(`/admin/feedback/admin/${data.id}`)).data;
};

type UseDeleteJobFeedbackOptions = {
    jobId: string;
    config?: MutationConfig<typeof deleteJobFeedback>;
};

export const useDeleteJobFeedback = ({ config, jobId }: UseDeleteJobFeedbackOptions) => {
    return useMutation({
        ...config,
        mutationFn: deleteJobFeedback,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([JOB, jobId]);
        },
    });
};
