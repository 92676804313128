import { Stack } from '@mantine/core';
import React, { FC } from 'react';

import { ComputeModelsPanel } from '@/features/admin/components/compute-models/ComputeModelsPanel';
import { ComputeModelsTable } from '@/features/admin/components/compute-models/ComputeModelsTable';

export const ComputeModels: FC = () => {
    return (
        <Stack>
            <ComputeModelsPanel />
            <ComputeModelsTable />
        </Stack>
    );
};
