import { Alert, Group } from '@mantine/core';
import { MessageDataType, MessageResultStatusEnum } from 'mivia-shared';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type MessageProps = {
    data: MessageDataType;
};

const colorStatus = (status: MessageResultStatusEnum) => {
    switch (status) {
        case 'info':
            return 'blue';
        case 'warning':
            return 'orange';
        case 'error':
            return 'red';
        default:
            return 'grey';
    }
};

export const Message: FC<MessageProps> = ({ data }) => {
    const { t } = useTranslation();
    const { content, title, status } = data;

    const translatedTitle = t(`model.message.${title}`);
    const translatedContent = t(`model.message.${content}`);

    const color = colorStatus(status);
    return (
        <Alert color={color} my={8} variant="outline" w={'100%'}>
            <Group spacing={8}>
                <div style={{ color: color, fontWeight: 600 }}>{translatedTitle}:</div>
                <div>{translatedContent}</div>
            </Group>
        </Alert>
    );
};
