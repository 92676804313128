import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { NODES } from '@/types/query-keys';

export interface UseMutationDeleteNodeDto {
    id: string;
}

const deleteNode = async (data: UseMutationDeleteNodeDto): Promise<any> => {
    return (await axios.delete(`/admin/nodes/${data.id}`)).data;
};

type UseDeleteNodeOptions = {
    config?: MutationConfig<typeof deleteNode>;
};

export const useDeleteNode = ({ config }: UseDeleteNodeOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: deleteNode,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [NODES] });
        },
    });
};
