import { useRoutes } from 'react-router-dom';

import { FullPageMiviaLoader } from '@/components/FullPageMiviaLoader';
import { useSessionContext } from '@/providers/session-context';

import { adminRoutes } from './admin';
import { groupAdmin, protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
    const session = useSessionContext();
    const { userId, accessTokenPayload, isLoading } = session;

    const routes = [...publicRoutes];
    const { role, grpAdmin } = accessTokenPayload ?? {};

    if (userId) {
        if (userId && role) routes.push(...protectedRoutes);
        if (role === 'ADMIN') routes.push(...adminRoutes);
        if (grpAdmin) routes.push(...groupAdmin);
    }

    const element = useRoutes(routes);

    if (isLoading) return <FullPageMiviaLoader />;

    return <>{element}</>;
};
