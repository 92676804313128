import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { JOBS, USER } from '@/types/query-keys';

import { AdminViewableUserJob } from '../types/user-jobs';

export const getUserJobs = async ({
    userId,
}: {
    userId: string | undefined;
}): Promise<AdminViewableUserJob[]> => {
    return (
        await axios.get(`/admin/jobs`, {
            params: {
                userId,
                page: 1,
                pageSize: 100,
            },
        })
    ).data;
};

type QueryFnType = typeof getUserJobs;

type UseUserJobOptions = {
    userId: string | undefined;
    config?: QueryConfig<QueryFnType>;
};

export const useUserJob = ({ config, userId }: UseUserJobOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [USER, userId, JOBS],
        enabled: !!userId,
        queryFn: () => getUserJobs({ userId }),
        ...config,
    });
};
