import { Flex } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { Panel } from '@/components/Panel';
import { useLicenseProducts } from '@/features/user-license/api/products';
import { LicenseBlock } from '@/features/user-license/components/LicenseBlock';

export const BuyLicense: FunctionComponent = () => {
    const { data: lincenses } = useLicenseProducts();

    return (
        <Panel title="Buy license">
            <Flex align="stretch" gap="xl" justify="center" wrap="wrap">
                {lincenses?.map((license) => (
                    <LicenseBlock key={license.id} license={license}></LicenseBlock>
                ))}
            </Flex>
        </Panel>
    );
};
