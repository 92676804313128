import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal, Tabs } from '@mantine/core';

import { useCreateComputeModel } from '@/features/admin/api/create-compute-model';
import { useUpdateComputeModel } from '@/features/admin/api/update-compute-model';
import { ComputeModelAccess } from '@/features/admin/components/compute-models/ComputeModelAccess';
import {
    ComputeModelForm,
    ComputeModelFormValues,
} from '@/features/admin/components/compute-models/ComputeModelForm';
import { ComputeModel } from '@/features/admin/types/compute-model';

interface ComputeModelModalProps {
    computeModelId?: string;
    initialValues?: ComputeModel;
}

export const ComputeModelModal = NiceModal.create<ComputeModelModalProps>(
    ({ initialValues, computeModelId }) => {
        const modal = useModal();
        const createComputeModel = useCreateComputeModel();
        const updateComputeModel = useUpdateComputeModel();
        const { visible, remove } = modal;

        const handleOnSubmit = (values: ComputeModelFormValues) => {
            if (initialValues && computeModelId) {
                updateComputeModel.mutate({ id: computeModelId, values });
            } else {
                createComputeModel.mutate(values);
            }
            remove();
        };

        const title = initialValues ? `Edit "${initialValues.displayName}"` : 'Add compute model';

        return (
            <Modal centered onClose={() => remove()} opened={visible} title={title}>
                <Tabs defaultValue="general">
                    <Tabs.List>
                        <Tabs.Tab value="general">General</Tabs.Tab>

                        {computeModelId && <Tabs.Tab value="access">Access</Tabs.Tab>}
                    </Tabs.List>

                    <Tabs.Panel pt="md" value="general">
                        <ComputeModelForm
                            initialValues={initialValues}
                            onCancel={() => remove()}
                            onSubmit={handleOnSubmit}
                        />
                    </Tabs.Panel>

                    {computeModelId && (
                        <Tabs.Panel pt="md" value="access">
                            <ComputeModelAccess id={computeModelId}></ComputeModelAccess>
                        </Tabs.Panel>
                    )}
                </Tabs>
            </Modal>
        );
    }
);
