import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

const removeAccountRequest = async (): Promise<any> => {
    return (await axios.delete('/users/me')).data;
};

type UseRequestAccountRemoveOptions = {
    config?: MutationConfig<typeof removeAccountRequest>;
};

export const useRequestAccountRemove = ({ config }: UseRequestAccountRemoveOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: removeAccountRequest,
    });
};
