import { useMutation } from '@tanstack/react-query';

import {
    showNodeStoppingFailureNotification,
    showNodeStoppingNotification,
} from '@/features/admin/api/notifications';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { NODES } from '@/types/query-keys';

export interface UseMutationStopNodeDto {
    id: string;
}

const stopNode = async (data: UseMutationStopNodeDto): Promise<any> => {
    return (await axios.put(`/admin/nodes/${data.id}/stop`)).data;
};

type UseStopNodeOptions = {
    config?: MutationConfig<typeof stopNode>;
};

export const useStopNode = ({ config }: UseStopNodeOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: stopNode,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([NODES]);
            showNodeStoppingNotification();
        },
        onError: (error: any) => {
            showNodeStoppingFailureNotification();
        },
    });
};
