import {z} from "zod";

const messageResultStatus = z.enum(['info', 'warning', 'error']);

const messageData = z.object({
    title: z.string(),
    content: z.string(),
    status: messageResultStatus,
});

const messageResult = z.object({
    type: z.literal('message'),
    data: messageData,
});

export type MessageResultType = z.infer<typeof messageResult>
export type MessageDataType = z.infer<typeof messageData>
export type MessageResultStatusEnum = z.infer<typeof messageResultStatus>
