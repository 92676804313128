import React, { FunctionComponent } from 'react';

import { Layout } from '@/features/auth/component/Layout';
import { DisplayTerms } from '@/features/terms/components/DisplayTerms';

export const FullScreenTerms: FunctionComponent = () => {
    return (
        <Layout title="Terms and conditions">
            <DisplayTerms />
        </Layout>
    );
};
