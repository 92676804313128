export type LicenseType =
    | 'TIME_LIMITED'
    | 'IMAGES_LIMITED'
    | 'UNLIMITED'
    | 'FLOATING'
    | 'DEMO'
    | 'ENTERPRISE';
export type LicenseCreatedBy = 'SYSTEM' | 'PURCHASE' | 'ADMIN' | 'VOUCHER';

export interface License {
    id: string;
    type: LicenseType;
    limits: LicenseLimits;
    createdAt: string;
    name: string;
    createdBy: LicenseCreatedBy;
}

export interface LicenseLimits {
    finish: string;
    images: number;
    used: number;
}

export const getLicenseName = (type: LicenseType) => {
    switch (type) {
        case 'IMAGES_LIMITED':
            return 'Limited images';
        case 'UNLIMITED':
            return 'Unlimited';
        case 'TIME_LIMITED':
            return 'Time limited';
        case 'DEMO':
            return 'Demo';
        case 'FLOATING':
            return 'Group';
        case 'ENTERPRISE':
            return 'Enterprise';
        default:
            return 'Unknown license';
    }
};
