import { Box, Checkbox, Stack } from '@mantine/core';
import React, { FC } from 'react';

import { useModels } from '@/features/models/api/models';

interface ModelsListProps {
    selected: string[];
    onChange: (models: string[]) => void;
}

export const ModelsList: FC<ModelsListProps> = ({ selected = [], onChange }) => {
    const { data: models } = useModels();

    const modelsList = models?.map((model) => (
        <Checkbox key={model.id} label={model.displayName} value={model.id} />
    ));

    return (
        <Box mx={'auto'} w={800}>
            <Checkbox.Group onChange={onChange} value={selected}>
                <Stack>{modelsList}</Stack>
            </Checkbox.Group>
        </Box>
    );
};
