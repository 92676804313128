import { Group, Rating, RatingProps, Text } from '@mantine/core';
import React, { FC, useCallback, useMemo } from 'react';

/**
 * Extends the properties of RatingProps to include a callback function for changing the tooltip.
 *
 * @typedef {object} TooltipRatingProps
 *
 * @property {(value: number) => string} onTooltipChange - Callback to change the tooltip's display text.
 */
type TooltipRatingProps = RatingProps & {
    onTooltipChange: (value: number) => string;
};

/**
 * Displays a rating component with a tooltip. The tooltip's content changes based on the hovered value or the current value.
 *
 * @param {TooltipRatingProps} props - The properties of the TooltipRating.
 *
 * @property {function} onTooltipChange - Callback to derive tooltip's display text from a value.
 * @property {...RatingProps} props - Rest of the properties inherited from RatingProps.
 *
 * @returns {React.Element} The rendered TooltipRating component.
 */
export const TooltipRating: FC<TooltipRatingProps> = ({ onTooltipChange, ...props }) => {
    const { value, onChange } = props;
    const [hoveredValue, setHoveredValue] = React.useState<number>(value || 0);

    /**
     * Handle the change in rating.
     * Either sets the hovered value or calls the provided onChange callback.
     *
     * @param {number} value - The new rating value.
     */
    const handleRatingChange = useCallback(
        (value: number) => {
            onChange ? onChange(value) : setHoveredValue(value);
        },
        [onChange]
    );

    /**
     * Determines the tooltip's display text based on hovered or current value.
     */
    const displayTooltip = useMemo(() => {
        if (hoveredValue > 0) return onTooltipChange(hoveredValue);
        if (value && value > 0) return onTooltipChange(value);
        return onTooltipChange(0);
    }, [hoveredValue, onTooltipChange, value]);

    return (
        <Group>
            <Rating
                {...props}
                onBlur={() => setHoveredValue(0)}
                onChange={handleRatingChange}
                onHover={setHoveredValue}
            />
            <Text size="sm">({displayTooltip})</Text>
        </Group>
    );
};
