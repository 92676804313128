import { Button, Group, JsonInput, NumberInput, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { queryRegistryTagsByModelId } from '@/features/admin/api/docker-images';

export interface DeployModelFormValues {
    computeModelId: string;
    version: string;
    port: number;
    envVariables?: string;
}

interface DeployModelFormProps {
    onSubmit: (values: DeployModelFormValues) => void;
    onCancel: () => void;
    availableModels: { value: string; label: string }[];
    isBusy: boolean;
}

export const DeployModelForm: FunctionComponent<DeployModelFormProps> = ({
    onSubmit,
    onCancel,
    availableModels,
    isBusy,
}) => {
    const form = useForm<DeployModelFormValues>({
        initialValues: {
            computeModelId: '',
            version: '',
            port: 8000,
            envVariables: undefined,
        },
    });
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        async function fetchImages() {
            const data = await queryRegistryTagsByModelId(form.values.computeModelId);
            setTags(data.map((tag) => tag.tag) ?? []);
        }
        fetchImages();
    }, [form.values.computeModelId]);

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Select
                data={availableModels}
                disabled={isBusy}
                label="Model"
                placeholder={
                    availableModels.length > 0
                        ? 'Select a model'
                        : 'No compute-models-registry available'
                }
                {...form.getInputProps('computeModelId')}
            />
            <Select
                data={tags}
                label="Version"
                {...form.getInputProps('version')}
                disabled={isBusy}
            />
            <NumberInput
                label="Communication port"
                {...form.getInputProps('port')}
                disabled={isBusy}
            ></NumberInput>

            <JsonInput
                autosize
                formatOnBlur
                label="Environment variables"
                minRows={4}
                placeholder="Set Environment variables for the model deployment, as key-value JSON, e.g. { 'key1': 'value1' }. Leave empty if not needed."
                validationError="Invalid JSON"
                {...form.getInputProps('envVariables')}
            />

            <Group mt="md" position="right">
                <Button disabled={isBusy} onClick={() => onCancel()} variant="outline">
                    Cancel
                </Button>
                <Button disabled={!form.values.version} loading={isBusy} type="submit">
                    Create
                </Button>
            </Group>
        </form>
    );
};
