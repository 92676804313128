import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TEST_INSTANCE_REQ } from '@/types/query-keys';

export const getTestInstanceResult = async (
    id: string
): Promise<'wait' | 'active' | 'failed' | 'completed'> => {
    return (await axios.get(`/admin/compute-models-tester/${id}`)).data;
};

type QueryFnType = typeof getTestInstanceResult;

type UseTestInstanceResultOptions = {
    jobId: string;
    config?: QueryConfig<QueryFnType>;
};

export const useTestInstanceResult = ({ config, jobId }: UseTestInstanceResultOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [TEST_INSTANCE_REQ, jobId],
        queryFn: () => getTestInstanceResult(jobId),
        refetchInterval: 3000,
        ...config,
    });
};
