import { Button, Group, MultiSelect, Stack, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useShallowEffect } from '@mantine/hooks';
import React from 'react';

import { Panel } from '@/components/Panel';
import { useAdminSettings } from '@/features/admin/api/admin-settings';
import {
    UpdateMaintenanceMessageDto,
    useUpdateMaintenanceMode,
} from '@/features/admin/api/update-maintenance-message';
import { useModels } from '@/features/models/api/models';

export const MaintenancePanel: React.FC = () => {
    // API data fetchers
    const { data: settings } = useAdminSettings();
    const { data: models } = useModels();
    const updateMaintenanceMessage = useUpdateMaintenanceMode();

    // Form initialization
    const form = useForm<UpdateMaintenanceMessageDto>({
        initialValues: {
            maintenanceMessage: {
                en: '',
                de: '',
            },
            maintenanceModels: [],
        },
    });

    // Syncing form values with fetched data
    useShallowEffect(() => {
        if (!settings) return;

        form.setValues({
            maintenanceMessage: {
                en: settings.maintenanceMessage?.en ?? '',
                de: settings.maintenanceMessage?.de ?? '',
            },
            maintenanceModels: settings.maintenanceModels ?? [],
        });
    }, [settings]);

    // Form submission handler
    const handleUpdate = (values: UpdateMaintenanceMessageDto): void => {
        updateMaintenanceMessage.mutate(values);
    };

    // Extracting models for selection
    const selectableModels = models?.map((model) => ({ value: model.id, label: model.name })) ?? [];

    return (
        <Panel title="Maintenance mode">
            <form onSubmit={form.onSubmit(handleUpdate)}>
                <Stack spacing="md">
                    <MultiSelect
                        data={selectableModels}
                        label="Models"
                        placeholder="Enable maintance for specific models"
                        {...form.getInputProps('maintenanceModels')}
                    />

                    <Textarea
                        label="Maintenance message (English)"
                        required
                        withAsterisk
                        {...form.getInputProps('maintenanceMessage.en')}
                    />
                    <Textarea
                        label="Maintenance message (German)"
                        required
                        withAsterisk
                        {...form.getInputProps('maintenanceMessage.de')}
                    />
                    <Group position="right">
                        <Button type="submit">Save</Button>
                    </Group>
                </Stack>
            </form>
        </Panel>
    );
};
