import { useMutation } from '@tanstack/react-query';

import { showUserActivatedNotification } from '@/features/admin/api/notifications';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

export const activateUser = async (userId: string): Promise<boolean> => {
    return (await axios.put(`/admin/users/${userId}/activate`)).data;
};

type UseActivateUserOptions = {
    config?: MutationConfig<typeof activateUser>;
};

export const useActivateUser = ({ config }: UseActivateUserOptions = {}) => {
    return useMutation({
        ...config,
        onSuccess: (data: any, ...rest) => {
            showUserActivatedNotification();
        },
        mutationFn: activateUser,
    });
};
