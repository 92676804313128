import { useQuery } from '@tanstack/react-query';

import { LicenseProductType } from '@/features/user-license/types/license-product';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { PRODUCTS } from '@/types/query-keys';

export const getProducts = async (): Promise<LicenseProductType[]> => {
    return (await axios.get('/payments/products'))?.data;
};

type QueryFnType = typeof getProducts;

type UseLicensesProductsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useLicenseProducts = ({ config }: UseLicensesProductsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [PRODUCTS],
        queryFn: () => getProducts(),
        ...config,
    });
};
