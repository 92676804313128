import { Alert, Button, Group, Input, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FunctionComponent, useEffect } from 'react';

import { TextEditor } from '@/components/TextEditor/TextEditor';

import { InsightsFormValues } from './types/insight-form-values';
import { InsightDetailType } from './types/insight-type';

interface ModelInsightsFormProps {
    onSubmit: (values: InsightsFormValues) => void;
    onCancel: () => void;
    initialValues?: InsightDetailType;
}

export const InsightsForm: FunctionComponent<ModelInsightsFormProps> = ({
    onSubmit,
    onCancel,
    initialValues,
}) => {
    const form = useForm<InsightsFormValues>({
        initialValues: {
            titleEn: initialValues?.titleEn || '',
            titleDe: initialValues?.titleDe || '',
            contentEn: initialValues?.contentEn || '',
            contentDe: initialValues?.contentDe || '',
        },
        validate: {
            titleEn: (value) => (value.trim().length > 0 ? null : 'Title is required'),
            titleDe: (value) => (value.trim().length > 0 ? null : 'Title is required'),
            contentEn: (value) => (value.trim().length > 0 ? null : 'Content is required'),
            contentDe: (value) => (value.trim().length > 0 ? null : 'Content is required'),
        },
    });

    useEffect(() => {
        if (initialValues !== undefined) {
            form.setValues({ ...initialValues });
        }
    }, [initialValues]);

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Stack>
                <Input type="hidden" {...form.getInputProps('id')}></Input>

                <TextInput
                    {...form.getInputProps('titleEn')}
                    label="Title (English)"
                    required
                ></TextInput>
                <TextInput
                    {...form.getInputProps('titleDe')}
                    label="Title (German)"
                    required
                ></TextInput>
                <Alert color="blue" variant="light">
                    Image in MiViA Insight should not exceed 850px width to fit the screen
                </Alert>
                <TextEditor
                    {...form.getInputProps('contentEn')}
                    label="Content (English)"
                    required
                ></TextEditor>
                <TextEditor
                    {...form.getInputProps('contentDe')}
                    label="Content (German)"
                    required
                ></TextEditor>

                <Group mt="md" position="right">
                    <Button onClick={() => onCancel()} variant="outline">
                        Close
                    </Button>
                    <Button type="submit">Save</Button>
                </Group>
            </Stack>
        </form>
    );
};
