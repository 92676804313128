import { Button, Group, NumberInput, SimpleGrid } from '@mantine/core';
import React, { FC } from 'react';

import { Panel } from '@/components/Panel';
import { useDemoConfig } from '@/features/admin/components/settings/api/demo-config';
import { useUpdateDemoConfig } from '@/features/admin/components/settings/api/update-demo-config';
import {
    DemoConfigForm,
    useDemoConfigForm,
} from '@/features/admin/components/settings/forms/useDemoConfigForm';

export const DemoPanel: FC = () => {
    const { data: demoData } = useDemoConfig();
    const { mutate: updateDemoConfig } = useUpdateDemoConfig();

    const form = useDemoConfigForm(demoData);

    const onSubmit = (values: DemoConfigForm) => {
        updateDemoConfig(values);
    };

    return (
        <Panel title="Demo license">
            <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                <SimpleGrid cols={2} spacing="md">
                    <NumberInput
                        hideControls={true}
                        label="Demo images"
                        required
                        {...form.getInputProps('maxImages')}
                    />
                    <div></div>
                    <NumberInput
                        hideControls={true}
                        label="Demo time (days)"
                        required
                        {...form.getInputProps('demoDuration')}
                    />
                    <NumberInput
                        hideControls={true}
                        label="Extended Demo time (days)"
                        required
                        {...form.getInputProps('extensionDuration')}
                    />
                    <NumberInput
                        hideControls={true}
                        label="Send remind before demo ends (days)"
                        required
                        {...form.getInputProps('reminderBeforeExpiry')}
                    />{' '}
                    <NumberInput
                        hideControls={true}
                        label="Send remind before extended demo ends (days)"
                        required
                        {...form.getInputProps('reminderBeforeExtensionExpiry')}
                    />
                </SimpleGrid>
                <Group mt={'md'} position="right">
                    <Button type="submit">Save</Button>
                </Group>
            </form>
        </Panel>
    );
};
