import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mantine/core';
import React from 'react';

import { useCreateLicense } from '@/features/admin/api/create-license';
import { LicenseForm, LicenseFormValues } from '@/features/admin/components/LicenseForm';

interface LicenseModalProps {
    userId: string;
}

export const LicenseModal = NiceModal.create<LicenseModalProps>(({ userId }) => {
    const modal = useModal();
    const createLicense = useCreateLicense();
    const { hide, visible } = modal;

    const handleSubmit = async (values: LicenseFormValues) => {
        createLicense.mutate({ ...values, userId });
        hide();
    };

    return (
        <Modal centered onClose={hide} opened={visible} title="Create license">
            <LicenseForm onCancel={hide} onSubmit={handleSubmit} />
        </Modal>
    );
});
