import { useQuery } from '@tanstack/react-query';
import { Model } from 'mivia-shared';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { AVAILABLE_MODELS } from '@/types/query-keys';

export const getModels = async (): Promise<Model[]> => {
    return (await axios.get('/settings/available-models')).data;
};

type QueryFnType = typeof getModels;

type UseModelsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useModels = ({ config }: UseModelsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [AVAILABLE_MODELS],
        queryFn: getModels,
        ...config,
    });
};
