import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Group, Image, Modal, Stack, Text, TextInput } from '@mantine/core';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useEnableOtp } from '@/features/user-profile/api/enable-otp';
import { initOtp } from '@/features/user-profile/api/init-otp';
import { showOtpEnabledNotification } from '@/features/user-profile/api/notifications';

export const TwoFactorModal = NiceModal.create(() => {
    const { t } = useTranslation();
    const [qrCode, setQrCode] = useState<string>();
    const [otp, setOtp] = useState<string>();
    const [error, setError] = useState<boolean>(false);
    const modal = useModal();
    const enableOtp = useEnableOtp();

    useEffect(() => {
        setError(false);
    }, [otp]);

    useEffect(() => {
        initOtp().then((data) => {
            setQrCode(data.toString('base64'));
        });
    }, []);

    const { visible, remove } = modal;

    const handleEnableOtp = () => {
        if (otp) {
            enableOtp.mutate(
                { code: otp, enable: true },
                {
                    onSuccess: () => {
                        remove();
                        showOtpEnabledNotification();
                    },
                    onError: () => setError(true),
                }
            );
        }
    };

    return (
        <Modal
            centered
            closeOnClickOutside={false}
            onClose={() => remove()}
            opened={visible}
            title={t('otp.title')}
        >
            <Stack mx={'lg'} spacing="sm">
                <Text size="sm">{t('otp.twoFactorEnableText')}</Text>
                {qrCode && <Image src={`data:image/png;base64, ${qrCode}`} />}
                <Group align="start" grow={true}>
                    <TextInput
                        error={error && t('otp.invalidOtpCode')}
                        onChange={(v) => setOtp(v.target.value)}
                        onKeyDown={(e: KeyboardEvent) => e.key == 'Enter' && handleEnableOtp()}
                        placeholder={t('otp.twoFactorCodePlaceholder')}
                    />
                    <Button disabled={otp?.length != 6} onClick={handleEnableOtp}>
                        Activate
                    </Button>
                </Group>
            </Stack>
        </Modal>
    );
});
