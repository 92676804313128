import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { TWO_FACTOR_ENABLED } from '@/types/query-keys';

export interface UseMutationEnableOtpDto {
    code: string;
    enable: boolean;
}

export const enableOtp = async (data: UseMutationEnableOtpDto): Promise<boolean> => {
    return (await axios.put('/users/2fa', data)).data;
};

type UseEnableOtpOptions = {
    config?: MutationConfig<typeof enableOtp>;
};

export const useEnableOtp = ({ config }: UseEnableOtpOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: enableOtp,
        onSuccess: (...params) => {
            config?.onSuccess?.(...params);
            queryClient.invalidateQueries({ queryKey: [TWO_FACTOR_ENABLED] });
        },
    });
};
