import React from 'react';

import { News } from '@/features/admin/components/news/News';

import { useAdminStyles } from '../components/admin-styles';

export function AdminNews() {
    const { classes } = useAdminStyles();

    return (
        <div className={classes.container}>
            <News />
        </div>
    );
}
