import { useMutation } from '@tanstack/react-query';

import { ComputeModelFormValues } from '@/features/admin/components/compute-models/ComputeModelForm';
import { ComputeModel } from '@/features/admin/types/compute-model';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { COMPUTE_MODELS } from '@/types/query-keys';

export type UseMutationCreateComputeModelDto = ComputeModelFormValues;

const createComputeModel = async (
    data: UseMutationCreateComputeModelDto
): Promise<ComputeModel> => {
    return (await axios.post('/admin/compute-models', data)).data;
};

type UseCreateComputeModelOptions = {
    config?: MutationConfig<typeof createComputeModel>;
};

export const useCreateComputeModel = ({ config }: UseCreateComputeModelOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: createComputeModel,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [COMPUTE_MODELS] });
        },
    });
};
