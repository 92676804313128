import { FullScreenImprint } from '@/features/terms/components/FullScreenImprint';
import { FullScreenLicenses } from '@/features/terms/components/FullScreenLicenses';
import { FullScreenTerms } from '@/features/terms/components/FullScreenTerms';
import { lazyImport } from '@/utils/lazyImport';

const { AuthRoutes } = lazyImport(() => import('@/features/auth'), 'AuthRoutes');

export const publicRoutes = [
    {
        path: '/auth/*',
        element: <AuthRoutes />,
    },
    {
        path: '/terms',
        element: <FullScreenTerms />,
    },
    {
        path: '/imprint',
        element: <FullScreenImprint />,
    },
    {
        path: '/licenses',
        element: <FullScreenLicenses />,
    },
    {
        path: '/*',
        element: <AuthRoutes />,
    },
];
