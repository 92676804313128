import { getImageThumbnailUrl, getImageUrl, getResultUrl } from '@/config';
import { AdminViewableUserJob } from '@/features/admin/types/user-jobs';
import { UserJobType } from '@/features/models/types/userJobType';

export const prepareResults = (job: UserJobType | AdminViewableUserJob) => {
    const imgId = job.image?.id || '';
    const imageUrl = getImageUrl(imgId);
    const thumbnailUrl = getImageThumbnailUrl(imgId);
    const resultUrl = getResultUrl(job.result?.id);
    const caption = job.image?.orginalFilename;
    return { imageUrl, resultUrl, caption, thumbnailUrl };
};
