import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { USERS } from '@/types/query-keys';

export interface UseMutationAssignRoleDto {
    role: string | null;
    userId: string | undefined;
}

export const assignRole = async (data: UseMutationAssignRoleDto): Promise<void> => {
    if (!data.userId || !data.role) return Promise.reject('User id or role is not defined');

    return (await axios.patch(`users/${data.userId}`, { role: data.role })).data;
};

type UseCreateJobOptions = {
    config?: MutationConfig<typeof assignRole>;
};

export const useAssignRole = ({ config }: UseCreateJobOptions = {}) => {
    return useMutation({
        ...config,
        onSuccess: (data: any, ...rest) => {
            queryClient.invalidateQueries({ queryKey: [USERS] });
            config?.onSuccess?.(data, ...rest);
        },
        mutationFn: assignRole,
    });
};
