import React, { FunctionComponent, useState } from 'react';

import { useLicenses } from '@/features/admin/api/licenses';
import { LicenseRowData, LicenseTable } from '@/features/admin/components/licenses/LicenseTable';

const DEFAULT_SORT: keyof LicenseRowData = 'createdAt';

export const Licenses: FunctionComponent = () => {
    const [sortBy, setSortBy] = useState<keyof LicenseRowData>(DEFAULT_SORT);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const licensesQuery = useLicenses({ sortBy, sortOrder: reverseSortDirection ? 'asc' : 'desc' });

    const data = licensesQuery.data || [];

    function handleSorting(sortBy: keyof LicenseRowData, reversed: boolean) {
        setSortBy(sortBy);
        setReverseSortDirection(reversed);
    }

    return (
        <>
            <LicenseTable
                licenses={data}
                onSort={handleSorting}
                reverseSortDirection={reverseSortDirection}
                sortBy={sortBy}
            />
        </>
    );
};
