import { createStyles } from '@mantine/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Head } from '@/components/Head';
import { LangSelector } from '@/components/LangSelector';
import { MiviaLogoClaimBelow } from '@/components/Layout/MiviaLogo';
import { useSessionContext } from '@/providers/session-context';

import background from './background.jpeg';

type LayoutProps = {
    children: React.ReactNode;
    title: string;
};

const useStyles = createStyles((theme, _params, getRef) => ({
    layout: {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat-y',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    childWrapper: {
        flex: 1,
        display: 'grid',
        placeItems: 'center',
        paddingBottom: 100,
    },
    logo: {
        marginTop: '1rem',
        marginLeft: '1rem',
    },
    langWrapper: {
        position: 'absolute',
        right: '1rem',
        bottom: '1rem',
    },
}));

export const Layout = ({ children, title }: LayoutProps) => {
    const { classes } = useStyles();
    const { userId } = useSessionContext();

    let mainSiteLink = '/';
    if (userId) {
        mainSiteLink = '/dashboard';
    }

    return (
        <div className={classes.layout}>
            <Head title={title} />

            <Link to={mainSiteLink}>
                <MiviaLogoClaimBelow className={classes.logo} variant="white" width={200} />
            </Link>

            <div className={classes.childWrapper} data-cy="layout-wrapper">
                {children}
            </div>

            <div className={classes.langWrapper}>
                <LangSelector color="dark" />
            </div>
        </div>
    );
};
