import { useMutation } from '@tanstack/react-query';

import { ModelNewsFormValues } from '@/features/admin/components/news/types/model-news-form-values';
import { ModelNewsDetailType } from '@/features/admin/components/news/types/model-news-type';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { ADMIN_MODELS_NEWS } from '@/types/query-keys';

const updateModelNews = async ({
    id,
    data,
}: {
    id: number;
    data: ModelNewsFormValues;
}): Promise<ModelNewsDetailType> => {
    return (await axios.put(`/admin/compute-models-news/${id}`, data)).data;
};

type UseUpdateModelNewsOptions = {
    config?: MutationConfig<typeof updateModelNews>;
};

export const useUpdateModelNews = ({ config }: UseUpdateModelNewsOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: updateModelNews,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [ADMIN_MODELS_NEWS] });
        },
    });
};
