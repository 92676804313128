import { Button, Group, SegmentedControl, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ComputeModelAccessType } from 'mivia-shared';
import React, { FC } from 'react';

import { ComputeModel } from '@/features/admin/types/compute-model';

interface ComputeModelFormProps {
    onSubmit: (values: ComputeModelFormValues) => void;
    onCancel: () => void;
    initialValues?: ComputeModel;
}

export interface ComputeModelFormValues {
    internalName: string;
    displayName: string;
    dockerImage: string;
    accessType: ComputeModelAccessType;
}

const getInitialValues = (initialValues?: ComputeModel): ComputeModelFormValues => {
    if (initialValues) {
        return {
            internalName: initialValues.internalName,
            displayName: initialValues.displayName,
            dockerImage: initialValues.dockerImage,
            accessType: initialValues.accessType,
        };
    }

    return {
        internalName: '',
        displayName: '',
        dockerImage: 'mivia/model',
        accessType: 'PUBLIC',
    };
};

export const ComputeModelForm: FC<ComputeModelFormProps> = ({
    onSubmit,
    onCancel,
    initialValues,
}) => {
    const form = useForm<ComputeModelFormValues>({
        initialValues: getInitialValues(initialValues),
    });
    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Stack>
                <TextInput
                    disabled={!!initialValues}
                    label="Internal name"
                    placeholder="Internal unique key, without spaces"
                    required
                    {...form.getInputProps('internalName')}
                />
                <TextInput
                    label="Displayed name"
                    placeholder="Name visible in Admin Panel"
                    required
                    {...form.getInputProps('displayName')}
                />
                <TextInput
                    label="Docker image (without version)"
                    placeholder={'e.g., "registry.mivia.io/mivia/model-name"'}
                    required
                    {...form.getInputProps('dockerImage')}
                />
                <SegmentedControl
                    {...form.getInputProps('accessType')}
                    data={[
                        { value: 'PUBLIC', label: 'Public' },
                        { value: 'PRIVATE', label: 'Private' },
                    ]}
                />

                <Group mt="md" position="right">
                    <Button onClick={() => onCancel()} variant="outline">
                        Cancel
                    </Button>
                    <Button type="submit">{initialValues ? 'Save' : 'Add'}</Button>
                </Group>
            </Stack>
        </form>
    );
};
