import { Input, TextInput } from '@mantine/core';
import { useId } from '@mantine/hooks';
import { IconAt } from '@tabler/icons-react';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { IMask, IMaskInput } from 'react-imask';
type MaskedDateInputProps = React.ComponentPropsWithoutRef<typeof TextInput>;

function parseDate(dateStr: string): Date | null {
    const parsedDate = dayjs(dateStr, 'YYYY-MM-DD');

    if (parsedDate.isValid()) {
        return parsedDate.toDate();
    }

    return null; // return null if parsing fails
}

function formatDate(date: Date): string {
    return dayjs(date).format('YYYY-MM-DD');
}

export const MaskedDateInput: FC<MaskedDateInputProps> = (props) => {
    return (
        <Input.Wrapper id={props.id}>
            <Input<any>
                autofix={true}
                blocks={{
                    d: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 31,
                        maxLength: 2,
                    },
                    m: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 12,
                        maxLength: 2,
                    },
                    Y: {
                        mask: IMask.MaskedRange,
                        from: 1900,
                        to: 9999,
                    },
                }}
                component={IMaskInput}
                format={(value: Date) => formatDate(value)}
                mask={Date}
                parse={(value: string) => parseDate(value)}
                pattern="Y-`m-`d"
                {...props}
            />
        </Input.Wrapper>
    );
};
