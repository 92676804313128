import React from 'react';

import { useAdminStyles } from '../components/admin-styles';
import { ImagesPanel } from '../components/images/ImagesPanel';

export function AdminImages() {
    const { classes } = useAdminStyles();

    return (
        <div className={classes.container}>
            <ImagesPanel></ImagesPanel>
        </div>
    );
}
