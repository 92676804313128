import { useQuery } from '@tanstack/react-query';

import { ExampleResultsType } from '@/features/models/types/example-results-type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { MODEL_EXAMPLE_RESULTS } from '@/types/query-keys';

export const getExampleResult = async (modelId: string): Promise<ExampleResultsType> => {
    return (await axios.get(`/admin/compute-models/${modelId}/example`)).data;
};

type QueryFnType = typeof getExampleResult;

type UseExampleResultOptions = {
    config?: QueryConfig<QueryFnType>;
    modelId: string;
};

export const useExampleResult = ({ config, modelId }: UseExampleResultOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [MODEL_EXAMPLE_RESULTS, modelId],
        queryFn: () => getExampleResult(modelId),
        ...config,
    });
};
