import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { SupportedLanguagesList } from 'mivia-shared';
import { initReactI18next } from 'react-i18next';

import { isDevelopment, REACT_APP_API_BASE_URL } from '@/config';

i18n.use(languageDetector)
    .use(backend)
    .use(initReactI18next)
    .init({
        returnNull: false,
        backend: {
            loadPath: REACT_APP_API_BASE_URL + '/languages/{{lng}}',
        },
        fallbackLng: 'en',
        supportedLngs: SupportedLanguagesList,
        debug: isDevelopment(),
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            caches: ['cookie'],
        },
    });

export const getLanguage = (): 'en' | 'de' => i18n.language as 'en' | 'de';

export default i18n;
