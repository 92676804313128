import { Alert } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Panel } from '@/components/Panel';
import { SandboxStepper } from '@/features/sandbox/components/SandboxStepper';

export const SandboxPage: FC = () => {
    const { t } = useTranslation();
    return (
        <Panel showCaption={false}>
            <Alert
                color="forest"
                icon={<IconInfoCircle />}
                mb={16}
                title={t('sandbox.title')}
                variant="light"
            >
                <Trans i18nKey="sandbox.description">
                    MiViA Sandbox provides a place to test and compare different models on a single
                    image. Simply choose an image and select the models you want to run for
                    analysis. Review the results directly on the platform, but note that the results
                    are not saved and cannot be exported. To save your results, use the specific
                    model page.
                </Trans>
            </Alert>
            <SandboxStepper />
        </Panel>
    );
};
