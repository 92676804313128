import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

const impersonateUser = async (userId: string): Promise<any> => {
    return (await axios.post(`/users/${userId}/impersonate`)).data;
};

type UseImpersonateUserOptions = {
    config?: MutationConfig<typeof impersonateUser>;
};

export const useImpersonateUser = ({ config }: UseImpersonateUserOptions = {}) => {
    const navigate = useNavigate();
    return useMutation({
        ...config,
        mutationFn: impersonateUser,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([]);
            navigate('/dashboard');
        },
    });
};
