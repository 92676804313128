import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { PROFILE } from '@/types/query-keys';

import { UserProfile } from '../types/user-profile';

export const getProfile = async (): Promise<UserProfile> => {
    return (await axios.get('/users/me/account')).data;
};

type QueryFnType = typeof getProfile;

type UseProfileOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useProfile = ({ config }: UseProfileOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [PROFILE, 'me'],
        queryFn: getProfile,
        ...config,
    });
};
