import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { PUBLIC_FILES } from '@/types/query-keys';

export type PublicImage = {
    filename: string;
    metadata: {
        'content-type': string;
        'X-Amz-Meta-Filename': string;
    };
};

export const getPublicImages = async (): Promise<PublicImage[]> => {
    return (await axios.get('/admin/images/public')).data;
};

type QueryFnType = typeof getPublicImages;

type UsePublicImagesOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const usePublicImages = ({ config }: UsePublicImagesOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [PUBLIC_FILES],
        queryFn: getPublicImages,
        ...config,
    });
};
