import { useUserInfo } from '@/features/auth/api/me';
import { AuthUser } from '@/types/auth-user';

export const useAuthUser = (): AuthUser | null => {
    // const { accessTokenPayload } = useSessionContext();
    // const { role, name, email, id } = accessTokenPayload;
    // return { role, name, email, id };
    const { data } = useUserInfo();

    if (!data) return null;

    return data;
};
