import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mantine/core';
import React from 'react';

import { useCreateModelNews } from '@/features/admin/components/news/api/create-model-news';
import { useModelNewsDetails } from '@/features/admin/components/news/api/model-news-details';
import { useUpdateModelNews } from '@/features/admin/components/news/api/update-model-news';
import { NewsForm } from '@/features/admin/components/news/NewsForm';
import { ModelNewsFormValues } from '@/features/admin/components/news/types/model-news-form-values';

interface NewsModalProps {
    newsId?: number;
}

export const NewsModal = NiceModal.create<NewsModalProps>((props) => {
    const modal = useModal();
    const { visible, remove } = modal;

    const { data: newsDetails } = useModelNewsDetails({ id: props.newsId });
    const update = useUpdateModelNews();
    const create = useCreateModelNews();

    const handleSubmit = (values: ModelNewsFormValues) => {
        if (props.newsId) update.mutate({ id: props.newsId, data: values });
        else create.mutate(values);
        remove();
    };
    const handleCancel = () => {
        remove();
    };

    return (
        <Modal centered onClose={handleCancel} opened={visible} size={700} title="Edit news">
            <NewsForm
                initialValues={newsDetails}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
            ></NewsForm>
        </Modal>
    );
});
