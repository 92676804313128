import { Container, createStyles, Tabs } from '@mantine/core';
import { IconLockOpen } from '@tabler/icons-react';
import { TFunction } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { TabItem, useCreateNavigationLinks } from '@/components/Links/useCreateNavigationLinks';
import { useModels } from '@/features/models/api/models';

const useStyles = createStyles((theme) => ({
    header: {
        paddingTop: theme.spacing.sm,
        backgroundColor: theme.colors[theme.primaryColor][8],
        borderBottom: `1px solid ${theme.colors[theme.primaryColor][8]}`,
        // marginBottom: 120,
    },

    mainSection: {
        paddingBottom: theme.spacing.sm,
    },

    tabs: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    tabsList: {
        borderBottom: '0 !important',
    },

    tab: {
        fontWeight: 500,
        height: 38,
        backgroundColor: 'transparent',
        color: theme.colors.gray[2],

        '&:hover': {
            backgroundColor: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 7],
        },

        '&[data-active]': {
            // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            // borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],

            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
            color: theme.colors.dark[7],
        },
    },
}));

const createTabItems = (tabs: TabItem[], currentTab: string, t: TFunction) => {
    console.log('createTabItems rendered');
    const items = tabs
        .filter((tab) => !tab.onDemandVisible) // Do not show onDemandVisible tabs
        .map((tab) => {
            const icon = tab.accessType == 'PRIVATE' ? <IconLockOpen size={16} /> : undefined;
            return (
                <Tabs.Tab icon={icon} key={tab.path} value={tab.path}>
                    {tab.label}
                </Tabs.Tab>
            );
        });

    // if currentab is on list ondemand
    const onDemandTabActive = tabs.find(
        (tab) => tab.onDemandVisible && tab.path.startsWith(currentTab)
    );

    if (onDemandTabActive) {
        items.push(
            <Tabs.Tab key={onDemandTabActive.path} value={onDemandTabActive.path}>
                {t('tabs.sandbox')}
            </Tabs.Tab>
        );
    }

    return items;
};

export const Navigation = () => {
    const { data: models } = useModels();
    const { t } = useTranslation();

    const [currentTab, setCurrentTab] = React.useState('dashboard');
    const location = useLocation();
    const { classes } = useStyles();
    const navigation = useNavigate();

    const tabs = useCreateNavigationLinks(models ?? [], false, t);

    // Set tab based on current location, if not found assume first tab
    useEffect(() => {
        const currenTab =
            tabs.find((tab) => tab.path.startsWith(location.pathname))?.path || tabs[0].path;
        setCurrentTab(currenTab);
    }, [tabs, location]);

    const items = useMemo(() => createTabItems(tabs, currentTab, t), [currentTab, t, tabs]);

    const handleTabChange = (value: string) => {
        const path = tabs.find((tab) => tab.path === value)?.path;
        if (path) navigation(path);
    };

    return (
        <Container size="xl">
            <Tabs
                classNames={{
                    root: classes.tabs,
                    tabsList: classes.tabsList,
                    tab: classes.tab,
                }}
                data-cy={'header-tab-lists-name'}
                onTabChange={handleTabChange}
                value={currentTab}
                variant="outline"
            >
                <Tabs.List>{items}</Tabs.List>
            </Tabs>
        </Container>
    );
};
