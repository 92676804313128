import { Container, ContainerProps } from '@mantine/core';
import React, { FunctionComponent } from 'react';

interface MainContainerProps extends ContainerProps {
    children: React.ReactNode;
}

export const MainContainer: FunctionComponent<MainContainerProps> = ({
    children,
    ...props
}: MainContainerProps) => {
    return (
        <Container
            style={{ paddingTop: 10 }}
            sx={(theme) => ({
                backgroundColor: theme.white,
                minHeight: '100%',
                boxShadow:
                    '5px 0px 17px -14px rgba(66, 68, 90, 0.56), -5px 0px 17px -14px rgba(66, 68, 90, 0.56)',
            })}
            {...props}
        >
            {children}
        </Container>
    );
};
