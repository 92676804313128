import { useQuery } from '@tanstack/react-query';
import { SupportedLanguage } from 'mivia-shared';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TRANSLATIONS } from '@/types/query-keys';

export const getTranslations = async (lang: SupportedLanguage): Promise<string> => {
    const ret = (await axios.get(`/languages/${lang}`)).data;
    return JSON.stringify(ret, null, 2);
};

type QueryFnType = typeof getTranslations;

type UseTranslationsOptions = {
    config?: QueryConfig<QueryFnType>;
    lang: SupportedLanguage;
};

export const useTranslations = ({ config, lang }: UseTranslationsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [TRANSLATIONS, lang],
        queryFn: () => getTranslations(lang),
        ...config,
    });
};
