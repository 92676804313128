import { Badge } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { JobStatus } from '../../types/user-jobs';

interface RoleBadgeProps {
    status: JobStatus;
}

export const getJobLabel = (status: JobStatus) => {
    switch (status) {
        case 'NEW':
            return 'New';
        case 'FAILED':
            return 'Failed';
        case 'CACHED':
            return 'Cached';
        default:
            return 'Unknown';
    }
};

export const JobStatusBadge: FunctionComponent<RoleBadgeProps> = ({ status }) => {
    switch (status) {
        case 'NEW':
            return (
                <Badge color="teal" radius="xs" variant="outline">
                    {getJobLabel(status)}
                </Badge>
            );
        case 'FAILED':
            return (
                <Badge color="red" radius="xs" variant="outline">
                    {getJobLabel(status)}
                </Badge>
            );
        case 'CACHED':
            return (
                <Badge color="gray" radius="xs" variant="outline">
                    {getJobLabel(status)}
                </Badge>
            );
        default:
            return null;
    }
};
