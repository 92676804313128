import SuperTokens from 'supertokens-web-js';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
import Session from 'supertokens-web-js/recipe/session';

import { REACT_APP_API_BASE_URL, REACT_APP_FRONTEND_URL } from '@/config';

export const initAuth = () => {
    SuperTokens.init({
        appInfo: {
            appName: 'mivia-server',
            apiDomain: REACT_APP_API_BASE_URL,
            apiBasePath: '/api/auth',
        },
        recipeList: [
            // EmailVerification.init({ mode: 'REQUIRED', disableDefaultUI: true }),
            // EmailPassword.init({
            // signInAndUpFeature: { disableDefaultUI: true },
            // resetPasswordUsingTokenFeature: { disableDefaultUI: true },
            // emailVerificationFeature: { mode: 'OFF', disableDefaultUI: true },
            // }),

            Session.init(),
            EmailPassword.init(),
        ],
    });
};
