import { Center, Loader } from '@mantine/core';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useAdminStyles } from '@/features/admin/components/admin-styles';

import { useJob } from '../api/job';
import { JobInfo } from '../components/jobs/JobInfo';

export const AdminJob: FC = () => {
    const { id } = useParams();
    const { classes } = useAdminStyles();
    const jobQuery = useJob({ jobId: id });

    if (!jobQuery.data)
        return (
            <Center>
                <Loader size="xl" variant="dots" />
            </Center>
        );

    return (
        <div className={classes.container}>
            <JobInfo job={jobQuery.data}></JobInfo>
        </div>
    );
};
