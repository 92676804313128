import { TFunction } from 'i18next';
import { ComputeModelAccessType, Model } from 'mivia-shared';

export interface TabItem {
    label: string;
    path: string;
    name: string;
    modelId?: string;
    accessType?: ComputeModelAccessType;
    onDemandVisible?: boolean;
}

export const useCreateNavigationLinks = (
    models: Model[],
    modelsOnly = false,
    t: TFunction
): TabItem[] => {
    const modelsLinks: TabItem[] = models.map((model) => ({
        name: model.name,
        label: t(`model.${model.name}`),
        modelId: model.id,
        path: `/models/${model.name.toLowerCase()}`,
        accessType: model.accessType,
    }));

    if (modelsOnly) return modelsLinks;

    return [
        {
            name: 'dashboard',
            label: t('tabs.dashboard'),
            path: '/dashboard',
        },
        ...modelsLinks,
        {
            name: 'history',
            label: t('tabs.history'),
            path: '/history',
        },
        {
            name: 'sandbox',
            label: t('tabs.sandbox'),
            path: '/sandbox',
            onDemandVisible: true,
        },
    ] as TabItem[];
};
