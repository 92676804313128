import { useQuery } from '@tanstack/react-query';

import { NodeType } from '@/features/admin/types/nodeType';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { NODES } from '@/types/query-keys';

export const queryNodes = async (): Promise<NodeType[]> => {
    const { data } = await axios.get<NodeType[]>(`/admin/nodes`);
    return data;
};

type QueryFnType = typeof queryNodes;

type UseNodesOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useQueryNodes = ({ config }: UseNodesOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [NODES],
        refetchInterval: 15000,
        queryFn: () => queryNodes(),
        ...config,
    });
};
