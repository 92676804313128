import React, { useEffect, useState, useCallback } from 'react';
import Session from 'supertokens-web-js/recipe/session';

import { handleSessionExpired } from '@/features/auth/api/logout';

interface SessionContextProps {
    isLoading: boolean;
    userId: string | undefined;
    accessTokenPayload: any | undefined;
    refreshAuth: () => void;
}

const SessionContext = React.createContext<SessionContextProps>({
    isLoading: true,
    userId: undefined,
    accessTokenPayload: undefined,
    refreshAuth: () => {},
});

interface SessionContextProviderProps {
    children: React.ReactNode;
}

export const SessionContextProvider: React.FC<SessionContextProviderProps> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [userId, setUserId] = useState<string | undefined>();
    const [accessTokenPayload, setAccessTokenPayload] = useState<any | undefined>();

    const fetchSessionData = useCallback(async () => {
        try {
            const sessionExists = await Session.doesSessionExist();
            if (sessionExists) {
                const uid = await Session.getUserId();
                const accessToken = await Session.getAccessTokenPayloadSecurely();
                setUserId(uid);
                setAccessTokenPayload(accessToken);
            } else {
                setUserId(undefined);
                setAccessTokenPayload(undefined);
            }
        } catch (error) {
            // Handle any errors related to fetching session data here
            console.error('Error fetching session data:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const refreshAuth = useCallback(() => {
        setIsLoading(true);
        fetchSessionData();
    }, [fetchSessionData]);

    useEffect(() => {
        fetchSessionData();
    }, [fetchSessionData]);

    useEffect(() => {
        if (!isLoading && !userId) {
            handleSessionExpired();
        }
    }, [isLoading, userId]);

    return (
        <SessionContext.Provider value={{ isLoading, userId, accessTokenPayload, refreshAuth }}>
            {children}
        </SessionContext.Provider>
    );
};

export const useSessionContext = () => React.useContext(SessionContext);
