import { createStyles } from '@mantine/core';

export const useChartStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
    },
    icon: {
        opacity: 0.3,
        position: 'absolute',
        right: -7,
        top: 5,
        '&:hover': {
            opacity: 1.0,
        },
    },
    select: {
        position: 'absolute',
        bottom: 10,
        left: 60,
    },
    title: {
        textAlign: 'center',
    },
}));
