import { Route, Routes } from 'react-router-dom';

import { SandboxPage } from '@/features/sandbox/components';

export const SandboxRoutes = () => {
    return (
        <Routes>
            <Route element={<SandboxPage />} path="" />
        </Routes>
    );
};
