import React from 'react';

import { useAdminStyles } from '../components/admin-styles';
import { Users } from '../components/users/Users';

export function AdminUsers() {
    const { classes } = useAdminStyles();
    return (
        <div className={classes.container} data-cy="admin-panel-user-section">
            <Users />
        </div>
    );
}
