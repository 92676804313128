import { createStyles, Paper, px, ThemeIcon } from '@mantine/core';
import React from 'react';

const ICON_SIZE = 60;

const useStyles = createStyles((theme) => ({
    card: {
        position: 'relative',
        overflow: 'visible',
        padding: theme.spacing.xl,
        paddingTop: px(theme.spacing.xl) * 1.5 + ICON_SIZE / 3,
        width: '100%',
    },

    icon: {
        position: 'absolute',
        top: -ICON_SIZE / 3,
        left: `calc(50% - ${ICON_SIZE / 2}px)`,
        // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
    },
}));

interface PanelWithIconProps {
    icon?: React.ReactNode;
    children?: React.ReactNode;
}

export const PanelWithIcon = ({ icon, children }: PanelWithIconProps) => {
    const { classes } = useStyles();

    return (
        <Paper className={classes.card} mt={ICON_SIZE / 3} radius="md" withBorder>
            <ThemeIcon className={classes.icon} radius={ICON_SIZE} size={ICON_SIZE}>
                {icon}
            </ThemeIcon>
            {children}
        </Paper>
    );
};
