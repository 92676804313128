import React from 'react';

import { Nodes } from '@/features/admin/components/nodes/Nodes';

import { useAdminStyles } from '../components/admin-styles';

export const AdminNodes = () => {
    const { classes } = useAdminStyles();
    return (
        <div className={classes.container}>
            <Nodes />
        </div>
    );
};
