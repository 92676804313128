import { useMutation } from '@tanstack/react-query';

import { VoucherFormValues } from '@/features/admin/components/vouchers/VoucherForm';
import { Voucher } from '@/features/admin/types/voucher';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { VOUCHERS } from '@/types/query-keys';

type UseMutationCreateVoucherDto = VoucherFormValues;

const CreateVouchers = async (data: UseMutationCreateVoucherDto): Promise<Voucher[]> => {
    return (await axios.post('/admin/vouchers/', data)).data;
};

type UseCreateVoucherOptions = {
    config?: MutationConfig<typeof CreateVouchers>;
};

export const useCreateVouchers = ({ config }: UseCreateVoucherOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: CreateVouchers,
        onSuccess: (data: Voucher[], variables, context) => {
            queryClient.invalidateQueries({ queryKey: [VOUCHERS] });
            config?.onSuccess?.(data, variables, context);
        },
    });
};
