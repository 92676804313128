import React from 'react';

import { SvgrComponent } from '@/types/SvgrComponent';

import styles from './MiviaLoader.module.css';

export const MiviaLoader: SvgrComponent = (props) => {
    return (
        <svg className={styles.loader} viewBox="0 0 420 200" width={300} {...props}>
            <g>
                <polygon
                    fill="none"
                    points="111.61,69.87 101.68,108.68 91.01,69.87 62.36,69.87 62.36,140.68 80.97,140.68 80.97,94.87 82.34,94.87 93.42,139.32 109.83,139.32 121.02,94.87 122.38,94.87 122.38,140.68 141,140.68 141,69.87"
                    stroke="black"
                />
                <rect fill="none" height="70.81" stroke="black" width="18.61" x="158.9" y="69.81" />
                <polygon
                    fill="none"
                    points="232.28,69.87 223.29,122.27 219.94,122.27 210.64,69.87 190.98,69.87 206.87,140.68 236.46,140.68 251.94,69.87"
                    stroke="black"
                />
                <rect
                    fill="none"
                    height="70.81"
                    stroke="black"
                    width="18.61"
                    x="266.03"
                    y="69.87"
                />
                <polygon
                    fill="none"
                    points="315.68,69.87 298.63,140.68 316.93,140.68 328.96,84.62 332.51,84.62 344.12,140.68 362.83,140.68 345.69,69.87"
                    stroke="black"
                />
            </g>
        </svg>
    );
};
