import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { VERSION } from '@/types/query-keys';

const getVersion = async (): Promise<string> => {
    return (await axios.get('/version'))?.data;
};

type QueryFnType = typeof getVersion;

type UseVersionOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useVersion = ({ config }: UseVersionOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [VERSION],
        queryFn: getVersion,
        ...config,
    });
};
