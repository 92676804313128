import { Select, SelectProps } from '@mantine/core';
import { getData } from 'country-list';
import React, { FunctionComponent } from 'react';

type CountrySelectProps = Omit<SelectProps, 'data'>;

export const CountrySelect: FunctionComponent<CountrySelectProps> = (props) => {
    return (
        <Select
            {...props}
            data={getData().map((country) => ({
                value: country.code,
                label: country.name,
            }))}
            searchable
            withinPortal={false}
        />
    );
};
