import { useTimeout } from '@mantine/hooks';
import { useState, useEffect } from 'react';

export const useDelayedVisibility = (isVisible: boolean, delay: number) => {
    const [shouldRender, setShouldRender] = useState(isVisible);
    const { start, clear } = useTimeout(() => setShouldRender(false), delay);

    useEffect(() => {
        if (isVisible) {
            clear(); // Clear any existing timeouts
            setShouldRender(true);
        } else {
            start(); // Start the timeout
        }

        return clear; // Clear timeout on unmount
    }, [isVisible, start, clear]);

    return shouldRender;
};
