import {
    Button,
    Divider,
    Group,
    Input,
    Select,
    SimpleGrid,
    Switch,
    TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useShallowEffect } from '@mantine/hooks';
import React, { FunctionComponent } from 'react';

import { FlagGb } from '@/components/LangSelector/FlagGb';
import { FlagGermany } from '@/components/LangSelector/FlagGermany';
import { TextEditor } from '@/components/TextEditor/TextEditor';
import {
    LicenseBillingType,
    LicenseType,
    ProductFormValues,
} from '@/features/admin/components/stripe/types/license-product';

interface ProductFormProps {
    onSubmit: (values: ProductFormValues) => void;
    onCancel: () => void;
    initialValues: ProductFormValues | undefined;
}

const FLAG_SIZE = 20;
const LICENSE_TYPES_VALUES = [
    { value: 'IMAGES_LIMITED', label: 'Images limited' },
    { value: 'TIME_LIMITED', label: 'Time limited' },
    { value: 'NONE', label: 'None' },
];

const LICENSE_BILLING_VALUES = [
    { value: 'ONE_TIME', label: 'One time' },
    { value: 'DAILY', label: 'Daily' },
    { value: 'MONTHLY', label: 'Monthly' },
    { value: 'ANNUAL', label: 'Annual' },
];

export const ProductForm: FunctionComponent<ProductFormProps> = ({
    initialValues,
    onSubmit,
    onCancel,
}) => {
    const form = useForm<ProductFormValues>({
        initialValues: {
            id: initialValues?.id || '',
            titleEn: initialValues?.titleEn || '',
            titleDe: initialValues?.titleDe || '',
            descriptionEn: initialValues?.descriptionEn || '',
            descriptionDe: initialValues?.descriptionDe || '',
            priceEn: initialValues?.priceEn,
            priceDe: initialValues?.priceDe,
            featuresEn: initialValues?.featuresEn || '',
            featuresDe: initialValues?.featuresDe || '',
            stripePriceId: initialValues?.stripePriceId || '',
            licenseType: initialValues?.licenseType || LicenseType.NONE,
            billingType: initialValues?.billingType || null,
            renewalReminderDays: initialValues?.renewalReminderDays || null,
            active: true,
        },
        validate: {
            titleEn: (value) => (value.trim().length > 0 ? null : 'Title is required'),
            titleDe: (value) => (value.trim().length > 0 ? null : 'Title is required'),
            licenseType: (value) => (value ? null : 'License type is required'),
            billingType: (value, data) => {
                if (data.licenseType === LicenseType.NONE) {
                    if (!value) {
                        return null;
                    } else {
                        return 'Billing should remain empty for this license type';
                    }
                }

                if (data.licenseType == LicenseType.IMAGES_LIMITED) {
                    if (value != LicenseBillingType.ONE_TIME) {
                        return 'This license type only supports one time billing';
                    }
                }
                return null;
            },
            renewalReminderDays: (value, data) => {
                // Reminder can be set only for time limited licenses
                if (value && value < 0) {
                    return 'Days should be positive';
                }
                if (data.licenseType !== LicenseType.TIME_LIMITED) {
                    return 'Reminder can be set only for time limited licenses';
                }
            },
        },
    });

    useShallowEffect(() => {
        console.log('ProductForm loaded');
        if (initialValues) {
            form.setValues(initialValues);
            form.resetDirty(initialValues);
        }
    }, [initialValues]);

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Input type="hidden" {...form.getInputProps('id')}></Input>
            <SimpleGrid cols={2} spacing="sm">
                <div>
                    <FlagGb width={FLAG_SIZE} />
                </div>
                <div>
                    <FlagGermany width={FLAG_SIZE} />
                </div>

                <div>
                    <TextInput label="Title" required {...form.getInputProps('titleEn')} />
                </div>
                <div>
                    <TextInput label="Title" required {...form.getInputProps('titleDe')} />
                </div>

                <div>
                    <TextInput label="Description" {...form.getInputProps('descriptionEn')} />
                </div>
                <div>
                    <TextInput label="Description" {...form.getInputProps('descriptionDe')} />
                </div>
                <div>
                    <TextInput label="Price" {...form.getInputProps('priceEn')} />
                </div>
                <div>
                    <TextInput label="Price" {...form.getInputProps('priceDe')} />
                </div>

                <div>
                    <TextEditor label="Features" {...form.getInputProps('featuresEn')}></TextEditor>
                </div>

                <div>
                    <TextEditor label="Features" {...form.getInputProps('featuresDe')}></TextEditor>
                </div>
            </SimpleGrid>
            <Divider mb="md" mt="md" />
            <SimpleGrid cols={2} spacing="sm">
                <div>
                    <TextInput label="Stripe price ID" {...form.getInputProps('stripePriceId')} />
                </div>
                <div>
                    <TextInput
                        label="Send reminder before license ends (days)"
                        type="number"
                        {...form.getInputProps('renewalReminderDays')}
                        onChange={(e) => {
                            form.setFieldValue(
                                'renewalReminderDays',
                                parseInt(e.target.value) || null
                            );
                        }}
                    />
                </div>
                <div>
                    <Select
                        data={LICENSE_TYPES_VALUES}
                        label="License type"
                        required
                        withinPortal={true}
                        {...form.getInputProps('licenseType')}
                    />
                    <Input.Description pt={4}>
                        Select {'"None"'} if license should not be buyable
                    </Input.Description>
                </div>

                <div>
                    <Select
                        clearable
                        data={LICENSE_BILLING_VALUES}
                        label="License billing"
                        withinPortal={true}
                        {...form.getInputProps('billingType')}
                    />
                </div>

                <div></div>
                <div>
                    <Switch
                        defaultChecked
                        label="License is active"
                        {...form.getInputProps('active', { type: 'checkbox' })}
                    />
                </div>
            </SimpleGrid>

            <Group mt="md" position="right">
                <Button onClick={() => onCancel()} variant="outline">
                    Cancel
                </Button>
                <Button type="submit">Save</Button>
            </Group>
        </form>
    );
};
