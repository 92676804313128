import { Stack } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { ProductsPanel } from '@/features/admin/components/stripe/ProductsPanel';
import { ProductsTable } from '@/features/admin/components/stripe/ProductsTable';

export const Products: FunctionComponent = () => {
    return (
        <Stack>
            <ProductsPanel></ProductsPanel>
            <ProductsTable></ProductsTable>
        </Stack>
    );
};
