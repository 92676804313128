import { useForm } from '@mantine/form';
import { useEffect } from 'react';

import { DemoConfigType } from '@/features/admin/components/settings/api/demo-config';

export type DemoConfigForm = DemoConfigType;

export const useDemoConfigForm = (initData: DemoConfigType | undefined) => {
    const form = useForm<DemoConfigForm>({
        initialValues: {
            maxImages: initData?.maxImages ?? 0,
            demoDuration: initData?.demoDuration ?? 0,
            extensionDuration: initData?.extensionDuration ?? 0,
            reminderBeforeExpiry: initData?.reminderBeforeExpiry ?? 0,
            reminderBeforeExtensionExpiry: initData?.reminderBeforeExtensionExpiry ?? 0,
        },
    });

    useEffect(() => {
        initData && form.setValues(initData);
    }, [initData]);

    return form;
};
