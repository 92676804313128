import NiceModal from '@ebay/nice-modal-react';
import { Button, Loader, MantineProvider, Text } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';

import { queryClient } from '@/lib/react-query';
import { initAuth } from '@/lib/supertokens';
import { theme } from '@/lib/theme';
import { CookieConsent } from '@/providers/cookie-consent';
import { SessionContextProvider } from '@/providers/session-context';

const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
    return (
        <div role="alert">
            <Text align="center" size="lg">
                Ooops, something went wrong :(
                {error}
            </Text>
            <Button onClick={() => window.location.assign(window.location.origin)}>Refresh</Button>
        </div>
    );
};

const LazyLoader = () => (
    <div style={{ minHeight: '100vh', width: '100%', display: 'grid', placeItems: 'center' }}>
        <Loader size="lg" variant="bars" />
    </div>
);

type AppProviderProps = {
    children: React.ReactNode;
};

initAuth();
// initSentry();

export const AppProvider = ({ children }: AppProviderProps) => {
    return (
        // <LazyLoader />
        <React.Suspense fallback={<LazyLoader />}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <HelmetProvider>
                    <QueryClientProvider client={queryClient}>
                        <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
                            <SessionContextProvider>
                                <NiceModal.Provider>
                                    <ReactQueryDevtools />
                                    <Router>{children}</Router>
                                </NiceModal.Provider>
                            </SessionContextProvider>
                            <Notifications />
                            <CookieConsent />
                        </MantineProvider>
                    </QueryClientProvider>
                </HelmetProvider>
            </ErrorBoundary>
        </React.Suspense>
    );
};
