import React, { FC } from 'react';

import { ScrollableTable } from '@/components/ScrollableTable';
import { useAdminStyles } from '@/features/admin/components/admin-styles';
import { JobRow } from '@/features/admin/components/jobs/JobRow';
import { useJobsContext } from '@/features/admin/components/jobs/JobsContext';
import { AdminViewableUserJob } from '@/features/admin/types/user-jobs';

export const JobsTable: FC = () => {
    const [selectedJob, setSelectedJob] = React.useState<string | null>(null);
    const { data: jobs, hasNextPage, fetchNextPage } = useJobsContext();

    const allJobs =
        jobs?.pages.reduce((acc, page) => [...acc, ...page.data], [] as AdminViewableUserJob[]) ||
        [];

    const { classes } = useAdminStyles();

    const handleRowClick = (jobId: string) => {
        setSelectedJob(jobId);
    };

    const rows = allJobs.map((j) => (
        <JobRow isSelected={selectedJob == j.id} job={j} key={j.id} onClick={handleRowClick} />
    ));
    return (
        <ScrollableTable hasMore={hasNextPage} loadMore={fetchNextPage}>
            <thead className={classes.header}>
                <tr>
                    <th>Job date</th>
                    <th>Owner</th>
                    <th>User Rating</th>
                    <th>Admin Rating</th>
                    <th>Duration</th>
                    <th>Status</th>
                    <th>Model</th>
                    <th style={{ width: 250 }}>Image</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </ScrollableTable>
    );
};
