import { useQuery } from '@tanstack/react-query';

import { AdminViewableUserJob } from '@/features/admin/types/user-jobs';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { JOB } from '@/types/query-keys';

export const getJob = async (jobId: string | undefined): Promise<AdminViewableUserJob> => {
    return (await axios.get(`/admin/jobs/${jobId}`))?.data;
};

type QueryFnType = typeof getJob;

type UseJobOptions = {
    jobId?: string | undefined;
    config?: QueryConfig<QueryFnType>;
};

export const useJob = ({ config, jobId }: UseJobOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [JOB, jobId],
        enabled: !!jobId,
        refetchInterval: (job) => (job?.status === 'PENDING' ? 5000 : false),
        queryFn: () => getJob(jobId),
        ...config,
    });
};
