import { Container, createStyles, Group } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { MiviaLogo } from '@/components/Layout/MiviaLogo';
import { MobileMenu } from '@/components/Layout/MobileMenu';
import { UserMenu } from '@/components/Layout/UserMenu';
import { useAuthUser } from '@/hooks/useUser';

const useStyles = createStyles((theme) => ({
    header: {
        paddingTop: theme.spacing.sm,
        backgroundColor: theme.colors[theme.primaryColor][8],
        borderBottom: `1px solid ${theme.colors[theme.primaryColor][8]}`,
        minHeight: 65,
        // marginBottom: 0,
    },
}));

interface HeaderTabsProps {
    showLogo?: boolean;
    children?: React.ReactNode;
}

export const AppBar = ({ children, showLogo = true }: HeaderTabsProps) => {
    const { classes } = useStyles();
    const user = useAuthUser();

    return (
        <header className={classes.header}>
            <Container size="xl">
                <Group position="apart">
                    {showLogo && (
                        <Link to="dashboard">
                            <MiviaLogo variant="white" />
                        </Link>
                    )}

                    <div style={{ flexGrow: 1 }} />
                    <MobileMenu />
                    <UserMenu user={user} />
                </Group>
            </Container>
            {children}
        </header>
    );
};
