import { useModal } from '@ebay/nice-modal-react';
import { Button, Group } from '@mantine/core';
import React, { FunctionComponent, useState } from 'react';

import { useDeleteVoucher } from '@/features/admin/api/delete-voucher';
import { useQueryVouchers } from '@/features/admin/api/vouchers';
import { VoucherModal } from '@/features/admin/components/vouchers/VoucherModal';
import { VoucherTable } from '@/features/admin/components/vouchers/VoucherTable';

export const Vouchers: FunctionComponent = () => {
    const [page, setPage] = useState<number>(1);
    const addVoucherModal = useModal(VoucherModal);

    const vouchersQuery = useQueryVouchers({ page: page, pageSize: 1000000 });
    const deleteVoucher = useDeleteVoucher();

    const handleDelete = (id: string) => {
        deleteVoucher.mutate(id);
    };

    const { vouchers, total } = vouchersQuery.data || { vouchers: [], total: 0 };

    return (
        <>
            <Group position="right">
                <Button onClick={() => addVoucherModal.show()}>Create voucher</Button>
            </Group>
            <VoucherTable onDelete={(id) => handleDelete(id)} vouchers={vouchers} />
        </>
    );
};
