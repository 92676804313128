import { Badge, SimpleGrid } from '@mantine/core';
import React, { FC } from 'react';

import { Panel } from '@/components/Panel';
import { useServiceStatus } from '@/features/admin/components/settings/api/service-status';

const ServiceStatusBadge: FC<{ status: boolean | undefined }> = ({ status }) => {
    if (status === undefined) return <Badge color="yellow">Loading</Badge>;
    return <Badge color={status ? 'green' : 'red'}>{status ? 'Online' : 'Offline'}</Badge>;
};

export const ServicesPanel: FC = () => {
    const { data: mailStatus } = useServiceStatus({ service: 'mail' });
    return (
        <Panel title="Services status">
            <SimpleGrid cols={2}>
                <div>Mail service</div>
                <ServiceStatusBadge status={mailStatus} />
            </SimpleGrid>
        </Panel>
    );
};
