import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mantine/core';
import React from 'react';

import { useCreateVouchers } from '@/features/admin/api/create-voucher';
import { VoucherForm, VoucherFormValues } from '@/features/admin/components/vouchers/VoucherForm';
import { VouchersList } from '@/features/admin/components/vouchers/VouchersList';
import { Voucher } from '@/features/admin/types/voucher';

export const VoucherModal = NiceModal.create((props) => {
    const [newVouchers, setNewVouchers] = React.useState<Voucher[]>([]);
    const modal = useModal();

    const createVouchers = useCreateVouchers({
        config: {
            onSuccess: (data: Voucher[]) => {
                console.log(data);
                setNewVouchers(data);
            },
        },
    });
    const { hide, visible, remove } = modal;

    const handleSubmit = async (values: VoucherFormValues) => {
        createVouchers.mutate(values);
    };

    return (
        <Modal centered onClose={() => hide()} opened={visible} title="Create vouchers">
            <div>
                {newVouchers.length > 0 ? (
                    <VouchersList onOk={() => remove()} vouchers={newVouchers} />
                ) : (
                    <VoucherForm onCancel={() => hide()} onSubmit={handleSubmit} />
                )}
            </div>
        </Modal>
    );
});
