import React, { Dispatch, useContext, useState } from 'react';

import { ImagesFilterFormType } from '@/features/admin/components/images/ImagesFilterFormType';

type ImagesContextType = {
    filter: ImagesFilterFormType | undefined;
    setFilter: Dispatch<ImagesFilterFormType | undefined>;
};

export const ImagesContext = React.createContext<ImagesContextType | undefined>(undefined);

interface ImagesContextProviderProps {
    children: React.ReactNode;
}

/**
 * Provides Images filter context to all children
 * @param children
 * @constructor
 */
export const ImagesContextProvider: React.FC<ImagesContextProviderProps> = ({ children }) => {
    const [filter, setFilter] = useState<ImagesFilterFormType | undefined>();

    return (
        <ImagesContext.Provider value={{ filter, setFilter }}>{children}</ImagesContext.Provider>
    );
};

/**
 * Returns Images context
 */
export const useImagesContext = () => {
    const context = useContext(ImagesContext) as ImagesContextType;
    if (!context) {
        throw new Error('useImagesContext must be used within a ImagesContextProvider');
    }
    return context;
};
