import { useMutation } from '@tanstack/react-query';

import { showModelUpdated } from '@/features/admin/api/notifications';
import { ComputeModelFormValues } from '@/features/admin/components/compute-models/ComputeModelForm';
import { ComputeModel } from '@/features/admin/types/compute-model';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { COMPUTE_MODELS } from '@/types/query-keys';

export type UseMutationUpdateComputeModelDto = {
    id: string;
    values: ComputeModelFormValues;
};

const updateComputeModel = async (
    data: UseMutationUpdateComputeModelDto
): Promise<ComputeModel> => {
    return (await axios.put(`/admin/compute-models/${data.id}`, data.values)).data;
};

type UseUpdateComputeModelOptions = {
    config?: MutationConfig<typeof updateComputeModel>;
};

export const useUpdateComputeModel = ({ config }: UseUpdateComputeModelOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: updateComputeModel,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [COMPUTE_MODELS] });
            showModelUpdated();
        },
    });
};
