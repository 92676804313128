import Axios from 'axios';
import Session from 'supertokens-web-js/recipe/session';

import { REACT_APP_API_BASE_URL } from '@/config';

const axiosInstance = Axios.create({
    baseURL: REACT_APP_API_BASE_URL,
    withCredentials: true,
});

// Must be before others
// Session.addAxiosInterceptors(axiosInstance);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    }
    // (error) => {
    //     if (error.response.status === 403) {
    //         window.location.href = '/auth/login';
    //     } else {
    //         return Promise.reject(error);
    //     }
    // }
);

// axiosInstance.interceptors.response
//     .use
// (response) => {
//     return response.data;
// }
// (error) => {
//     const message = error?.response?.data?.message || error?.message;
//     console.log('Fatal error: ', message);
//     // return Promise.reject(error);
// }
// ();

export const axios = axiosInstance;
