import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ActionIcon, CopyButton, Modal, Stack, Text, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconClipboard, IconClipboardCheck } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AccessKeyModalProps {
    code: string;
}

export const showKeyCopiedNotification = (title: string, message: string) => {
    showNotification({
        id: 'key-copied',
        autoClose: 5000,
        title: title,
        message: message,
        color: 'primary',
    });
};

export const AccessKeyModal = NiceModal.create<AccessKeyModalProps>((props) => {
    const { t } = useTranslation();
    const modal = useModal();
    const { hide, visible } = modal;

    return (
        <Modal
            centered
            onClose={() => hide()}
            opened={visible}
            title={t('userAccessKeys.newKeyTitle')}
        >
            <Stack>
                <div>
                    <Text fw={500} fz="sm">
                        {t('userAccessKeys.newKeyDescription')}
                    </Text>
                </div>
                <div>
                    <TextInput
                        readOnly
                        rightSection={
                            <CopyButton value={props.code}>
                                {({ copied, copy }) => (
                                    <ActionIcon
                                        onClick={() => {
                                            copy();
                                            showKeyCopiedNotification(
                                                t('common.success'),
                                                t('userAccessKeys.copied')
                                            );
                                        }}
                                        size="lg"
                                        variant="filled"
                                    >
                                        {copied ? <IconClipboardCheck /> : <IconClipboard />}
                                    </ActionIcon>
                                )}
                            </CopyButton>
                        }
                        value={props.code}
                    />
                </div>
            </Stack>
        </Modal>
    );
});
