import { useMutation } from '@tanstack/react-query';

import { showAccessRevoked } from '@/features/admin/api/notifications';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { COMPUTE_MODELS_ACCESS } from '@/types/query-keys';

const deleteModelAccessWrapper = (modelId: string) => {
    return async (userId: string): Promise<any> => {
        return (await axios.delete(`/admin/compute-models/${modelId}/access/${userId}`)).data;
    };
};

type UseDeleteModelAccessOptions = {
    modelId: string;
    config?: MutationConfig<ReturnType<typeof deleteModelAccessWrapper>>;
};

export const useDeleteModelAccess = ({ config, modelId }: UseDeleteModelAccessOptions) => {
    return useMutation({
        ...config,
        mutationFn: deleteModelAccessWrapper(modelId),
        onSuccess: async (data: any) => {
            await queryClient.invalidateQueries([COMPUTE_MODELS_ACCESS, modelId]);
            showAccessRevoked();
        },
    });
};
