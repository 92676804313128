import React from 'react';

import { Settings } from '@/features/admin/components/settings/Settings';

import { useAdminStyles } from '../components/admin-styles';

export function AdminSettings() {
    const { classes } = useAdminStyles();

    return (
        <div className={classes.container}>
            <Settings />
        </div>
    );
}
