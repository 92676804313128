import { useQuery } from '@tanstack/react-query';

import { ManualPosition } from '@/features/admin/types/manual';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { MODEL_MANUAL } from '@/types/query-keys';

export const getManual = async (
    id: string,
    position: ManualPosition,
    language: 'en' | 'de'
): Promise<string> => {
    return (await axios.get(`/admin/compute-models/${id}/manual/${position}/${language}`)).data;
};

type QueryFnType = typeof getManual;

type UseManualOptions = {
    config?: QueryConfig<QueryFnType>;
    id: string;
    position: ManualPosition;
    language: 'en' | 'de';
};

export const useManual = ({ config, id, position, language }: UseManualOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [MODEL_MANUAL, id, position, language],
        queryFn: () => getManual(id, position, language),
        enabled: !!id && !!position,
        ...config,
    });
};
