import { createStyles } from '@mantine/core';
import * as React from 'react';

import { AppBar } from '@/components/Layout/AppBar';
import { Footer } from '@/components/Layout/Footer';
import { GlobalStyles } from '@/components/Layout/GlobalStyles';
import { Navigation } from '@/components/Layout/Navigation';

import { Head } from '../Head';

import { MainContainer } from './MainContainer';

type MainLayoutProps = {
    children: React.ReactNode;
    title: string;
};

const useStyles = createStyles((theme, _params, getRef) => ({
    parent: {
        minHeight: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
    },
}));

export const MainLayout = ({ children, title }: MainLayoutProps) => {
    const { classes } = useStyles();

    return (
        <>
            <GlobalStyles />
            <Head title={title} />
            <div className={classes.parent}>
                <header style={{ zIndex: 1 }}>
                    <AppBar>
                        <Navigation />
                    </AppBar>
                </header>
                <main>
                    <MainContainer size="xl">{children}</MainContainer>
                </main>
                <footer>
                    <Footer />
                </footer>
            </div>
        </>
    );
};
