import { useQuery } from '@tanstack/react-query';

import { AdminProductType } from '@/features/admin/components/stripe/types/license-product';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ADMIN_PRODUCTS } from '@/types/query-keys';

export const queryProduct = async (id?: string): Promise<AdminProductType | undefined> => {
    if (!id) return undefined;
    return (await axios.get(`/admin/products/${id}`)).data;
};

type QueryFnType = typeof queryProduct;

type UseProductsOptions = {
    id: string | undefined;
    config?: QueryConfig<QueryFnType>;
};

export const useQueryProduct = ({ id, config }: UseProductsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [ADMIN_PRODUCTS, id],
        queryFn: () => queryProduct(id),
        enabled: !!id,
        ...config,
    });
};
