import { useQuery } from '@tanstack/react-query';

import { Group } from '@/features/admin/types/group';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { GROUPS } from '@/types/query-keys';

export const getGroups = async (): Promise<Group[]> => {
    return (await axios.get('/admin/groups'))?.data;
};

type QueryFnType = typeof getGroups;

type UseGroupOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useGroups = ({ config }: UseGroupOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [GROUPS],
        queryFn: getGroups,
        ...config,
    });
};
