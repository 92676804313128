import { useModal } from '@ebay/nice-modal-react';
import { Button, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateAccessKey } from '@/features/user-access-keys/api/create-access-key';
import { AccessKeyModal } from '@/features/user-access-keys/component/AccessKeyModal';

type AccessKeysFormValues = {
    name: string | undefined;
};

export const AccessKeysForm: FC = () => {
    const accessKeyModal = useModal(AccessKeyModal);
    const createAccessKey = useCreateAccessKey();
    const { t } = useTranslation();

    const form = useForm<AccessKeysFormValues>({
        initialValues: {
            name: undefined,
        },
    });

    const handleCreate = (values: AccessKeysFormValues) => {
        createAccessKey.mutate(values, {
            onSuccess: (data) => {
                accessKeyModal.show({ code: data.code ?? '' });
            },
        });
    };

    return (
        <div>
            <form onSubmit={form.onSubmit((values) => handleCreate(values))}>
                <Group mt="md">
                    <TextInput
                        placeholder={t('userAccessKeys.labelPlaceholder')}
                        withAsterisk
                        {...form.getInputProps('name')}
                    />
                    <Button type="submit">{t('userAccessKeys.create')}</Button>
                </Group>
            </form>
        </div>
    );
};
