import { useModal } from '@ebay/nice-modal-react';
import { Button, Group, SimpleGrid } from '@mantine/core';
import { IconPlayerPlayFilled, IconPlayerStopFilled } from '@tabler/icons-react';
import React, { FunctionComponent } from 'react';

import { useDeleteNode } from '@/features/admin/api/delete-node';
import { useStartNode } from '@/features/admin/api/start-node';
import { useStopNode } from '@/features/admin/api/stop-node';
import { ConfirmNodeDeleteDialog } from '@/features/admin/components/nodes/ConfirmNodeDeleteDialog';
import { DeployModelModal } from '@/features/admin/components/nodes/DeployModelModal';
import { NodeItemInfo } from '@/features/admin/components/nodes/NodeItemInfo';
import { NodeItemModels } from '@/features/admin/components/nodes/NodeItemModels';
import { NodeItemStats } from '@/features/admin/components/nodes/NodeItemStats';
import { Paper } from '@/features/auth/component/Paper';

import { NodeType } from '../../types/nodeType';

import styles from './NodeItem.module.css';

interface NodeProps {
    node: NodeType;
}

export const NodeItem: FunctionComponent<NodeProps> = ({ node }) => {
    const assignModelModal = useModal(DeployModelModal);
    const deleteNodeDialog = useModal(ConfirmNodeDeleteDialog);
    const deleteNode = useDeleteNode();
    const startNode = useStartNode();
    const stopNode = useStopNode();

    const isBusy = startNode.isLoading || stopNode.isLoading;

    const handleAddModelClick = () => {
        assignModelModal.show({ node });
    };

    const handleDeleteNode = () => {
        deleteNodeDialog.show({ nodeName: node.name }).then((result) => {
            if (result) {
                deleteNode.mutate({ id: node.id });
            }
        });
    };

    const serverClass =
        node.status == 'OFFLINE' ? `${styles.server} ${styles.serverOffline}` : styles.server;

    return (
        <Paper p={undefined} shadow="md">
            <div className={styles.wrapper}>
                <div className={serverClass}>
                    <NodeItemInfo node={node} />
                </div>
                <div className={styles.content}>
                    <SimpleGrid cols={2} spacing="xl">
                        <NodeItemStats node={node} />
                        <NodeItemModels isBusy={isBusy} node={node} />
                    </SimpleGrid>

                    <Group className={styles.buttons} spacing="sm">
                        {node.nodeType == 'CLOUD' && (
                            <>
                                <Button
                                    disabled={node.status === 'ONLINE' || isBusy}
                                    leftIcon={<IconPlayerPlayFilled size={12} />}
                                    loading={startNode.isLoading}
                                    onClick={() => startNode.mutate({ id: node.id })}
                                    variant="outline"
                                >
                                    Start
                                </Button>
                                <Button
                                    disabled={node.status === 'OFFLINE' || isBusy}
                                    leftIcon={<IconPlayerStopFilled size={12} />}
                                    loading={stopNode.isLoading}
                                    onClick={() => stopNode.mutate({ id: node.id })}
                                    variant="outline"
                                >
                                    Stop
                                </Button>
                            </>
                        )}

                        <Button
                            disabled={node.status === 'OFFLINE' || isBusy}
                            onClick={handleAddModelClick}
                        >
                            Add Model
                        </Button>
                        <Button
                            color="red"
                            disabled={!node.canDelete || isBusy}
                            onClick={handleDeleteNode}
                        >
                            Delete Node
                        </Button>
                    </Group>
                </div>
            </div>
        </Paper>
    );
};
