import { Avatar, createStyles, Group, Menu, Text, UnstyledButton } from '@mantine/core';
import {
    IconApi,
    IconAtom,
    IconChevronDown,
    IconHistory,
    IconLicense,
    IconLogout,
    IconSandbox,
    IconSettings,
    IconUsers,
} from '@tabler/icons-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

// eslint-disable-next-line no-restricted-imports
import { logout } from '@/features/auth/api/logout';
import { AuthUser } from '@/types/auth-user';

interface UserMenuProps {
    user: AuthUser | null;
}

const useStyles = createStyles((theme) => ({
    userMenu: {
        [theme.fn.smallerThan('xs')]: {
            display: 'none',
        },
    },

    user: {
        color: theme.white,
        padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
        borderRadius: theme.radius.sm,
        transition: 'background-color 100ms ease',

        '&:hover': {
            backgroundColor: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 8],
        },
    },

    userActive: {
        backgroundColor: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 8],
    },
}));

export const UserMenu: FunctionComponent<UserMenuProps> = ({ user }) => {
    const navigate = useNavigate();
    const { classes, theme } = useStyles();
    const { t } = useTranslation();

    const handleLogout = () => {
        logout()
            .then(() => {
                navigate('/auth/login');
            })
            .catch(() => {
                navigate('/auth/login');
            });
    };

    return (
        <Menu transitionProps={{ transition: 'pop-top-right' }} withinPortal={false}>
            <Menu.Target>
                <UnstyledButton className={classes.user}>
                    <Group spacing={7}>
                        <Avatar radius="xl" size={20}>
                            {user?.name?.charAt(0)}
                        </Avatar>
                        <Text
                            data-cy="user-menu-name"
                            mr={3}
                            size="sm"
                            sx={{ lineHeight: 1, color: theme.white }}
                            weight={500}
                        >
                            {user?.name}
                        </Text>
                        <IconChevronDown size={12} />
                    </Group>
                </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown style={{ zIndex: 10 }}>
                {(user?.role == 'ADMIN' || user?.grpAdmin) && (
                    <Menu.Label>{t('menu.administrator')}</Menu.Label>
                )}
                {/*<Menu.Item icon={<Users size={14} />}>Group administrator</Menu.Item>*/}
                {user?.role == 'ADMIN' && (
                    <Menu.Item component={Link} data-cy="admin-menu-admin-panel" icon={<IconAtom size={14} />} to="/admin">
                        {t('menu.adminPanel')}
                    </Menu.Item>
                )}

                {user?.grpAdmin && (
                    <Menu.Item component={Link} icon={<IconUsers size={14} />} to="/group-admin">
                        {t('menu.group')}
                    </Menu.Item>
                )}

                <Menu.Label>{t('menu.account')}</Menu.Label>

                <Menu.Item
                    component={Link}
                    data-cy="user-menu-account-settings"
                    icon={<IconSettings size={14} />}
                    to="/profile"
                >
                    {t('menu.settings')}
                </Menu.Item>

                <Menu.Item component={Link} icon={<IconApi size={14} />} to="/access-keys">
                    {t('menu.accessKeys')}
                </Menu.Item>

                <Menu.Item component={Link} data-cy="user-menu-license" icon={<IconLicense size={14} />} to="/subscription">
                    {t('menu.license')}
                </Menu.Item>

                <Menu.Item component={Link} icon={<IconSandbox size={14} />} to="/sandbox">
                    {t('menu.sandbox')}
                </Menu.Item>

                <Menu.Item component={Link} icon={<IconHistory size={14} />} to="/history">
                    {t('menu.history')}
                </Menu.Item>

                <Menu.Item icon={<IconLogout size={14} />} onClick={handleLogout}>
                    {t('menu.logout')}
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};
