import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { INSIGHTS } from '@/types/query-keys';

import { InsightsFormValues } from '../types/insight-form-values';
import { InsightDetailType } from '../types/insight-type';

const createInsights = async (data: InsightsFormValues): Promise<InsightDetailType> => {
    return (await axios.post('/admin/insights/', data)).data;
};

type UseCreateInsightsOptions = {
    config?: MutationConfig<typeof createInsights>;
};

export const useCreateInsights = ({ config }: UseCreateInsightsOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: createInsights,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [INSIGHTS] });
        },
    });
};
