import { useMutation } from '@tanstack/react-query';

import {
    showModelRestartFailureNotification,
    showModelRestartSuccessNotification,
} from '@/features/admin/api/notifications';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { NODES } from '@/types/query-keys';

const restartModelInstance = async (id: string): Promise<any> => {
    return (await axios.post(`/admin/compute-models-instances/${id}/restart`)).data;
};

type UseRestartComputeModelOptions = {
    config?: MutationConfig<typeof restartModelInstance>;
};

export const useRestartComputeModel = ({ config }: UseRestartComputeModelOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: restartModelInstance,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [NODES] });
            showModelRestartSuccessNotification();
        },
        onError: (error: any) => {
            showModelRestartFailureNotification();
        },
    });
};
