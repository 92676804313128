import { useModal } from '@ebay/nice-modal-react';
import { ActionIcon, Popover, Text } from '@mantine/core';
import { useDisclosure, useHover } from '@mantine/hooks';
import { IconStar, IconStarFilled } from '@tabler/icons-react';
import React, { FC } from 'react';

import { UserFeedbackModal } from '@/features/models/component/UserFeedbackModal';
import { UserFeedback } from '@/features/models/types/userJobType';

interface VoteProps {
    jobId: string;
    feedback: UserFeedback | undefined;
}

const size = 20;
export const UserVoteIcon: FC<VoteProps> = ({ jobId, feedback }) => {
    const feedbackModal = useModal(UserFeedbackModal);
    const { hovered: upHovered, ref: upRef } = useHover<HTMLButtonElement>();
    const [opened, { close, open }] = useDisclosure(false);
    const handleVoteForm = () => {
        feedbackModal.show({ feedback, jobId });
    };

    const rating = feedback?.rating || 0;

    return (
        <Popover data-cy="model-results-rating-modal" opened={opened} position="bottom" shadow="md" withArrow>
            <Popover.Target>
                <ActionIcon
                    color={rating > 0 ? 'forest' : 'gray'}
                    onClick={handleVoteForm}
                    onMouseEnter={open}
                    onMouseLeave={close}
                    ref={upRef}
                    size={size}
                    style={{ opacity: upHovered ? 1 : 0.7 }}
                    variant="transparent"
                >
                    {rating > 0 ? <IconStarFilled /> : <IconStar />}
                </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
                <Text size="sm">Rate the results</Text>
            </Popover.Dropdown>
        </Popover>
    );
};
