import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Alert, Modal, Stack } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import React from 'react';

import { useComputeModels } from '@/features/admin/api/compute-models';
import { useDeployModel } from '@/features/admin/api/deploy-model';
import {
    DeployModelForm,
    DeployModelFormValues,
} from '@/features/admin/components/nodes/DeployModelForm';
import { NodeType } from '@/features/admin/types/nodeType';
import { queryClient } from '@/lib/react-query';
import { NODES } from '@/types/query-keys';

interface DeployModelModalProps {
    node: NodeType;
}

export const DeployModelModal = NiceModal.create<DeployModelModalProps>(({ node }) => {
    const [isBusy, setIsBusy] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>();
    const modal = useModal();
    const deployModel = useDeployModel();
    const { data: models } = useComputeModels();

    const { hide, visible } = modal;

    const handleSubmit = (values: DeployModelFormValues) => {
        setIsBusy(true);
        deployModel.mutate(
            { nodeId: node.id, ...values },
            {
                onSuccess: () => {
                    modal.remove();
                    setIsBusy(false);
                    queryClient.invalidateQueries({ queryKey: [NODES] });
                },
                onError: (reason: AxiosError<any>) => {
                    setIsBusy(false);
                    setError(reason.response?.data?.message);
                },
            }
        );
    };

    const handleCancel = () => {
        modal.hide();
    };
    //
    // const availableModels = compute-models-registry?.filter((model) => {
    //     return !node.compute-models-registry.find((nodeModel) => nodeModel.model.name === model.value);
    // });

    // List compute models
    const availableModels =
        models?.map((model) => {
            return {
                label: model.displayName,
                value: model.id,
            };
        }) || [];

    return (
        <Modal
            centered
            closeOnClickOutside={false}
            closeOnEscape={false}
            onClose={() => hide()}
            opened={visible}
            title="Deploy model"
            withCloseButton={false}
            withinPortal={true}
        >
            <Stack>
                {error && (
                    <Alert color="red" icon={<IconAlertCircle size={16} />} title="Fatal Error">
                        {error}
                    </Alert>
                )}

                <DeployModelForm
                    availableModels={availableModels}
                    isBusy={isBusy}
                    onCancel={handleCancel}
                    onSubmit={handleSubmit}
                ></DeployModelForm>
            </Stack>
        </Modal>
    );
});
