import { createStyles, ScrollArea } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper } from '@/features/auth/component/Paper';
import { useQueryTerms } from '@/features/terms/api/terms';

type SupportedLangs = 'en' | 'de';
interface DisplayTermsProps {
    fullScreen?: boolean;
}

const useStyles = createStyles((theme, _params, _getRef) => ({
    terms: {
        'a:link': {
            color: theme.colors.jade[7],
            textDecoration: 'none',
            fontWeight: 600,
        },
        'a:visited': {
            color: theme.colors.jade[7],
            textDecoration: 'none',
            fontStyle: 'italic',
            fontWeight: 600,
        },

        pre: {
            borderRadius: 4,
            color: '#000',
            backgroundColor: 'rgba(248, 249, 250, 1)',
            padding: 10,
        },
    },
}));

export const DisplayTerms: FunctionComponent<DisplayTermsProps> = ({ fullScreen = false }) => {
    const { i18n, t } = useTranslation();
    const { classes } = useStyles();
    const { height } = useViewportSize();
    const queryTerms = useQueryTerms({ language: i18n.language as SupportedLangs });
    const heightChange = fullScreen ? 180 : 220;
    return (
        <Paper m={16} pr={0} py={0}>
            <ScrollArea
                className={classes.terms}
                style={{ height: height - heightChange, paddingRight: 24 }}
            >
                <h1>{t('common.terms')}</h1>
                <div dangerouslySetInnerHTML={{ __html: queryTerms.data?.text || '' }}></div>
            </ScrollArea>
        </Paper>
    );
};
