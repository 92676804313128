import { Button, Group, Input, NumberInput, SegmentedControl } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FunctionComponent, useEffect, useState } from 'react';

type LicenseFormState = 'IMAGES_LIMITED' | 'TIME_LIMITED' | 'UNLIMITED';

export interface LicenseFormValues {
    type: LicenseFormState;
    days: number;
    images: number;
}

interface LicenseFormProps {
    onSubmit: (values: LicenseFormValues) => void;
    onCancel: () => void;
}

export const LicenseForm: FunctionComponent<LicenseFormProps> = ({ onSubmit, onCancel }) => {
    const [type, setType] = useState<LicenseFormState>('IMAGES_LIMITED');
    const form = useForm<LicenseFormValues>({
        initialValues: {
            images: 20,
            days: 30,
            type: 'IMAGES_LIMITED',
        },
    });

    useEffect(() => {
        setType(form.values.type);
    }, [form.values.type]);

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Input.Wrapper label="License type" mb={10}>
                <SegmentedControl
                    data={[
                        { value: 'IMAGES_LIMITED', label: 'Images limited' },
                        { value: 'TIME_LIMITED', label: 'Time limited' },
                        { value: 'UNLIMITED', label: 'Unlimited' },
                    ]}
                    {...form.getInputProps('type')}
                    style={{ width: '100%' }}
                />
            </Input.Wrapper>

            {type === 'IMAGES_LIMITED' && (
                <NumberInput
                    label="Number of uploaded images"
                    required
                    {...form.getInputProps('images')}
                />
            )}

            {type === 'TIME_LIMITED' && (
                <NumberInput label="Number of days" required {...form.getInputProps('days')} />
            )}

            <Group mt="md" position="right">
                <Button onClick={() => onCancel()} variant="outline">
                    Cancel
                </Button>
                <Button type="submit">Create</Button>
            </Group>
        </form>
    );
};
