import { ActionIcon, Badge, Group } from '@mantine/core';
import { IconArrowDown, IconArrowUp, IconPencil } from '@tabler/icons-react';
import React, { FC, FunctionComponent } from 'react';

import type { AdminProductType } from '@/features/admin/components/stripe/types/license-product';

interface ProductRowProps {
    item: AdminProductType;
    onEdit: (item: AdminProductType) => void;
    onOrderChange: (item: AdminProductType, order: 'up' | 'down') => void;
}

const ActiveBadge: FunctionComponent<{ value: boolean }> = ({ value }) => {
    return value ? (
        <Badge color="teal" variant="filled">
            Active
        </Badge>
    ) : (
        <Badge color="red" variant="filled">
            Not active
        </Badge>
    );
};

export const ProductRow: FC<ProductRowProps> = ({ item, onEdit, onOrderChange }) => {
    return (
        <tr>
            <td>
                <Group spacing={0}>
                    <ActionIcon onClick={() => onOrderChange(item, 'up')} variant="transparent">
                        <IconArrowUp size={16} />
                    </ActionIcon>
                    <ActionIcon onClick={() => onOrderChange(item, 'down')} variant="transparent">
                        <IconArrowDown size={16} />
                    </ActionIcon>
                </Group>
            </td>
            <td>{item.titleEn}</td>
            <td>
                <ActiveBadge value={item.active} />
            </td>
            <td>
                <Group spacing={0}>
                    <ActionIcon onClick={() => onEdit(item)} radius="xl">
                        <IconPencil size={16}></IconPencil>
                    </ActionIcon>
                </Group>
            </td>
        </tr>
    );
};
