import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal, Stack } from '@mantine/core';
import { IconBug, IconClock, IconThumbUp } from '@tabler/icons-react';
import React, { ReactNode } from 'react';

import { useTestInstanceResult } from '@/features/admin/components/nodes/api/test-instance-result';

interface InstanceTestDialogProps {
    jobId: string;
}

const Wait = () => {
    return (
        <Stack align="center">
            <IconClock size={48} />
            <center>
                Wait for the test to finish. Depending on queue and model, may take a few minutes.
            </center>
        </Stack>
    );
};

const Error = () => {
    return (
        <Stack align="center" justify="center">
            <IconBug color="red" size={48} />
            <center>
                Model is probably broken.
                <br /> No results were generated.
            </center>
        </Stack>
    );
};

const Ok = () => {
    return (
        <Stack align="center">
            <IconThumbUp color="green" size={48} />
            <center>Model is working fine (probably).</center>
        </Stack>
    );
};

export const InstanceTestDialog = NiceModal.create<InstanceTestDialogProps>((props) => {
    const modal = useModal();
    const { data: result } = useTestInstanceResult({ jobId: props.jobId });
    const { remove, visible } = modal;

    let component: ReactNode;

    switch (result) {
        case undefined:
            component = <Wait />;
            break;
        case 'failed':
            component = <Error />;
            break;
        case 'completed':
            component = <Ok />;
            break;
        case 'active':
        case 'wait':
            component = <Wait />;
            break;
        default:
            component = <Wait />;
    }

    return (
        <Modal centered onClose={() => remove()} opened={visible}>
            {component}
        </Modal>
    );
});
