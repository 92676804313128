import { Burger, createStyles, Paper, Transition } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useCreateNavigationLinks } from '@/components/Links/useCreateNavigationLinks';
import { useModels } from '@/features/models/api/models';

const useStyles = createStyles((theme) => ({
    burger: {
        [theme.fn.largerThan('xs')]: {
            display: 'none',
        },
    },

    dropdown: {
        position: 'absolute',
        top: 65,
        left: 0,
        right: 0,
        zIndex: 0,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopWidth: 0,
        overflow: 'hidden',

        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: '8px 12px',
        borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,

        '&:hover': {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },

        [theme.fn.smallerThan('sm')]: {
            borderRadius: 0,
            padding: theme.spacing.md,
        },
    },

    linkActive: {
        '&, &:hover': {
            backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
                .background,
            color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
        },
    },
}));

export const MobileMenu: FunctionComponent = () => {
    const { classes, theme, cx } = useStyles();
    const { t } = useTranslation();
    const [opened, { toggle, close }] = useDisclosure(false);
    const { data: models } = useModels();
    const links = useCreateNavigationLinks(models ?? [], false, t);
    const [active, setActive] = useState('');

    const navigate = useNavigate();

    const items = links.map((link) => (
        <Link
            className={cx(classes.link, { [classes.linkActive]: active === link.path })}
            key={link.label}
            onClick={(event) => {
                event.preventDefault();
                setActive(link.path);
                close();
                navigate(link.path);
            }}
            to={link.path}
        >
            {link.label}
        </Link>
    ));

    return (
        <>
            <Burger
                className={classes.burger}
                color={theme.white}
                onClick={toggle}
                opened={opened}
                size="sm"
            />
            <Transition duration={200} mounted={opened} transition="pop-top-right">
                {(styles) => (
                    <Paper className={classes.dropdown} style={styles} withBorder>
                        {items}
                    </Paper>
                )}
            </Transition>
        </>
    );
};
