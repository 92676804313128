import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { ADMIN_MODELS_NEWS } from '@/types/query-keys';

const deleteModelNews = async (id: number): Promise<any> => {
    return (await axios.delete(`/admin/compute-models-news/${id}`)).data;
};

type UseDeleteModelNewsOptions = {
    config?: MutationConfig<typeof deleteModelNews>;
};

export const useDeleteModelNews = ({ config }: UseDeleteModelNewsOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: deleteModelNews,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [ADMIN_MODELS_NEWS] });
        },
    });
};
