import React, { FC } from 'react';

import { ImagesContextProvider } from '@/features/admin/components/images/ImagesContext';
import { ImagesFilterForm } from '@/features/admin/components/images/ImagesFilterForm';
import { ImagesTable } from '@/features/admin/components/images/ImagesTable';

export const ImagesPanel: FC = () => {
    return (
        <>
            <ImagesContextProvider>
                <ImagesFilterForm />
                <ImagesTable />
            </ImagesContextProvider>
        </>
    );
};
