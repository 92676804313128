import { Button, Group, Input, Stack, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { TooltipRating } from '@/components/TooltipRating';
import { UserFeedback } from '@/features/models/types/userJobType';

export interface UserFeedbackFormValues {
    comment?: string;
    rating: number;
}

interface UserFeedbackFormProps {
    onSubmit: (values: UserFeedbackFormValues) => void;
    onCancel: () => void;
    initFeedback?: UserFeedback;
}

export const UserFeedbackForm: FunctionComponent<UserFeedbackFormProps> = ({
    onSubmit,
    onCancel,
    initFeedback,
}) => {
    const { t } = useTranslation();

    const form = useForm<UserFeedbackFormValues>({
        initialValues: {
            rating: initFeedback?.rating || 0,
            comment: initFeedback?.comment || '',
        },
        validate: {
            rating: (value) => (value > 0 ? null : 'model.rating.ratingError'),
        },
    });

    const commentProps = form.getInputProps('comment');

    const handleScaleNumberChange = (value: number) => {
        return t(`model.rating.${value}`);
    };

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack spacing="sm">
                <Input.Wrapper
                    data-cy="model-results-rating-stars"
                    label={t('model.rating.rateResults')}
                    {...form.getInputProps('rating')}
                    required
                >
                    <TooltipRating
                        {...form.getInputProps('rating')}
                        onTooltipChange={handleScaleNumberChange}
                    ></TooltipRating>
                </Input.Wrapper>

                <Textarea
                    {...commentProps}
                    data-cy="model-results-rating-comments"
                    label={t('model.rating.additionalComments')}
                    placeholder=""
                />

                <Group mt="md" position="right">
                    <Button onClick={onCancel} variant="outline">
                        {t('common.cancel')}
                    </Button>
                    <Button data-cy="model-results-rating-submit-button" type="submit">{t('common.submit')}</Button>
                </Group>
            </Stack>
        </form>
    );
};
