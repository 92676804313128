import { useMutation } from '@tanstack/react-query';

import { AdminFeedbackFormValues } from '@/features/admin/components/jobs/AdminFeedbackForm';
import { showVoteNotification } from '@/features/models/api/notifications';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { JOB } from '@/types/query-keys';

export type UseAdminFeedbackDto = AdminFeedbackFormValues;

type AdminFeedbackType = (data: UseAdminFeedbackDto) => Promise<any>;

const adminFeedbackWrapper = (jobId: string) => {
    return async (data: UseAdminFeedbackDto): Promise<any> => {
        return (await axios.post(`/admin/jobs/${jobId}/feedback`, data)).data;
    };
};

type UseAdminFeedbackOptions = {
    config?: MutationConfig<AdminFeedbackType>;
    jobId: string;
};

export const useAdminFeedback = ({ config, jobId }: UseAdminFeedbackOptions) => {
    return useMutation({
        ...config,
        mutationFn: adminFeedbackWrapper(jobId),
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([JOB, jobId]);
            showVoteNotification();
        },
    });
};
