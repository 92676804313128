import React, { FunctionComponent } from 'react';

import { Layout } from '@/features/auth/component/Layout';
import { Paper } from '@/features/auth/component/Paper';

export const FullScreenImprint: FunctionComponent = () => {
    return (
        <Layout title="Imprint">
            <Paper minWidth={600} px={32} py={0}>
                <div>
                    <h3>
                        <span>Imprint</span>
                    </h3>
                    <p>Information according to § 5 TMG</p>
                    <p>
                        MiViA GmbH
                        <br /> Halsbrücker Str. 34
                        <br /> 09599 Freiberg
                    </p>
                    <p>
                        Commercial register: HRB 35236
                        <br /> Register court: Amtsgericht Chemnitz
                    </p>
                    <p>
                        Represented by:
                        <br /> Dr. Grzegorz Pawel Korpala
                        <br /> Miriam Corcoran
                    </p>
                    <p>
                        Contact
                        <br /> Phone: +49 (0) 3731 39 4187
                        <br /> E-mail: info@mivia.ai
                    </p>
                    <p>
                        Umsatzsteuer- ID
                        <br /> Sales tax identification number (USt-ID) according to § 27 a
                        Umsatzsteuergesetz:
                        <br /> DE355371904
                    </p>
                    <p>
                        EU Dispute Resolution
                        <br /> The European Commission provides a platform for online dispute
                        resolution (OS): &nbsp;
                        <a
                            href="https://ec.europa.eu/consumers/odr/"
                            rel="noreferrer"
                            target="_blank"
                        >
                            ec.europa.eu/consumers/odr
                        </a>
                        .<br /> You can find our e-mail address in the imprint above.
                    </p>
                    <p>
                        Consumer dispute resolution/universal dispute resolution body
                        <br /> We are not willing or obliged to participate in dispute resolution
                        proceedings before a<br /> consumer arbitration board
                    </p>
                </div>
            </Paper>
        </Layout>
    );
};
