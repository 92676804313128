import { useMutation } from '@tanstack/react-query';

import { DockerImage } from '@/features/admin/types/docker-image';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { COMPUTE_MODELS_STATE, DOCKER_IMAGES } from '@/types/query-keys';

export interface UseMutationUpdateAllInstancesDto {
    computeModelId: string;
    version: string;
}

const updateAllInstances = async (data: UseMutationUpdateAllInstancesDto): Promise<DockerImage> => {
    return (await axios.put(`/admin/compute-models-instances`, data)).data;
};

type UseUpdateAllInstancesOptions = {
    config?: MutationConfig<typeof updateAllInstances>;
    imageName: string;
};

export const useUpdateAllInstances = ({ config, imageName }: UseUpdateAllInstancesOptions) => {
    return useMutation({
        ...config,
        mutationFn: updateAllInstances,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([DOCKER_IMAGES, imageName]);
            queryClient.setQueryData<boolean>([COMPUTE_MODELS_STATE], (old) => false);
        },
        onMutate: async (data: UseMutationUpdateAllInstancesDto) => {
            // asume by default that the model is in update state
            queryClient.setQueryData<boolean>([COMPUTE_MODELS_STATE], (old) => true);
        },
    });
};
