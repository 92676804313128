import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useAdminFeedback } from '@/features/admin/api/job-feedback';
import {
    AdminFeedbackForm,
    AdminFeedbackFormValues,
} from '@/features/admin/components/jobs/AdminFeedbackForm';

interface AdminFeedbackModalProps {
    jobId: string;
}

export const AdminFeedbackModal = NiceModal.create<AdminFeedbackModalProps>(({ jobId }) => {
    const { t } = useTranslation();
    const modal = useModal();
    const adminFeedback = useAdminFeedback({ jobId });

    const { remove, visible } = modal;

    const handleSubmit = (values: AdminFeedbackFormValues) => {
        adminFeedback.mutate(values);
        remove();
    };

    return (
        <Modal
            centered
            onClose={() => remove()}
            opened={visible}
            size={500}
            title={t('model.rating.title')}
        >
            <AdminFeedbackForm onCancel={() => remove()} onSubmit={handleSubmit} />
        </Modal>
    );
});
