import { ActionIcon, Menu } from '@mantine/core';
import {
    IconEdit,
    IconLicense,
    IconMailOpened,
    IconPhotoX,
    IconUserMinus,
    IconUserPentagon,
} from '@tabler/icons-react';
import { FC } from 'react';

import { UserAccount } from '../../types/user-account';

interface UserAdminButtonMenuProps {
    user: UserAccount;
    onDeleteClick: (user: UserAccount) => void;
    onAssignLicenseClick: (userId: string) => void;
    onActivateClick: (userId: string) => void;
    onAllowPermanentDataDeletion: (userId: string, value: boolean) => void;
    onImpersonateClick: (userId: string) => void;
}

export const UserAdminButtonMenu: FC<UserAdminButtonMenuProps> = ({
    user,
    onDeleteClick,
    onAssignLicenseClick,
    onActivateClick,
    onAllowPermanentDataDeletion,
    onImpersonateClick,
}) => {
    return (
        <Menu transitionProps={{ transition: 'pop-top-right' }} width={200}>
            <Menu.Target>
                <ActionIcon>
                    <IconEdit color="black" size={22} strokeWidth={1} />
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                {/*<Menu.Item icon={<UserCircle size={16} />} onClick={() => onAssignRoleClick(user)}>*/}
                {/*    Assign role*/}
                {/*</Menu.Item>*/}
                <Menu.Item
                    icon={<IconLicense size={16} />}
                    onClick={() => onAssignLicenseClick(user.id)}
                >
                    Assign license
                </Menu.Item>
                <Menu.Item
                    icon={<IconMailOpened size={16} />}
                    onClick={() => onActivateClick(user.id)}
                >
                    Activate account
                </Menu.Item>
                <Menu.Item icon={<IconUserMinus size={16} />} onClick={() => onDeleteClick(user)}>
                    Delete user
                </Menu.Item>

                <Menu.Item
                    icon={<IconUserPentagon size={16} />}
                    onClick={() => onImpersonateClick(user.id)}
                >
                    Impersonate
                </Menu.Item>

                <Menu.Item
                    data-cy="admin-panel-users-allow-permanent-deletion"
                    icon={<IconPhotoX size={16} />}
                    onClick={() =>
                        onAllowPermanentDataDeletion(
                            user.id,
                            !user.preferences.removeDataPermanently
                        )
                    }
                >
                    Allow permanent data deletion
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};
