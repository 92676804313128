import { ResultType } from 'mivia-shared';

import { ModelInstance } from '@/features/admin/types/user-jobs';
import { JobError } from '@/features/models/types/job-error';

export enum JobStatus {
    CACHED = 'CACHED',
    NEW = 'NEW',
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS',
    PENDING = 'PENDING',
}

export interface Feedback {
    name: string;
    value: any;
    score: number;
}

export interface UserFeedback {
    comment?: string;
    rating: number;
}

export interface UserJobType {
    id: string;
    imageId: string;
    resultId: string | null;
    finishedAt: string | null;
    startedAt: string | null;
    createdAt: string;
    status: JobStatus;
    error?: JobError;
    image: Image;
    result?: ResultClass;
    modelId: string;
    archived: boolean;
    outdated: boolean;
    model: Model;
    userFeedback?: UserFeedback;
    modelInstance: ModelInstance;
}

export interface Image {
    id: string;
    orginalFilename: string;
    filename: string;
    size: number;
    ownerId: string;
    hash: string;
    thumbnail: string;
    createdAt: Date;
    meta?: {
        width: number;
        height: number;
        format: string;
    };
}

export interface ResultClass {
    id: string;
    sourceImageHash: string;
    modelId: string;
    results: ResultType[];
    feedback: Feedback[];
    createdAt: Date;
    resultFilename?: string;
}

export interface Model {
    id: string;
    displayName: string;
    version: string;
    dockerImage: string;
}
