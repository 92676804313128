import { showNotification } from '@mantine/notifications';

import i18n from '@/i18n/config';

export const showProfileUpdatedNotification = () => {
    showNotification({
        id: 'profile-updated',
        withCloseButton: true,
        autoClose: 5000,
        title: 'Profile updated',
        message: 'Your profile has been updated.',
        color: 'primary',
    });
};

export const showProfileFailUpdateNotification = (error?: string) => {
    showNotification({
        id: 'profile-updated-failed',
        withCloseButton: true,
        autoClose: 5000,
        title: 'Profile not updated',
        message: 'Error occurred. ' + error,
        color: 'red',
    });
};

export const showOtpEnabledNotification = () => {
    showNotification({
        id: 'otp-enabled',
        withCloseButton: true,
        autoClose: 5000,
        title: i18n.t('common.success') as string,
        message: i18n.t('otp.otpNotificationEnabledMsg') as string,
        color: 'primary',
    });
};
