import { Container, createStyles } from '@mantine/core';
import * as React from 'react';

import { AdminNavbar } from '@/components/Layout/AdminNavbar';
import { AppBar } from '@/components/Layout/AppBar';
import { GlobalStyles } from '@/components/Layout/GlobalStyles';

import { Head } from '../Head';

type MainLayoutProps = {
    children: React.ReactNode;
    title: string;
};

const useStyles = createStyles((theme, _params, getRef) => ({
    parent: {
        minHeight: '100vh',
        display: 'grid',
        gridTemplateColumns: '300px 1fr',
    },
    container: {
        backgroundColor: theme.white,

        minHeight: 'calc(100% - 65px)',
    },
}));

export const AdminLayout = ({ children, title }: MainLayoutProps) => {
    const { classes } = useStyles();

    return (
        <>
            <GlobalStyles />
            <Head title={title} />
            <div className={classes.parent}>
                <AdminNavbar />

                <div>
                    <header style={{ zIndex: 1 }}>
                        <AppBar showLogo={false} />
                    </header>
                    <Container className={classes.container} fluid={true}>
                        {children}
                    </Container>
                </div>

                {/*<header style={{ zIndex: 1 }}>*/}
                {/*    <AppBar user={{ name: 'Krzysztof', image: '' }} showLogo={false} />*/}
                {/*</header>*/}
                {/*<main>*/}
                {/*    <AdminNavbar></AdminNavbar>*/}
                {/*    /!*<MainContainer size="xl">{children}</MainContainer>*!/*/}
                {/*</main>*/}
                {/*<footer>*/}
                {/*    <Footer />*/}
                {/*</footer>*/}
            </div>
        </>
    );
};
