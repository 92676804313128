import createConfig from '@spydersoft/react-runtime-config';

const config = createConfig({
    namespace: 'MIVIA_APP_CONFIG',
    schema: {
        apiUrl: {
            type: 'string',
            description: 'Backend url',
        },
        appUrl: {
            type: 'string',
            description: 'Frontend url',
        },
        jobFetchInterval: {
            type: 'number',
            description: 'Job fetch interval',
            default: 3000,
        },
        deploymentEnv: {
            type: 'string',
            description: 'Deployment environment',
            default: 'development',
            enum: ['development', 'testing', 'staging', 'production'],
        },
    },
});

export const { useConfig, getConfig } = config;

export const isDevelopment = () => config.getConfig('deploymentEnv') === 'development';
export const isProduction = () => config.getConfig('deploymentEnv') === 'production';
export const isStaging = () => config.getConfig('deploymentEnv') === 'staging';
export const appUrl = config.getConfig('appUrl');

export const MIN_IMAGE_WIDTH = 1024;
export const MIN_IMAGE_HEIGHT = 1024;
export const MAX_IMAGE_SIZE_MB = 30;

export const REACT_APP_API_BASE_URL = config.getConfig('apiUrl');
export const REACT_APP_FRONTEND_URL = config.getConfig('appUrl');
export const JOB_FETCH_INTERVAL = config.getConfig('jobFetchInterval');
export const getImageThumbnailUrl = (id: string) =>
    `${REACT_APP_API_BASE_URL}/image/${id}/thumbnail`;

export const getImageUrl = (id: string) => `${REACT_APP_API_BASE_URL}/image/${id}`;
export const getMaskUrl = (filename: string) => `${REACT_APP_API_BASE_URL}/masks/${filename}`;

export const getResultUrl = (id: string | undefined) =>
    id ? `${REACT_APP_API_BASE_URL}/jobs/results/${id}` : undefined;

export const getResultUrlWithFilename = (id: string | undefined, filename: string) =>
    id ? `${REACT_APP_API_BASE_URL}/jobs/results/${id}/${filename}` : undefined;
