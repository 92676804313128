import { useQuery } from '@tanstack/react-query';

import { ModelAccess } from '@/features/admin/components/compute-models/types/model-access';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { COMPUTE_MODELS_ACCESS } from '@/types/query-keys';

export const getModelAccess = async (modelId: string): Promise<ModelAccess[]> => {
    return (await axios.get(`/admin/compute-models/${modelId}/access`)).data;
};

type QueryFnType = typeof getModelAccess;

type UseModelAccessOptions = {
    modelId: string;
    config?: QueryConfig<QueryFnType>;
};

export const useModelAccess = ({ config, modelId }: UseModelAccessOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [COMPUTE_MODELS_ACCESS, modelId],
        queryFn: () => getModelAccess(modelId),
        ...config,
    });
};
