import { useQuery } from '@tanstack/react-query';

import { Performance } from '@/features/admin/types/performance';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { NODE_PERFORMANCE } from '@/types/query-keys';

export const queryPerformance = async (nodeId: string): Promise<Performance> => {
    const { data } = await axios.get(`/admin/stats/performance/${nodeId}`);
    return data;
};

type QueryFnType = typeof queryPerformance;

type UsePerformanceOptions = {
    nodeId: string;
    config?: QueryConfig<QueryFnType>;
};

export const useQueryPerformance = ({ config, nodeId }: UsePerformanceOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [NODE_PERFORMANCE, nodeId],
        queryFn: () => queryPerformance(nodeId),
        ...config,
    });
};
