import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { PROFILE } from '@/types/query-keys';

import {
    showProfileFailUpdateNotification,
    showProfileUpdatedNotification,
} from '../api/notifications';
import { Address, UserProfile } from '../types/user-profile';

export const updateProfile = async (data: Address): Promise<UserProfile> => {
    return (await axios.patch('/users/me', { address: data })).data;
};

type UseUpdateProfileOptions = {
    config?: MutationConfig<typeof updateProfile>;
};

export const useUpdateProfile = ({ config }: UseUpdateProfileOptions = {}) => {
    return useMutation({
        ...config,
        onSuccess: (data: UserProfile, ...rest) => {
            queryClient.setQueryData<UserProfile>([PROFILE, 'me'], (prevData) => data);
            config?.onSuccess?.(data, ...rest);
            showProfileUpdatedNotification();
        },
        onError: (error, ...rest) => {
            const dErr = error.response?.data as any;
            const errMsg = dErr?.message;
            showProfileFailUpdateNotification(errMsg);
        },
        mutationFn: updateProfile,
    });
};
