import { List, Popover, ThemeIcon, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCheck, IconPennant, IconX } from '@tabler/icons-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Feedback } from '@/features/models/types/userJobType';

interface FeedbackItemProps {
    info: Feedback;
}

interface FeedbackInfoProps {
    feedback: Feedback[];
}

const scoreToColor = (score: number) => {
    if (score == 0) return 'black';
    if (score > 0 && score <= 0.2) return 'red';
    if (score > 0.2 && score <= 0.8) return 'orange';
    if (score > 0.8) return 'green';
};

const FeedbackItem: FunctionComponent<FeedbackItemProps> = ({ info }) => {
    const { t } = useTranslation();
    /* i18next-extract-disable-next-line */
    const feedbackText = t(`feedback.${info.name}`);
    const color = info.value ? 'green' : 'red';
    const icon = info.value ? <IconCheck size={10} /> : <IconX size={10} />;
    return (
        <List.Item
            icon={
                <ThemeIcon
                    color={color}
                    radius="xl"
                    size={18}
                    style={{ cursor: 'pointer' }}
                    variant={'outline'}
                >
                    {icon}
                </ThemeIcon>
            }
        >
            {feedbackText}
        </List.Item>
    );
};

export const ModelFeedbackIcon: FunctionComponent<FeedbackInfoProps> = ({ feedback }) => {
    const [opened, { close, open }] = useDisclosure(false);
    const avgScore = feedback.reduce((a, b) => a + b.score, 0) / feedback.length;
    const color = scoreToColor(avgScore);

    return (
        <Popover opened={opened} position="bottom" shadow="md" withArrow>
            <Popover.Target>
                <UnstyledButton pt={2}>
                    <IconPennant
                        color={color}
                        onMouseEnter={open}
                        onMouseLeave={close}
                        size={18}
                    ></IconPennant>
                </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
                <List size="sm">
                    {feedback.map((f) => (
                        <FeedbackItem info={f} key={f.name} />
                    ))}
                </List>
            </Popover.Dropdown>
        </Popover>
    );
};
