import { useMutation } from '@tanstack/react-query';

import { showAccessGranted } from '@/features/admin/api/notifications';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { COMPUTE_MODELS_ACCESS } from '@/types/query-keys';

const updateModelAccessWrapper = (modelId: string) => {
    return async (userId: string): Promise<any> => {
        return (await axios.post(`/admin/compute-models/${modelId}/access`, { userId })).data;
    };
};

type UseUpdateModelAccessOptions = {
    modelId: string;
    config?: MutationConfig<ReturnType<typeof updateModelAccessWrapper>>;
};

export const useUpdateModelAccess = ({ config, modelId }: UseUpdateModelAccessOptions) => {
    return useMutation({
        ...config,
        mutationFn: updateModelAccessWrapper(modelId),
        onSuccess: async (data: any) => {
            await queryClient.invalidateQueries([COMPUTE_MODELS_ACCESS, modelId]);
            showAccessGranted();
        },
    });
};
