import React from 'react';

import { ComputeModels } from '@/features/admin/components/compute-models/ComputeModels';

import { useAdminStyles } from '../components/admin-styles';

export const AdminComputeModels = () => {
    const { classes } = useAdminStyles();
    return (
        <div className={classes.container}>
            <ComputeModels />
        </div>
    );
};
