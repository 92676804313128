import { useMutation } from '@tanstack/react-query';

import {
    showFailureNotification,
    showSuccessNotification,
} from '@/features/admin/api/notifications';
import { DemoConfigType } from '@/features/admin/components/settings/api/demo-config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { ADMIN_DEMO_CONFIG } from '@/types/query-keys';

export type UseMutationUpdateDemoConfigDto = DemoConfigType;

const updateDemoConfig = async (data: UseMutationUpdateDemoConfigDto): Promise<any> => {
    return (await axios.put('/admin/licenses/demo', data)).data;
};

type UseUpdateDemoConfigOptions = {
    config?: MutationConfig<typeof updateDemoConfig>;
};

export const useUpdateDemoConfig = ({ config }: UseUpdateDemoConfigOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: updateDemoConfig,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([ADMIN_DEMO_CONFIG]);
            showSuccessNotification('Demo license has been updated successfully.');
        },
        onError: (error: any) => {
            showFailureNotification('Failed to update demo license.');
        },
    });
};
