import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { USERS } from '@/types/query-keys';

export interface UseMutationChangeUserPrefsDto {
    removeDataPermanently?: boolean;
    userId: string;
}

const changeUserPrefs = async (data: UseMutationChangeUserPrefsDto): Promise<any> => {
    return (
        await axios.patch(`/admin/users/${data.userId}/preferences`, {
            removeDataPermanently: data.removeDataPermanently,
        })
    ).data;
};

type UseChangeUserPrefsOptions = {
    config?: MutationConfig<typeof changeUserPrefs>;
};

export const useChangeUserPrefs = ({ config }: UseChangeUserPrefsOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: changeUserPrefs,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [USERS] });
        },
    });
};
