import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { ACCESS_KEYS } from '@/types/query-keys';

export interface UseMutationRemoveAccessKeyDto {
    id: number;
}

const removeAccessKey = async (data: UseMutationRemoveAccessKeyDto): Promise<any> => {
    return (await axios.delete(`access-keys/${data.id}`)).data;
};

type UseRemoveAccessKeyOptions = {
    config?: MutationConfig<typeof removeAccessKey>;
};

export const useRemoveAccessKey = ({ config }: UseRemoveAccessKeyOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: removeAccessKey,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [ACCESS_KEYS] });
        },
    });
};
