import { ActionIcon, TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconSearch, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';

import { useQueryUsers } from '@/features/admin/api/list-users';
import { UserRowData, UsersTable } from '@/features/admin/components/users/UsersTable';

const DEFAULT_SORT: keyof UserRowData = 'timeJoined';

export const Users = () => {
    const [search, setSearch] = useState<string | undefined>();
    const [debouncedSearch] = useDebouncedValue<string | undefined>(search, 500);
    const [sortBy, setSortBy] = useState<keyof UserRowData>(DEFAULT_SORT);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const usersQuery = useQueryUsers({
        search: debouncedSearch,
        sortBy,
        sortOrder: reverseSortDirection ? 'desc' : 'asc',
    });

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setSearch(value);
    };

    function handleSorting(sortBy: keyof UserRowData, reversed: boolean) {
        setSortBy(sortBy);
        setReverseSortDirection(reversed);
    }

    return (
        <>
            <TextInput
                icon={<IconSearch size={14} stroke={1.5} />}
                mb="md"
                onChange={handleSearchChange}
                placeholder="Search by any field"
                rightSection={
                    <ActionIcon onClick={() => setSearch('')}>
                        <IconX size={12} style={{ display: 'block', opacity: 0.5 }} />
                    </ActionIcon>
                }
            />
            <UsersTable
                data={usersQuery.data}
                onSort={handleSorting}
                reverseSortDirection={reverseSortDirection}
                sortBy={sortBy}
            />
        </>
    );
};
