import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { INSIGHTS } from '@/types/query-keys';

const deleteInsight = async (id: number): Promise<any> => {
    return (await axios.delete(`/admin/insights/${id}`)).data;
};

type UseDeleteInsightsOptions = {
    config?: MutationConfig<typeof deleteInsight>;
};

export const useDeleteInsights = ({ config }: UseDeleteInsightsOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: deleteInsight,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [INSIGHTS] });
        },
    });
};
