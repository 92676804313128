import { ActionIcon, Button, Flex, Menu, Select, Switch, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useShallowEffect } from '@mantine/hooks';
import {
    IconCaretDown,
    IconEraser,
    IconEraserOff,
    IconFilterSearch,
    IconStar,
    IconStarOff,
    IconStarsFilled,
    IconStarsOff,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import React, { FC } from 'react';

import { MaskedDateInput } from '@/components/MaskedDateInput';
import { useJobsContext } from '@/features/admin/components/jobs/JobsContext';
import { JobsFilterFormType } from '@/features/admin/components/jobs/JobsFilterFormType';
import { UsernameSelect } from '@/features/admin/components/jobs/UsernameTextInput';
import { useModels } from '@/features/models/api/models';

export const JobsFilter: FC = () => {
    const { setFilter, isFetching } = useJobsContext();

    const { data: models } = useModels();
    const form = useForm<JobsFilterFormType>({
        initialValues: {
            // dateRange: [dayjs().subtract(1, 'month').toDate(), dayjs().toDate()],
            startDate: '',
            endDate: dayjs().format('YYYY-MM-DD'),
            modelId: '',
            userId: '',
            deleted: false,
            company: '',
            withUserFeedback: false,
            withAdminFeedback: false,
        },
    });

    useShallowEffect(() => {
        setFilter(form.values);
    }, []);

    const handleSubmit = (values: JobsFilterFormType) => {
        setFilter(values);
    };

    const setStartDateMinus = (days: number) => {
        // If endDate is not set or invalid, set it to today
        const endDateValueDate = dayjs(form.values.endDate);
        const endDate = endDateValueDate.isValid() ? endDateValueDate : dayjs();
        form.setFieldValue('endDate', endDate.format('YYYY-MM-DD'));

        // Set startDate to endDate - days
        const startDate = endDate.subtract(days, 'day').format('YYYY-MM-DD');
        form.setFieldValue('startDate', startDate);
    };

    const selectableModels = models?.map((model) => ({ value: model.id, label: model.name })) ?? [];

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Flex align="center" direction="row" gap="xs" justify="flex-end" wrap="wrap">
                <UsernameSelect {...form.getInputProps('userId')} placeholder="Filter by user" />
                <TextInput placeholder="Filter by company" {...form.getInputProps('company')} />
                <Select
                    data={selectableModels}
                    {...form.getInputProps('modelId')}
                    clearable
                    placeholder="Filter by model"
                ></Select>

                <MaskedDateInput
                    placeholder="Start date"
                    rightSection={
                        <Menu>
                            <Menu.Target>
                                <ActionIcon variant="transparent">
                                    <IconCaretDown size="1.05rem"></IconCaretDown>
                                </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Item onClick={() => setStartDateMinus(1)}>
                                    Since yesterday
                                </Menu.Item>
                                <Menu.Item onClick={() => setStartDateMinus(7)}>
                                    Last 7 days
                                </Menu.Item>
                                <Menu.Item onClick={() => setStartDateMinus(30)}>
                                    Last 30 days
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    }
                    style={{ width: 135 }}
                    {...form.getInputProps('startDate')}
                />
                <MaskedDateInput
                    placeholder="End date"
                    style={{ width: 110 }}
                    {...form.getInputProps('endDate')}
                />

                <Switch
                    {...form.getInputProps('deleted')}
                    offLabel={<IconEraserOff />}
                    onLabel={<IconEraser />}
                    size={'xl'}
                    styles={{ root: { display: 'inline-flex' } }}
                />

                <Switch
                    {...form.getInputProps('withUserFeedback')}
                    offLabel={<IconStarOff />}
                    onLabel={<IconStar />}
                    size={'xl'}
                    styles={{ root: { display: 'inline-flex' } }}
                />

                <Switch
                    {...form.getInputProps('withAdminFeedback')}
                    offLabel={<IconStarsOff />}
                    onLabel={<IconStarsFilled />}
                    size={'xl'}
                    styles={{ root: { display: 'inline-flex' } }}
                />

                <Button
                    leftIcon={<IconFilterSearch />}
                    loading={isFetching}
                    styles={{
                        root: { width: 100 },
                    }}
                    type="submit"
                >
                    Filter
                </Button>
            </Flex>
        </form>
    );
};
