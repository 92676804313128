import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ADMIN_SERVICE_STATUS } from '@/types/query-keys';

export const getServiceStatus = async (service: string): Promise<boolean> => {
    return (await axios.get(`/admin/services/ping/${service}`)).data;
};

type QueryFnType = typeof getServiceStatus;

type UseServiceStatusOptions = {
    config?: QueryConfig<QueryFnType>;
    service: string;
};

export const useServiceStatus = ({ config, service }: UseServiceStatusOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [ADMIN_SERVICE_STATUS, service],
        queryFn: () => getServiceStatus(service),
        ...config,
    });
};
