import { SimpleGrid, Table } from '@mantine/core';
import { getName as getCountryName } from 'country-list';
import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';

import { Address, UserAccount } from '../../types/user-account';
import { UserStats } from '../../types/user-stats';

interface UserProfileProps {
    profile: UserAccount;
    stats: UserStats;
}

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const renderAddress = (address: Address | undefined) => {
    if (!address) return null;

    let res = '';

    if (address.company) res += address.company;
    if (address.address) res += '\n' + address.address;
    if (address.postalCode) res += '\n' + address.postalCode + ' ';
    if (address.city) res += address.city;
    if (address.country) res += '\n' + (getCountryName(address?.country || '') || '');
    if (address.taxNumber) res += '\n' + 'VAT number: ' + address.taxNumber;
    if (address.phone) res += '\n' + 'Phone: ' + address.phone;

    return res;
};

export const UserProfile: FunctionComponent<UserProfileProps> = ({ profile, stats }) => {
    const joined = dayjs(profile.timeJoined).format(dateFormat);
    const lastActivity = profile.lastLogin ? dayjs(profile.lastLogin).format(dateFormat) : '-';

    return (
        <Table striped>
            <tbody>
                <tr>
                    <td>Name</td>
                    <td>
                        {profile.name} {profile.surname}
                    </td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>
                        <a href={`mailto:${profile.email}`}>{profile.email}</a>
                    </td>
                </tr>
                <tr>
                    <td>Joined</td>
                    <td>{joined}</td>
                </tr>
                <tr>
                    <td>Last activity</td>
                    <td>{lastActivity}</td>
                </tr>
                <tr>
                    <td>Verified</td>
                    <td>{profile.verified ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                    <td>Role</td>
                    <td>{profile.role}</td>
                </tr>
                <tr>
                    <td>Training consent</td>
                    <td>{profile.preferences.trainingConsent ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                    <td>Address</td>
                    <td style={{ whiteSpace: 'pre-line' }}>{renderAddress(profile.address)}</td>
                </tr>
                <tr>
                    <td>Jobs</td>
                    <td>
                        <SimpleGrid
                            cols={2}
                            spacing={2}
                            sx={(theme) => ({
                                width: '20%',
                            })}
                        >
                            <div>New:</div>
                            <div>{stats.jobs.NEW}</div>
                            <div>Cached:</div>
                            <div>{stats.jobs.CACHED}</div>
                            <div>Failed:</div>
                            <div>{stats.jobs.FAILED}</div>
                        </SimpleGrid>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};
