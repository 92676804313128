import { ActionIcon } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';

import { Voucher } from '@/features/admin/types/voucher';

interface VoucherRowProps {
    voucher: Voucher;
    onDelete: (id: string) => void;
}

export const VoucherRow: FunctionComponent<VoucherRowProps> = ({ voucher, onDelete }) => {
    const createdAt = dayjs(voucher.createdAt).format('YYYY-MM-DD HH:mm:ss');
    const validUntil = voucher.validUntil ? dayjs(voucher.validUntil).format('YYYY-MM-DD') : '-';

    return (
        <tr key={voucher.id}>
            <td>{voucher.key}</td>

            <td>{createdAt}</td>
            <td>{voucher.executions}</td>
            <td>{validUntil}</td>
            <td>{voucher.takenBy?.name}</td>
            <td>
                <ActionIcon color="red" onClick={() => onDelete(voucher.id)}>
                    <IconTrash size={16}></IconTrash>
                </ActionIcon>
            </td>
        </tr>
    );
};
