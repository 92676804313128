import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { USER_IMAGES } from '@/types/query-keys';

import { Image } from '../types/image';

export const listImages = async (): Promise<Image[]> => {
    return (await axios.get('/image')).data;
};

type QueryFnType = typeof listImages;

type UseImagesOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useImages = ({ config }: UseImagesOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [USER_IMAGES],
        queryFn: () => listImages(),
        ...config,
    });
};
