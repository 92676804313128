import './index.css';
import './mantine-contrib.css';

import * as ReactDOMClient from 'react-dom/client';

import App from './App';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLDivElement);

// Initial render: Render an element to the root.
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
