import { useModal } from '@ebay/nice-modal-react';
import { Table } from '@mantine/core';
import React from 'react';

import { useChangeProductOrder } from '@/features/admin/components/stripe/api/order-product';
import { useQueryProducts } from '@/features/admin/components/stripe/api/products';
import { ProductModal } from '@/features/admin/components/stripe/ProductModal';
import { ProductRow } from '@/features/admin/components/stripe/ProductRow';
import { AdminProductType } from '@/features/admin/components/stripe/types/license-product';

export const ProductsTable = () => {
    const { data: products } = useQueryProducts();
    const productDialog = useModal(ProductModal);
    const { mutate: changeProductOrder } = useChangeProductOrder();

    const handleEditProduct = (product: AdminProductType) => {
        productDialog.show({ productId: product.id });
    };

    const handleOrderChange = (item: AdminProductType, order: 'up' | 'down') => {
        changeProductOrder({ id: item.id, order });
    };

    const rows =
        products?.map((item) => (
            <ProductRow
                item={item}
                key={item.id}
                onEdit={handleEditProduct}
                onOrderChange={handleOrderChange}
            ></ProductRow>
        )) || [];

    return (
        <Table highlightOnHover>
            <thead>
                <tr>
                    <th style={{ width: 90 }}>Order</th>
                    <th>Title</th>
                    <th>Active</th>
                    <th style={{ width: '10%' }}>Actions</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
};
