import { ActionIcon, Group, Table } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import React, { FC } from 'react';

import { useDeleteModelAccess } from '@/features/admin/api/delete-model-access';
import { useModelAccess } from '@/features/admin/api/model-access';
import { useUpdateModelAccess } from '@/features/admin/api/update-model-access';
import { UsernameSelect } from '@/features/admin/components/jobs/UsernameTextInput';

interface ComputeModelAccessProps {
    id: string;
}

export const ComputeModelAccess: FC<ComputeModelAccessProps> = ({ id }) => {
    const { data } = useModelAccess({ modelId: id });
    const addAccess = useUpdateModelAccess({ modelId: id });
    const delAccess = useDeleteModelAccess({ modelId: id });

    const handleUserChange = (userId: string) => {
        addAccess.mutate(userId);
    };

    const handleAccessDelete = (userId: string) => {
        delAccess.mutate(userId);
    };

    const rows =
        data?.map((element) => (
            <tr key={element.id}>
                <td>{`${element.user.name} ${element.user.surname}`}</td>
                <td>
                    <ActionIcon color={'red'} onClick={() => handleAccessDelete(element.userId)}>
                        <IconX></IconX>
                    </ActionIcon>
                </td>
            </tr>
        )) ?? [];

    return (
        <Group>
            <UsernameSelect onChange={handleUserChange} placeholder="Select user" w={'100%'} />

            {data && data.length > 0 && (
                <Table>
                    <thead>
                        <tr>
                            <th>User</th>
                            <th style={{ width: 50 }}>Remove</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            )}
        </Group>
    );
};
