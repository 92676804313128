import { createStyles } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { MiviaLoader } from '@/components/MiviaLoader';

const useStyles = createStyles((theme, _params, getRef) => ({
    page: {
        display: 'grid',
        placeItems: 'center',
        height: '100vh',
    },
}));

export const FullPageMiviaLoader: FunctionComponent = () => {
    const { classes } = useStyles();
    return (
        <div className={classes.page}>
            <MiviaLoader width={'20%'}></MiviaLoader>
        </div>
    );
};
