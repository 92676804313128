import { useModal } from '@ebay/nice-modal-react';
import { ActionIcon, Badge, Grid, Group, LoadingOverlay, Text } from '@mantine/core';
import { IconRefresh, IconStethoscope, IconTrashX } from '@tabler/icons-react';
import React, { FC } from 'react';

import { ConfirmDialog } from '@/components/ConfirmDialog';
import { useDeleteModelInstance } from '@/features/admin/api/delete-model-instance';
import { useRestartComputeModel } from '@/features/admin/api/restart-model-instance';
import { useTestInstance } from '@/features/admin/components/nodes/api/test-instance';
import { ConfirmInstanceDeleteDialog } from '@/features/admin/components/nodes/ConfirmInstanceDeleteDialog';
import { InstanceTestDialog } from '@/features/admin/components/nodes/InstanceTestDialog';
import { ComputeModelInstance } from '@/features/admin/types/compute-model-instance';
import { NodeType } from '@/features/admin/types/nodeType';

interface NodeItemModelsProps {
    node: NodeType;
    isBusy: boolean;
}

export const NodeItemModels: FC<NodeItemModelsProps> = ({ node, isBusy }) => {
    const deleteInstanceDialog = useModal(ConfirmInstanceDeleteDialog);
    const { mutate: deleteModelInstance, isLoading: isDeleteLoading } = useDeleteModelInstance();

    const restartInstanceDialog = useModal(ConfirmDialog);
    const { mutate: restartInstance, isLoading: isRestartLoading } = useRestartComputeModel();

    const { mutate: testInstance } = useTestInstance();
    const testInstanceDialog = useModal(InstanceTestDialog);

    const isBusyOverall = isBusy || isDeleteLoading || isRestartLoading;

    const handleDeleteInstanceClick = (instance: ComputeModelInstance, node: NodeType) => {
        deleteInstanceDialog
            .show({ modelName: instance.model.displayName, nodeName: node.name })
            .then((result) => {
                if (result) {
                    deleteModelInstance(instance.id);
                }
            });
    };

    const handleRestartInstanceClick = (instance: ComputeModelInstance) => {
        restartInstanceDialog
            .show({ questionText: `Are you sure you want to restart this model?` })
            .then((result) => {
                if (result) {
                    restartInstance(instance.id);
                }
            });
    };

    const handleTestInstanceClick = (instance: ComputeModelInstance) => {
        testInstance(instance.id, {
            onSuccess: (data) => testInstanceDialog.show({ jobId: data }),
        });
    };

    return (
        <div>
            <Text>Models: </Text>
            <div style={{ position: 'relative', height: 'calc(100% - 20px)' }}>
                <LoadingOverlay overlayBlur={2} visible={isBusyOverall} />
                <Grid grow gutter="xs">
                    {node.modelsInstances.map((instance) => (
                        <React.Fragment key={instance.model.id}>
                            <Grid.Col span={5}>{instance.model.displayName}</Grid.Col>
                            <Grid.Col span={1}>{instance.port}</Grid.Col>
                            <Grid.Col span={2}>
                                <Badge color="gray" size="lg">
                                    {instance.version}
                                </Badge>
                            </Grid.Col>
                            <Grid.Col span={2}>
                                <Group align="flex-end" spacing={0}>
                                    <ActionIcon
                                        color="red"
                                        disabled={node.status == 'OFFLINE'}
                                        radius="xl"
                                    >
                                        <IconTrashX
                                            onClick={() =>
                                                handleDeleteInstanceClick(instance, node)
                                            }
                                            size={16}
                                        ></IconTrashX>
                                    </ActionIcon>

                                    <ActionIcon disabled={node.status == 'OFFLINE'} radius="xl">
                                        <IconRefresh
                                            onClick={() => handleRestartInstanceClick(instance)}
                                            size={16}
                                        ></IconRefresh>
                                    </ActionIcon>

                                    <ActionIcon disabled={node.status == 'OFFLINE'} radius="xl">
                                        <IconStethoscope
                                            onClick={() => handleTestInstanceClick(instance)}
                                            size={16}
                                        ></IconStethoscope>
                                    </ActionIcon>
                                </Group>
                            </Grid.Col>
                        </React.Fragment>
                    ))}
                </Grid>
            </div>
        </div>
    );
};
