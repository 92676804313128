import React, { FunctionComponent } from 'react';

import { useAdminStyles } from '@/features/admin/components/admin-styles';
import { AdminDashboardPanel } from '@/features/admin/components/dashboard/AdminDashboardPanel';

export const AdminDashboard: FunctionComponent = () => {
    const { classes } = useAdminStyles();

    return (
        <div className={classes.container}>
            <AdminDashboardPanel />
        </div>
    );
};
