import { Button, Group, NumberInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import React, { FunctionComponent } from 'react';

export interface VoucherFormValues {
    count: number;
    validUntil?: Date;
    executions: number;
}

interface VoucherFormProps {
    onSubmit: (values: VoucherFormValues) => void;
    onCancel: () => void;
}

export const VoucherForm: FunctionComponent<VoucherFormProps> = ({ onSubmit, onCancel }) => {
    const form = useForm<VoucherFormValues>({
        initialValues: {
            count: 1,
            executions: 10,
        },
    });

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <NumberInput label="Number of vouchers" required {...form.getInputProps('count')} />

            <NumberInput
                label="Number of model executions"
                required
                {...form.getInputProps('executions')}
            />

            <DatePickerInput
                label="Valid until"
                {...form.getInputProps('validUntil')}
                popoverProps={{ withinPortal: true }}
            />

            <Group mt="md" position="right">
                <Button onClick={() => onCancel()} variant="outline">
                    Cancel
                </Button>
                <Button type="submit">Generate</Button>
            </Group>
        </form>
    );
};
