import { ActionIcon, Group } from '@mantine/core';
import { IconArrowUpRightCircle, IconTrash } from '@tabler/icons-react';
import React, { FunctionComponent } from 'react';

import { DockerImage } from '@/features/admin/types/docker-image';

interface ModelRowProps {
    dockerImage: DockerImage;
    onUpdate: (model: DockerImage) => void;
    onDelete: (model: DockerImage) => void;
}

export const RegistryRow: FunctionComponent<ModelRowProps> = ({
    dockerImage,
    onUpdate,
    onDelete,
}) => {
    return (
        <tr>
            <td>
                {dockerImage.tag}
                {/*<Group spacing={5}>*/}
                {/*    {model.tag}*/}
                {/*    {model.active && (*/}
                {/*        <Tooltip label="The main model chosen for the calculations" openDelay={500}>*/}
                {/*            <ActionIcon variant="transparent">*/}
                {/*                <IconCheck color="green" size={20}></IconCheck>*/}
                {/*            </ActionIcon>*/}
                {/*        </Tooltip>*/}
                {/*    )}*/}
                {/*</Group>*/}
            </td>
            <td>{dockerImage.count}</td>
            <td style={{ position: 'relative' }}>
                <Group spacing={0}>
                    <ActionIcon onClick={() => onUpdate(dockerImage)} radius="xl">
                        <IconArrowUpRightCircle size={16}></IconArrowUpRightCircle>
                    </ActionIcon>

                    <ActionIcon color="red" onClick={() => onDelete(dockerImage)} radius="xl">
                        <IconTrash size={16}></IconTrash>
                    </ActionIcon>
                </Group>
            </td>
        </tr>
    );
};
