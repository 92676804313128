import { useModal } from '@ebay/nice-modal-react';
import { Table } from '@mantine/core';
import React, { FC } from 'react';

import { useDeleteModelNews } from '@/features/admin/components/news/api/delete-model-news';
import { useModelsNews } from '@/features/admin/components/news/api/models-news';
import { NewsDeleteConfirmDialog } from '@/features/admin/components/news/NewsDeleteConfirmDialog';
import { NewsModal } from '@/features/admin/components/news/NewsModal';
import { NewsRow } from '@/features/admin/components/news/NewsRow';
import { ModelNewsType } from '@/features/admin/components/news/types/model-news-type';

export const NewsTable: FC = () => {
    const { data: news } = useModelsNews();
    const deleteNews = useDeleteModelNews();
    const deleteNewsDialog = useModal(NewsDeleteConfirmDialog);
    const editNewsDialog = useModal(NewsModal);

    const handleDeleteModel = (model: ModelNewsType) => {
        deleteNewsDialog.show().then((result) => {
            if (result) {
                deleteNews.mutate(model.id);
            }
        });
    };

    const handleEditModel = (news: ModelNewsType) => {
        editNewsDialog.show({ newsId: news.id });
    };

    const rows =
        news?.map((item) => (
            <NewsRow
                item={item}
                key={item.id}
                onDelete={handleDeleteModel}
                onEdit={handleEditModel}
            ></NewsRow>
        )) || [];

    return (
        <Table highlightOnHover>
            <thead>
                <tr>
                    <th>Title</th>
                    <th style={{ width: 150 }}>Created at</th>
                    <th style={{ width: '10%' }}>Actions</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
};
