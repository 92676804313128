import { showNotification } from '@mantine/notifications';

import i18n from '../../../i18n/config';

export const showJobStartedNotification = () => {
    showNotification({
        id: 'job-started',
        withCloseButton: true,
        autoClose: 5000,
        title: i18n.t('job.started') as string,
        message: i18n.t('job.startedMsg') as string,
        color: 'primary',
    });
};

export const showJobFinishedNotification = () => {
    showNotification({
        id: 'job-finished',
        withCloseButton: true,
        autoClose: 5000,
        title: i18n.t('job.finished') as string,
        message: i18n.t('job.finishedMsg') as string,
        color: 'primary',
    });
};

export const showJobFailedNotification = () => {
    showNotification({
        id: 'job-failed',
        withCloseButton: true,
        autoClose: 5000,
        title: i18n.t('job.failed') as string,
        message: i18n.t('job.failedMsg') as string,
        color: 'yellow',
    });
};

export const showJobsArchivedNotification = () => {
    showNotification({
        id: 'jobs-archived',
        withCloseButton: true,
        autoClose: 5000,
        title: i18n.t('job.archived') as string,
        message: i18n.t('job.archivedMsg') as string,
        color: 'primary',
    });
};

export const showJobsDeletedNotification = () => {
    showNotification({
        id: 'jobs-deleted',
        withCloseButton: true,
        autoClose: 5000,
        title: i18n.t('job.deleted') as string,
        message: i18n.t('job.deletedMsg') as string,
        color: 'primary',
    });
};

export const showVoteNotification = () => {
    showNotification({
        id: 'up-vote',
        withCloseButton: true,
        autoClose: 5000,
        title: 'Thank you!',
        message: 'We appreciate your feedback.',
        color: 'primary',
    });
};
