import { useLocalStorage } from '@mantine/hooks';
import React, { useState, useCallback, useContext } from 'react';

import { UserJobType } from '@/features/models/types/userJobType';

interface SelectedResultsContextProps {
    selected: string[];
    toggleSelected: (jobId: string) => void;
    selectAll: () => void;
    deselectAll: () => void;
    setCurrentQueryResults: (jobs: UserJobType[]) => void;
    page: number;
    total: number;
    pageSize: number;
    setPage: (page: number) => void;
    setTotal: (total: number) => void;
    setPageSize: (pageSize: number) => void;
}

const defaultContextValue: SelectedResultsContextProps = {
    selected: [],
    page: 1,
    total: 1,
    pageSize: 10,
    setPage: (_) => {},
    setTotal: (_) => {},
    setPageSize: (_) => {},
    toggleSelected: (_) => {},
    selectAll: () => {},
    deselectAll: () => {},
    setCurrentQueryResults: (_) => {},
};

export const SelectedResultsContext =
    React.createContext<SelectedResultsContextProps>(defaultContextValue);

export const useResultsContext = (): SelectedResultsContextProps => {
    const context = useContext(SelectedResultsContext);
    if (!context) {
        throw new Error('useSelectedResults must be used within a SelectedResultsProvider');
    }
    return context;
};

interface SelectedResultsProviderProps {
    children: React.ReactNode;
}

export const SelectedResultsProvider: React.FC<SelectedResultsProviderProps> = ({ children }) => {
    const [selected, setSelected] = useState<string[]>([]);
    const [availableResults, setAvailableResults] = useState<string[]>([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useLocalStorage<number>({
        key: 'results-page-size',
        defaultValue: 10,
        getInitialValueInEffect: false,
    });
    const [total, setTotal] = useState(1);

    const toggleSelected = useCallback((jobId: string) => {
        setSelected((current) =>
            current.includes(jobId) ? current.filter((item) => item !== jobId) : [...current, jobId]
        );
    }, []);

    const handleSetTotal = useCallback((total: number) => {
        // if total is nan or infinity, set it to 10
        if (isNaN(total) || !isFinite(total)) {
            setTotal(1);
        } else {
            setTotal(total);
        }
    }, []);

    const selectAll = useCallback(() => {
        const isAllSelected = availableResults.length === selected.length;
        setSelected(isAllSelected ? [] : [...availableResults]);
    }, [selected, availableResults]);

    const deselectAll = useCallback(() => {
        setSelected([]);
    }, []);

    const setCurrentQueryResults = useCallback((jobs: UserJobType[]) => {
        setAvailableResults(jobs.map((job) => job.id));
    }, []);

    return (
        <SelectedResultsContext.Provider
            value={{
                selected,
                toggleSelected,
                setCurrentQueryResults,
                selectAll,
                deselectAll,
                page,
                pageSize,
                total,
                setPage,
                setTotal: handleSetTotal,
                setPageSize,
            }}
        >
            {children}
        </SelectedResultsContext.Provider>
    );
};
