import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from '@/components/Panel';

import { AccessKeysForm } from './AccessKeysForm';
import { AccessKeysInfo } from './AccessKeysInfo';
import { AccessKeysList } from './AccessKeysList';

export const AccessKeysPage: FC = () => {
    const { t } = useTranslation();

    return (
        <Panel title={t('userAccessKeys.keys')}>
            <AccessKeysInfo />
            <AccessKeysList />
            <AccessKeysForm />
        </Panel>
    );
};
