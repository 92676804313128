import { useModal } from '@ebay/nice-modal-react';
import { Button, Group } from '@mantine/core';
import React, { FC } from 'react';

import { ComputeModelModal } from '@/features/admin/components/compute-models/ComputeModelModal';

export const ComputeModelsPanel: FC = () => {
    const dialog = useModal(ComputeModelModal);

    return (
        <Group mt={5} position="right">
            <Button onClick={() => dialog.show()}>Add</Button>
        </Group>
    );
};
