import {
    Button,
    Group,
    NumberInput,
    Select,
    Stack,
    Switch,
    TextInput,
    Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';
import React, { FunctionComponent, useEffect } from 'react';

export interface AddNodeFormValues {
    ip: string;
    name: string;
    description: string;
    hasGpu: boolean;
    priority: number;
    nodeType: 'ON_PREMISE' | 'CLOUD';
    provider?: 'OVH' | '';
    instanceId?: string | '';
}

interface AddNodeFormProps {
    onSubmit: (values: AddNodeFormValues) => void;
    onCancel: () => void;
}

export const AddNodeForm: FunctionComponent<AddNodeFormProps> = ({ onSubmit, onCancel }) => {
    const [isCloud, setIsCloud] = React.useState(false);
    const form = useForm<AddNodeFormValues>({
        initialValues: {
            ip: '',
            name: '',
            description: '',
            hasGpu: false,
            priority: 0,
            nodeType: 'ON_PREMISE',
            provider: '',
            instanceId: '',
        },
        validate: {
            ip: (value) =>
                /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(value)
                    ? null
                    : 'Invalid IP Address',
        },
    });

    // Reacting to changes in the node type
    useEffect(() => {
        const isOnPremise = form.values.nodeType === 'ON_PREMISE';
        setIsCloud(!isOnPremise);
        if (isOnPremise) {
            form.setValues({ provider: undefined, instanceId: '' });
        }
    }, [form.values.nodeType]);

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Stack>
                <Select
                    label="Node Type"
                    required
                    {...form.getInputProps('nodeType')}
                    data={[
                        { label: 'On Premise', value: 'ON_PREMISE' },
                        { label: 'Cloud', value: 'CLOUD' },
                    ]}
                />
                <TextInput label="Name" required {...form.getInputProps('name')} />
                <TextInput label="IP Address" required {...form.getInputProps('ip')} />
                <Select
                    clearable
                    data={[{ label: 'OVH', value: 'OVH' }]}
                    disabled={!isCloud}
                    label="Cloud provider"
                    required={isCloud}
                    {...form.getInputProps('cloudProvider')}
                />
                <TextInput
                    disabled={!isCloud}
                    label="Cloud instance ID"
                    required={isCloud}
                    {...form.getInputProps('instanceId')}
                />
                <TextInput label="Description" required {...form.getInputProps('description')} />
                <NumberInput
                    description="From 0 to 100. Higher priority nodes will be selected first."
                    label="Priority"
                    max={100}
                    min={0}
                    required
                    {...form.getInputProps('priority')}
                />
                <Group spacing="xs">
                    <Switch label="GPU Support" {...form.getInputProps('hasGpu')} />
                    <Tooltip
                        label="Enables support for NVIDIA cards. If the node does not have a CUDA-capable graphics card, enabling this option will prevent the models from running!"
                        multiline
                        width={350}
                        withArrow
                    >
                        <div>
                            <IconAlertCircle size={18} style={{ display: 'block', opacity: 0.5 }} />
                        </div>
                    </Tooltip>
                </Group>
            </Stack>
            <Group mt="md" position="right">
                <Button onClick={() => onCancel()} variant="outline">
                    Cancel
                </Button>
                <Button type="submit">Add</Button>
            </Group>
        </form>
    );
};
