import { useMutation } from '@tanstack/react-query';

import { LicenseFormValues } from '@/features/admin/components/LicenseForm';
import { License } from '@/features/admin/types/license';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { USERS } from '@/types/query-keys';

interface UseMutationCreateLicenseDto extends LicenseFormValues {
    userId: string;
}

const createLicense = async (data: UseMutationCreateLicenseDto): Promise<License> => {
    return (await axios.post('/admin/licenses', data)).data;
};

type UseCreateLicenseOptions = {
    config?: MutationConfig<typeof createLicense>;
};

export const useCreateLicense = ({ config }: UseCreateLicenseOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: createLicense,
        onSuccess: (data: License, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [USERS] });
        },
    });
};
