import { Accordion, Alert, Badge, Group } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { AccordionControl } from '@/features/models/component/AccordionControl';
import { ModelResultViewPanel } from '@/features/models/component/ModelResultViewPanel';
import { UserJobType } from '@/features/models/types/userJobType';
import { useDelayedVisibility } from '@/hooks/useDelayedVisibility';

interface JobSuccessProps {
    job: UserJobType;
    isVisible?: boolean;
    postfixTitle?: string;
    hideControls?: boolean;
}

const OutdatedAlert = () => {
    const { t } = useTranslation();
    return (
        <Alert
            color="red"
            icon={<IconAlertCircle size="1rem" />}
            mb={16}
            mt={8}
            title={t('model.outdatedTitle')}
            variant="filled"
        >
            <div>{t('model.outdatedText')}</div>
        </Alert>
    );
};

const JobSuccessInternal: FunctionComponent<JobSuccessProps> = ({
    job,
    isVisible = false,
    postfixTitle,
    hideControls,
}) => {
    const { t } = useTranslation();
    const shouldRenderPanel = useDelayedVisibility(isVisible, 1000);
    const outdatedBadge = job.outdated ? (
        <Badge color="red" radius="sm" sx={{ outline: '1px solid #c92a2a' }}>
            {t('model.outdatedBadge')}
        </Badge>
    ) : null;

    return (
        <Accordion.Item value={job.id}>
            <AccordionControl
                hideControls={hideControls}
                job={job}
                key={`${job.id}-header`}
                selectable={true}
            >
                <Group align="baseline">
                    {job.image.orginalFilename} {postfixTitle} {outdatedBadge}
                </Group>
            </AccordionControl>
            <Accordion.Panel>
                {job.outdated && <OutdatedAlert />}
                {shouldRenderPanel && <ModelResultViewPanel job={job} />}
            </Accordion.Panel>
        </Accordion.Item>
    );
};

export const JobSuccess = React.memo(JobSuccessInternal);
