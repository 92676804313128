import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { JobsFilterFormType } from '@/features/admin/components/jobs/JobsFilterFormType';
import { AdminViewableUserJob } from '@/features/admin/types/user-jobs';
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { SYSTEM_JOBS } from '@/types/query-keys';
import { removeNullishFields } from '@/utils/removeNullFields';

type JobsFilterQueryParams = JobsFilterFormType;

const getJobsWrapper = ({ pageParam = 1 }) => {
    return async (
        params?: JobsFilterQueryParams
    ): Promise<{ data: AdminViewableUserJob[]; nextPage: number | null }> => {
        // remove undefined fields
        const cleanedParams = removeNullishFields(params);

        const response = await axios.get('/admin/jobs', {
            params: { ...cleanedParams, page: pageParam },
        });

        const nextPage = parseInt(response.headers['x-next-page']);
        return {
            nextPage: isNaN(nextPage) ? null : nextPage,
            data: response.data,
        };
    };
};

type QueryFnType = ReturnType<typeof getJobsWrapper>;

type UseJobsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useInfinityJobs = ({ config }: UseJobsOptions = {}) => {
    const [filter, setFilter] = useState<JobsFilterFormType>({} as JobsFilterFormType);
    const queryClient = useQueryClient();

    const queryInfo = useInfiniteQuery({
        queryKey: [SYSTEM_JOBS, ...Object.values(filter)],
        queryFn: (params) => getJobsWrapper(params)(filter),
        getNextPageParam: (lastPage, pages) => {
            return lastPage.nextPage;
        },
    });

    const setFilterAndInvalidate = async (newFilter: JobsFilterFormType) => {
        queryClient.removeQueries({ queryKey: [SYSTEM_JOBS] });
        setFilter(newFilter);
    };

    return {
        ...queryInfo,
        setFilter: setFilterAndInvalidate,
    };
};
