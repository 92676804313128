import React, { Suspense } from 'react';
import { Navigate, Outlet, redirect } from 'react-router-dom';

import { ContentMiviaLoader } from '@/components/ContentMiviaLoader';
import { MainLayout } from '@/components/Layout/MainLayout';
import { SandboxRoutes } from '@/features/sandbox/routes';
import { UserAccessKeysRoutes } from '@/features/user-access-keys/routes';
import { UserSubscriptionsRoutes } from '@/features/user-license/routes';
import { UserProfileRoutes } from '@/features/user-profile/routes';
import { lazyImport } from '@/utils/lazyImport';

// import { Spinner } from '@/components/Elements';
// import { MainLayout } from '@/components/Layout';
// import { lazyImport } from '@/utils/lazyImport';
//
const { ModelsRoutes } = lazyImport(() => import('@/features/models'), 'ModelsRoutes');
const { UserDashboardRoutes } = lazyImport(
    () => import('@/features/user-dashboard'),
    'UserDashboardRoutes'
);
const { HistoryRoutes } = lazyImport(() => import('@/features/history'), 'HistoryRoutes');
// const { UserProfile } = lazyImport(() => import('@/features/users'), 'UserProfile');
// const { Users } = lazyImport(() => import('@/features/users'), 'Users');
const { GroupAdminRoutes } = lazyImport(
    () => import('@/features/group-admin/routes'),
    'GroupAdminRoutes'
);

const App = () => {
    return (
        <MainLayout title="">
            <Suspense
                fallback={
                    <div>
                        <ContentMiviaLoader />
                    </div>
                }
            >
                <Outlet />
            </Suspense>
        </MainLayout>
    );
};

export const protectedRoutes = [
    {
        path: '',
        element: <App />,
        children: [
            { path: '/models/*', element: <ModelsRoutes /> },
            { path: '/dashboard/*', element: <UserDashboardRoutes /> },
            { path: '/profile/*', element: <UserProfileRoutes /> },
            { path: '/history/*', element: <HistoryRoutes /> },
            { path: '/subscription/*', element: <UserSubscriptionsRoutes /> },
            { path: '/access-keys/*', element: <UserAccessKeysRoutes /> },
            { path: '/sandbox/*', element: <SandboxRoutes /> },
            { path: '/', element: <Navigate to="/dashboard" /> },
        ],
    },
];

export const groupAdmin = [
    {
        path: '',
        element: <App />,
        children: [
            {
                path: '/group-admin/*',
                element: <GroupAdminRoutes />,
            },
        ],
    },
];
