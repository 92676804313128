export const removeNullFields = (obj: Record<string, unknown>) =>
    Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

export const removeNullishFields = (obj: Record<string, any> | undefined) => {
    if (!obj) return {};

    return Object.fromEntries(
        Object.entries(obj).filter(
            ([key, value]) => value !== null && value !== undefined && value !== ''
        )
    );
};
