import { Tabs } from '@mantine/core';
import { IconBolt, IconPhoto } from '@tabler/icons-react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAdminStyles } from '../components/admin-styles';
import { AdminUserJobs } from '../routes/AdminUserJobs';
import { AdminUserProfile } from '../routes/AdminUserProfile';

export const AdminUser = () => {
    const { id, tab } = useParams();
    const navigate = useNavigate();
    const { classes } = useAdminStyles();
    const handleTabChange = (value: string) => {
        if (value) {
            navigate(`/admin/users/${id}/${value}`);
        }
    };

    return (
        <div className={classes.container}>
            <Tabs defaultValue={tab} onTabChange={handleTabChange}>
                <Tabs.List>
                    <Tabs.Tab icon={<IconPhoto size={14} />} value="profile">
                        Profile
                    </Tabs.Tab>
                    <Tabs.Tab icon={<IconBolt size={14} />} value="jobs">
                        Jobs
                    </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel pt="xs" value="profile">
                    <AdminUserProfile userId={id}></AdminUserProfile>
                </Tabs.Panel>
                <Tabs.Panel pt="xs" value="jobs">
                    <AdminUserJobs userId={id}></AdminUserJobs>
                </Tabs.Panel>
            </Tabs>
        </div>
    );
};
