import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { USER } from '@/types/query-keys';

import { UserAccount } from '../types/user-account';

export const getUserProfile = async ({
    userId,
}: {
    userId: string | undefined;
}): Promise<UserAccount> => {
    return (await axios.get(`/users/${userId}`)).data;
};

type QueryFnType = typeof getUserProfile;

type UseUserJobOptions = {
    userId: string | undefined;
    config?: QueryConfig<QueryFnType>;
};

export const useUserProfile = ({ config, userId }: UseUserJobOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [USER, userId],
        enabled: !!userId,
        queryFn: () => getUserProfile({ userId }),
        ...config,
    });
};
