import { useMutation } from '@tanstack/react-query';

import { showInsightsReset } from '@/features/admin/api/notifications';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

const resetInsights = async (): Promise<any> => {
    return (await axios.put('/admin/users/preferences/insights')).data;
};

type UseResetInsightsOptions = {
    config?: MutationConfig<typeof resetInsights>;
};

export const useResetInsights = ({ config }: UseResetInsightsOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: resetInsights,
        onSuccess: (data: any) => {
            showInsightsReset();
        },
    });
};
