import { Center, Loader, ScrollArea, Table } from '@mantine/core';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { getImageUrl } from '@/config';
import { useAdminStyles } from '@/features/admin/components/admin-styles';
import { LinkToModal } from '@/features/models/component/LinkToModal';

import { AdminViewableUserJob } from '../../types/user-jobs';

import { JobStatusBadge } from './JobStatusBadge';

interface AdminUserJobProps {
    data: AdminViewableUserJob[] | undefined;
}

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const RemovedRow = ({ job }: { job: AdminViewableUserJob }) => {
    return (
        <tr>
            <td>{dayjs(job.createdAt).format(dateFormat)}</td>
            <td colSpan={4} style={{ fontStyle: 'italic' }}>
                Image removed
            </td>
        </tr>
    );
};

export function UserJobs({ data }: AdminUserJobProps) {
    const { classes, cx } = useAdminStyles();

    const [scrolled, setScrolled] = useState(false);

    if (!data)
        return (
            <Center>
                <Loader size="xl" variant="dots" />
            </Center>
        );

    const rows = data.map((job) => {
        if (!job.image) return <RemovedRow job={job} key={job.id} />;

        const timeDiff = dayjs(job.finishedAt).diff(job.createdAt, 'seconds', true);
        return (
            <tr key={job.id}>
                <td>
                    <Link className={classes.link} to={`/admin/jobs/${job.id}`}>
                        {dayjs(job.createdAt).format(dateFormat)}
                    </Link>
                </td>
                <td>{timeDiff < 0 ? 0 : timeDiff}s</td>
                <td>
                    <JobStatusBadge status={job.status} />
                </td>
                <td>{job.model.displayName}</td>
                <td>
                    <LinkToModal
                        caption={job.image.orginalFilename}
                        imageUrl={getImageUrl(job.image.id)}
                    >
                        <span>{job.image.orginalFilename}</span>
                    </LinkToModal>
                </td>
            </tr>
        );
    });

    return (
        <ScrollArea
            onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
            sx={{ height: '100%' }}
        >
            <Table>
                <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
                    <tr>
                        <th>Job date</th>
                        <th>Duration</th>
                        <th>Status</th>
                        <th>Model</th>
                        <th>Image</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        </ScrollArea>
    );
}
