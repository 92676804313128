import { Badge, createStyles, Image, ImageProps, Tooltip } from '@mantine/core';
import React, { FunctionComponent, useEffect } from 'react';

interface OverlayImageProps extends ImageProps {
    outdated?: boolean;
}

const useStyles = createStyles((theme, _params, getRef) => ({
    wrapper: {
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        top: 5,
        left: 5,
        fontSize: theme.fontSizes.xs,
    },
}));

export const OverlayImage: FunctionComponent<OverlayImageProps> = ({ outdated, src, ...props }) => {
    const { classes } = useStyles();
    const [currentSrc, setCurrentSrc] = React.useState<string | undefined | null>(src);

    useEffect(() => {
        // If image was visible once - do not unload it
        // TODO: Can be improved?
        if (src != undefined && currentSrc == undefined) setCurrentSrc(src);
    }, [currentSrc, src]);

    return (
        <div className={classes.wrapper}>
            <Image {...props} src={currentSrc} />

            {outdated && (
                <Tooltip
                    label="A newer version of the model used in the analysis is available"
                    openDelay={1000}
                >
                    <Badge className={classes.overlay} color="red" radius="sm">
                        Outdated
                    </Badge>
                </Tooltip>
            )}
        </div>
    );
};
