import { createStyles } from '@mantine/core';

export const useAdminStyles = createStyles((theme) => ({
    header: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        transition: 'box-shadow 150ms ease',
        zIndex: 1, // TODO: Should not be used here, but it's a workaround for images covering header

        '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            borderBottom: `1px solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
            }`,
        },
    },

    scrolled: {
        boxShadow: theme.shadows.sm,
    },

    container: {
        padding: theme.spacing.xs,
    },

    link: {
        textDecoration: 'none',
        fontWeight: 500,

        '&:link': { textDecoration: 'none' },
        '&:visited': { textDecoration: 'none' },
        '&:hover': { textDecoration: 'none' },
        '&:active': { textDecoration: 'none' },
    },

    selectedRow: {
        backgroundColor: theme.colors.forest[0],
    },

    userWithPermanentDeletionRow: {
        color: theme.colors.grape[7],
        fontWeight: 500,

        a: {
            color: theme.colors.grape[7],
            fontWeight: 500,
        },

        input: {
            color: theme.colors.grape[7],
            fontWeight: 500,
        },
    },

    userNotConfirmedRow: {
        color: theme.colors.gray[4],

        a: {
            color: theme.colors.gray[4],
        },

        input: {
            color: theme.colors.gray[4],
        },
    },
}));
