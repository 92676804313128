import { useQuery } from '@tanstack/react-query';

import { ComputeModel } from '@/features/admin/types/compute-model';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { COMPUTE_MODELS } from '@/types/query-keys';

export const getComputeModels = async (): Promise<ComputeModel[]> => {
    return (await axios.get('/admin/compute-models')).data;
};

type QueryFnType = typeof getComputeModels;

type UseComputeModelsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useComputeModels = ({ config }: UseComputeModelsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [COMPUTE_MODELS],
        queryFn: getComputeModels,
        ...config,
    });
};
