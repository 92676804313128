const phoneNumberRegex = /^(\+\d{1,3}\s?)?[()\d\s.-]{5,}$/;

/**
 * Validates a phone number using the Zod library. If phone number is not provided, returns null.
 *
 * @param {string} phoneNumber - The phone number to validate.
 * @returns {boolean} Returns null if the phone number is valid, otherwise returns an error message.
 */
export const phoneValidator = (phoneNumber: string | undefined): null | string  => {
    if (!phoneNumber) return null;

    const trimmed = phoneNumber.trim();

    return phoneNumberRegex.test(
        trimmed
    )
        ? null
        : 'auth.phoneInvalid';
}

export const phoneNumberPlaceholder = '+49 211 1234567';
