import { Progress, Stack, Text } from '@mantine/core';
import React from 'react';

export const StatProgress = ({
    label,
    value,
    formatValue = true,
}: {
    label: string;
    value: number;
    formatValue?: boolean;
}) => {
    let valueString = '';
    if (formatValue) {
        valueString = value ? value?.toFixed(2) + '%' : 'N/A';
    } else {
        valueString = value ? value.toString() : 'N/A';
    }
    const color = value < 90 ? 'forest' : 'red';
    return (
        <Stack mb={8} spacing={0}>
            <Text>{label}:</Text>
            <Progress color={color} label={valueString} size="xl" value={value} />
        </Stack>
    );
};
