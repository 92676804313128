import { Center, Flex, Group, Image, Loader } from '@mantine/core';
import {
    isBarChartResult,
    isDataTableResult,
    isHistogramResult,
    isImageResult,
    isMessageResult,
} from 'mivia-shared';
import React, { FunctionComponent } from 'react';

import { getResultUrlWithFilename } from '@/config';
import { BarChartWrapper } from '@/features/models/component/BarChartWrapper';
import { DataTable } from '@/features/models/component/DataTable';
import { HistogramWrapper } from '@/features/models/component/HistogramWrapper';
import { LinkToModal } from '@/features/models/component/LinkToModal';
import { Message } from '@/features/models/component/Message';
import { prepareResults } from '@/features/models/helpers/prepare-results';
import { ResultView } from '@/features/models/types/result-view';

export const ModelResultViewPanel: FunctionComponent<ResultView> = ({ job }) => {
    const { imageUrl, caption } = prepareResults(job);

    const barCharts = job.result?.results?.filter(isBarChartResult) ?? [];
    const histograms = job.result?.results?.filter(isHistogramResult) ?? [];
    const dataTables = job.result?.results?.filter(isDataTableResult) ?? [];
    const images = job.result?.results?.filter(isImageResult) ?? [];
    const messages = job.result?.results?.filter(isMessageResult) ?? [];

    return (
        <>
            {(job.resultId && (
                <Group align="flex-start">
                    <Flex direction={'column'} w={'100%'}>
                        {messages.map((message, i) => (
                            <Message data={message.data} key={`message${i}`} />
                        ))}
                    </Flex>

                    <LinkToModal caption={caption} imageUrl={imageUrl}>
                        <Image alt="Source" fit="cover" src={imageUrl} width={350} />
                    </LinkToModal>

                    {images.map((image, i) => {
                        const resultUrl = getResultUrlWithFilename(job.id, image.filename);
                        return (
                            <LinkToModal caption={caption} imageUrl={resultUrl} key={`image${i}`}>
                                <Image
                                    alt="Result"
                                    fit="cover"
                                    placeholder
                                    src={resultUrl}
                                    width={350}
                                />
                            </LinkToModal>
                        );
                    })}

                    {barCharts.map((chart, i) => (
                        <BarChartWrapper
                            data={chart.data}
                            key={`chart${i}`}
                            keys={chart.keys}
                            xLabel={chart.xLabel}
                            yLabels={chart.yLabels}
                        />
                    ))}

                    {dataTables.map((table, i) => (
                        <DataTable key={`table${i}`} results={table} />
                    ))}

                    {histograms.map((histogram, i) => (
                        <HistogramWrapper key={`chart${i}`} results={histogram} />
                    ))}
                </Group>
            )) || (
                <Center>
                    <Loader size="lg" variant="bars" />
                </Center>
            )}
        </>
    );
};
