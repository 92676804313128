import { Button, Group, Input, Select, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FunctionComponent, useEffect } from 'react';

import { TextEditor } from '@/components/TextEditor/TextEditor';
import { useComputeModels } from '@/features/admin/api/compute-models';
import { ModelNewsDetailType } from '@/features/admin/components/news/types/model-news-type';

import { ModelNewsFormValues } from './types/model-news-form-values';

interface ModelNewsFormProps {
    onSubmit: (values: ModelNewsFormValues) => void;
    onCancel: () => void;
    initialValues?: ModelNewsDetailType;
}

export const NewsForm: FunctionComponent<ModelNewsFormProps> = ({
    onSubmit,
    onCancel,
    initialValues,
}) => {
    const { data: models } = useComputeModels();
    const form = useForm<ModelNewsFormValues>({
        initialValues: {
            titleEn: initialValues?.titleEn || '',
            titleDe: initialValues?.titleDe || '',
            contentEn: initialValues?.contentEn || '',
            contentDe: initialValues?.contentDe || '',
            modelId: initialValues?.modelId,
        },
        validate: {
            titleEn: (value) => (value.trim().length > 0 ? null : 'Title is required'),
            titleDe: (value) => (value.trim().length > 0 ? null : 'Title is required'),
            contentEn: (value) => (value.trim().length > 0 ? null : 'Content is required'),
            contentDe: (value) => (value.trim().length > 0 ? null : 'Content is required'),
        },
    });

    useEffect(() => {
        if (initialValues !== undefined) {
            form.setValues({ ...initialValues });
        }
    }, [initialValues]);

    const modelsSelectValues =
        models?.map((model) => ({
            value: model.id,
            label: model.displayName,
        })) || [];

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Stack>
                <Input type="hidden" {...form.getInputProps('id')}></Input>
                <Select
                    data={modelsSelectValues}
                    {...form.getInputProps('modelId')}
                    clearable={true}
                    label="Select model or leave empty for general news"
                />

                <TextInput
                    {...form.getInputProps('titleEn')}
                    label="Title (English)"
                    required
                ></TextInput>
                <TextInput
                    {...form.getInputProps('titleDe')}
                    label="Title (German)"
                    required
                ></TextInput>

                <TextEditor
                    {...form.getInputProps('contentEn')}
                    label="Content (English)"
                    required
                ></TextEditor>
                <TextEditor
                    {...form.getInputProps('contentDe')}
                    label="Content (German)"
                    required
                ></TextEditor>

                <Group mt="md" position="right">
                    <Button onClick={() => onCancel()} variant="outline">
                        Close
                    </Button>
                    <Button type="submit">Save</Button>
                </Group>
            </Stack>
        </form>
    );
};
