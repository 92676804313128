export const ACTIVE_JOBS = 'active-jobs';
// export const MODEL = 'model';
export const PROFILE = 'user-profile';
export const CURRENT_USER = 'current-user';
export const USERS = 'users';
export const USER = 'user';
export const JOBS = 'JOBS';
export const USER_STATS = 'user-stats';
export const JOB = 'job';
export const GROUPS = 'groups';
export const GROUP = 'group';
export const ARCHIVED_JOBS = 'archived-jobs';
export const PROJECTS = 'projects';
export const VOUCHERS = 'vouchers';
export const LICENSE = 'license';
export const LICENSES = 'licenses';
export const USER_IMAGES = 'user-images';
export const NODES = 'nodes';
export const MODELS = 'MODELS';
export const VERSION = 'version';
export const PRODUCTS = 'products';
export const ADMIN_PRODUCTS = 'admin-products';
export const INIT_STATUS = 'init-status';
export const ADMIN_TERMS = 'admin-terms';
export const IMAGES = 'images';
export const NODE_PERFORMANCE = 'node-performance';
export const TWO_FACTOR_ENABLED = 'two-factor-enabled';
export const SYSTEM_JOBS = 'system-jobs';
export const SYSTEM_IMAGES = 'system-images';
export const USERS_LIST = 'users-list';

export const COMPUTE_MODELS = 'compute-models-registry';
export const COMPUTE_MODELS_ACCESS = 'compute-models-access';
export const COMPUTE_MODELS_STATE = 'compute-models-state';
export const DOCKER_IMAGES = 'docker-images';

export const AVAILABLE_MODELS = 'available-models';
export const TRANSLATIONS = 'translations';
export const MODEL_MANUAL = 'model-manual';
export const ACCESS_KEYS = 'access-keys';
export const JOB_ERROR = 'job-error';
export const APP_SETTINGS = 'app-app-settings';
export const APP_ADMIN_SETTINGS = 'app-admin-settings';
export const ADMIN_MODELS_NEWS = 'admin-models-news';
export const MODEL_NEWS = 'model-news';

export const PUBLIC_FILES = 'public-images';
export const INSIGHTS = 'insights';
export const INSIGHTS_AVAILABLE = 'insights-available';
export const MODEL_EXAMPLE_RESULTS = 'model-example-results';
export const INSIGHTS_PREFS = 'insights-prefs';
export const ADMIN_SERVICE_STATUS = 'admin-service-status';
export const JOBS_COUNTS = 'jobs-counts';
export const TEST_INSTANCE_REQ = 'test-instance-req';
export const ADMIN_DEMO_CONFIG = 'admin-demo-config';

export const SANDBOX = 'sandbox';
