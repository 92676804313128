import React, { FC } from 'react';

import { ScrollableTable } from '@/components/ScrollableTable';
import { useInfinityImages } from '@/features/admin/api/images';
import { useAdminStyles } from '@/features/admin/components/admin-styles';
import { ImageRow } from '@/features/admin/components/images/ImageRow';
import { useImagesContext } from '@/features/admin/components/images/ImagesContext';
import { ImageType } from '@/features/admin/types/imageType';

export const ImagesTable: FC = () => {
    const { filter } = useImagesContext();
    const { classes, cx } = useAdminStyles();
    const { data: images, hasNextPage, fetchNextPage } = useInfinityImages({ filter });

    const allImages =
        images?.pages.reduce((acc, page) => [...acc, ...page.data], [] as ImageType[]) || [];

    const rows = allImages?.map((image) => <ImageRow image={image} key={image.id} />) ?? [];

    return (
        <ScrollableTable hasMore={hasNextPage} loadMore={fetchNextPage}>
            <thead className={cx(classes.header)}>
                <tr>
                    <th style={{ width: 150 }}>Image</th>
                    <th style={{ width: 150 }}>Uploaded at</th>
                    <th style={{ width: 150 }}>Image hash</th>
                    <th style={{ width: 250 }}>Owner</th>
                    <th style={{ width: 250 }}>Allowed for training?</th>
                    <th style={{ width: 250 }}>Deleted?</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </ScrollableTable>
    );
};
