import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal, ScrollArea } from '@mantine/core';

import { MiviaLoader } from '@/components/MiviaLoader';
import { useJobError } from '@/features/models/api/job-error';

interface ErrorModalProps {
    jobId: string;
}

export const ErrorModal = NiceModal.create<ErrorModalProps>((props) => {
    const modal = useModal();
    const { data, isLoading, isFetched } = useJobError({ id: props.jobId });
    const { visible, remove } = modal;

    return (
        <Modal centered onClose={() => remove()} opened={visible} size={1250} title="Error details">
            {isLoading && <MiviaLoader />}
            {isFetched && <pre style={{ fontSize: '0.7rem' }}>{data?.error || ''}</pre>}
        </Modal>
    );
});
