import { z } from 'zod';

/**
 * Password validator:
 *     It must contain at least one uppercase letter.
 *     It must contain at least one lowercase letter.
 *     It must contain either at least one digit or at least one special character.
 *     It must be at least 12 characters long.
 * @param password - password to validate
 * @returns boolean - true if password is valid, false otherwise
 */
export const passwordValidator = (password: string): null | string => {
    const passwordSchema = z.object({
        password: z
            .string()
            .refine((value) => /[A-Z]/.test(value), {
                // message: 'Must contain at least one uppercase letter',
                message: 'auth.passwordValidator.uppercase',
            })
            .refine((value) => /[a-z]/.test(value), {
                // message: 'Must contain at least one lowercase letter',
                message: 'auth.passwordValidator.lowercase',
            })
            .refine((value) => /\d|[!@#$%^&*()_+]/.test(value), {
                // message: 'Must contain at least one digit or special character',
                message: 'auth.passwordValidator.digitOrSpecial',
            })
            .refine((value) => value.length >= 12, {
                // message: 'Must be at least 12 characters long',
                message: 'auth.passwordValidator.length',
            }),
    });
    const result = passwordSchema.safeParse({ password });
    if (result.success) return null;
    return result.error.issues[0].message;
};
