import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { USER_STATS } from '@/types/query-keys';

import { UserStats } from '../types/user-stats';

export const getUserStats = async ({
    userId,
}: {
    userId: string | undefined;
}): Promise<UserStats> => {
    return (await axios.get(`/users/${userId}/stats`)).data;
};

type QueryFnType = typeof getUserStats;

type UseUserStatsOptions = {
    userId: string | undefined;
    config?: QueryConfig<QueryFnType>;
};

export const useUserStats = ({ config, userId }: UseUserStatsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [USER_STATS, userId],
        enabled: !!userId,
        queryFn: () => getUserStats({ userId }),
        ...config,
    });
};
