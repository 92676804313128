import { Rating, Stack, Text, Tooltip } from '@mantine/core';
import { useIntersection } from '@mantine/hooks';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { getImageThumbnailUrl, getImageUrl } from '@/config';
import { useAdminStyles } from '@/features/admin/components/admin-styles';
import { AdminViewableUserJob } from '@/features/admin/types/user-jobs';
import { OverlayImage } from '@/features/history/components/OverlayImage';
import { LinkToModal } from '@/features/models/component/LinkToModal';

interface JobRowProps {
    job: AdminViewableUserJob;
    onClick: (id: string) => void;
    isSelected: boolean;
}

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const createUserRatingComponent = (job: AdminViewableUserJob) => {
    if (!job.userFeedback) {
        return null;
    }

    return (
        <Tooltip label={job.userFeedback.comment} multiline={true}>
            <Rating readOnly value={job.userFeedback.rating} />
        </Tooltip>
    );
};

const createAdminRatingComponent = (job: AdminViewableUserJob) => {
    if (!job.adminFeedback) {
        return null;
    }

    const avgRating =
        job.adminFeedback.length == 0
            ? 0
            : job.adminFeedback.reduce(
                  (acc, f) => acc + (f.results + f.scaleLength + f.scaleNumber + f.resin) / 4.0,
                  0
              ) / job.adminFeedback.length;

    const ratingCount = job.adminFeedback.length;
    return (
        <Stack spacing={0}>
            <Tooltip label={`Rating ${avgRating}`} multiline={true}>
                <Rating fractions={5} readOnly value={avgRating} />
            </Tooltip>
            <Text size="xs">({ratingCount} votes)</Text>
        </Stack>
    );
};

export const JobRow: FC<JobRowProps> = ({ job, onClick, isSelected }) => {
    const { classes } = useAdminStyles();
    const { ref, entry } = useIntersection({
        threshold: 1,
    });

    const timeDiff = dayjs(job.finishedAt).diff(job.createdAt, 'seconds', true);

    const companyLabel = job.owner.address?.company ? `(${job.owner.address.company})` : null;
    const thumbnailUrl = getImageThumbnailUrl(job.image?.id || '');
    const imageUrl = getImageUrl(job.image?.id || '');
    const modelVersion = job.modelInstance?.version || 'outdated';

    const userRating = createUserRatingComponent(job);
    const adminRating = createAdminRatingComponent(job);

    return (
        <tr className={isSelected ? classes.selectedRow : undefined}>
            <td>
                <Link
                    className={classes.link}
                    onClick={() => onClick(job.id)}
                    target="_blank"
                    to={`/admin/jobs/${job.id}`}
                >
                    {dayjs(job.createdAt).format(dateFormat)}
                </Link>
            </td>
            <td>
                {job.owner.name} {job.owner.surname} {companyLabel}
            </td>
            <td>{userRating}</td>
            <td>{adminRating}</td>
            <td>{timeDiff < 0 ? 0 : timeDiff}s</td>
            <td>{job.status}</td>
            <td>
                {job.model?.displayName} ({modelVersion})
            </td>
            <td>
                <LinkToModal caption={job.image?.orginalFilename || ''} imageUrl={imageUrl}>
                    <OverlayImage
                        fit="cover"
                        height={50}
                        imageRef={ref}
                        src={entry?.isIntersecting ? thumbnailUrl : undefined}
                        width={200}
                        withPlaceholder
                    ></OverlayImage>
                </LinkToModal>
            </td>
        </tr>
    );
};
