import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mantine/core';

import { useUpdateExample } from '@/features/admin/api/update-example';
import { ComputeModelExampleForm } from '@/features/admin/components/compute-models/ComputeModelExampleForm';
import { ExampleResultsFormValues } from '@/features/admin/components/compute-models/types/example-results-form-values';
import { ComputeModel } from '@/features/admin/types/compute-model';
import { useExampleResult } from '@/features/models/api/example-result';

interface ComputeModelExampleModalProps {
    model: ComputeModel;
}

export const ComputeModelExampleModal = NiceModal.create<ComputeModelExampleModalProps>((props) => {
    const modal = useModal();
    const { data: exampleResults } = useExampleResult({ modelId: props.model.id });
    const update = useUpdateExample({ modelId: props.model.id });

    const { hide, visible } = modal;

    const handleSubmit = (values: ExampleResultsFormValues) => {
        update.mutate(values);
        hide();
    };

    const handleCancel = () => {
        hide();
    };

    return (
        <Modal
            centered
            onClose={() => hide()}
            opened={visible}
            size={1200}
            title={'Example results'}
        >
            <ComputeModelExampleForm
                initialValues={exampleResults}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
            ></ComputeModelExampleForm>
        </Modal>
    );
});
