import React from 'react';

import { Products } from '@/features/admin/components/stripe/Products';

import { useAdminStyles } from '../components/admin-styles';

export function AdminStripe() {
    const { classes } = useAdminStyles();

    return (
        <div className={classes.container}>
            <Products />
        </div>
    );
}
