import { useModal } from '@ebay/nice-modal-react';
import { Button, Group } from '@mantine/core';
import React, { FC } from 'react';

import { ProductModal } from '@/features/admin/components/stripe/ProductModal';

export const ProductsPanel: FC = () => {
    const dialog = useModal(ProductModal);

    return (
        <Group mt={5} position="right">
            <Button onClick={() => dialog.show()}>Add</Button>
        </Group>
    );
};
