import { ActionIcon, Group, List, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import React, { FC, useMemo } from 'react';

import { useDeleteJobFeedback } from '@/features/admin/api/delete-job-feedback';
import { AdminFeedback } from '@/features/admin/types/user-jobs';

interface JobInfoAdminFeedbackProps {
    jobId: string;
    data?: AdminFeedback[];
}

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

export const JobInfoAdminFeedback: FC<JobInfoAdminFeedbackProps> = ({ data = [], jobId }) => {
    const deleteAdminFeedback = useDeleteJobFeedback({ jobId });

    const formattedData = useMemo(
        () =>
            data.map((feedback) => ({
                ...feedback,
                formattedDate: dayjs(feedback.createdAt).format(dateFormat),
                rating:
                    (feedback.results +
                        feedback.scaleLength +
                        feedback.scaleNumber +
                        feedback.resin) /
                    4.0,
            })),
        [data]
    );

    const handleDeleteFeedback = (id: number) => {
        deleteAdminFeedback.mutate({ id });
    };

    return (
        <List listStyleType="none" spacing={'md'}>
            {formattedData.map((feedback) => (
                <List.Item key={feedback.id} title={`Rating ${feedback.rating}`}>
                    <Group spacing={'md'}>
                        <div style={{ fontSize: 26, fontWeight: 500 }}>
                            {feedback.rating.toFixed(1)}
                        </div>
                        <div>
                            <Group align="center" spacing={8}>
                                <Text fw={500} size="sm">
                                    {feedback.user.name} {feedback.user.surname} (
                                    {feedback.formattedDate})
                                </Text>
                                <ActionIcon
                                    color="red"
                                    onClick={() => handleDeleteFeedback(feedback.id)}
                                    size="xs"
                                    variant="light"
                                >
                                    <IconTrash />
                                </ActionIcon>
                            </Group>
                            <Text size="sm">Scale number: {feedback.scaleNumber}</Text>
                            <Text size="sm">Scale length: {feedback.scaleLength}</Text>
                            <Text size="sm">Resin: {feedback.resin}</Text>
                            <Text size="sm">Analysis results: {feedback.results}</Text>
                            <Text size="xs">Additional comments: {feedback.comment}</Text>
                        </div>
                    </Group>
                </List.Item>
            ))}
        </List>
    );
};
