import { Alert, Button, Group, Input, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FunctionComponent, useEffect } from 'react';

import { TextEditor } from '@/components/TextEditor/TextEditor';
import { ExampleResultsFormValues } from '@/features/admin/components/compute-models/types/example-results-form-values';
import { ExampleResultsType } from '@/features/models/types/example-results-type';

interface ModelInsightsFormProps {
    onSubmit: (values: ExampleResultsFormValues) => void;
    onCancel: () => void;
    initialValues?: ExampleResultsType;
}

export const ComputeModelExampleForm: FunctionComponent<ModelInsightsFormProps> = ({
    onSubmit,
    onCancel,
    initialValues,
}) => {
    const form = useForm<ExampleResultsFormValues>({
        initialValues: {
            contentEn: initialValues?.contentEn || '',
            contentDe: initialValues?.contentDe || '',
        },
        validate: {
            contentEn: (value) => (value.trim().length > 0 ? null : 'Content is required'),
            contentDe: (value) => (value.trim().length > 0 ? null : 'Content is required'),
        },
    });

    useEffect(() => {
        if (initialValues !== undefined) {
            form.setValues({ ...initialValues });
        }
    }, [initialValues]);

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Stack>
                <Alert color="teal" title="Information">
                    For best results, examples should be less then 1220px wide on white background.
                </Alert>

                <Input type="hidden" {...form.getInputProps('id')}></Input>
                <TextEditor
                    {...form.getInputProps('contentEn')}
                    label="Content (English)"
                    required
                ></TextEditor>
                <TextEditor
                    {...form.getInputProps('contentDe')}
                    label="Content (German)"
                    required
                ></TextEditor>

                <Group mt="md" position="right">
                    <Button onClick={() => onCancel()} variant="outline">
                        Close
                    </Button>
                    <Button type="submit">Save</Button>
                </Group>
            </Stack>
        </form>
    );
};
