import * as React from 'react';

import { SvgrComponent } from '@/types/SvgrComponent';

export const FlagGermany: SvgrComponent = (props) => (
    <svg viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0 320h640v160H0z" fill="#ffce00" />
        <path d="M0 0h640v160H0z" />
        <path d="M0 160h640v160H0z" fill="#d00" />
    </svg>
);
