import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mantine/core';
import React from 'react';

import { useCreateInsights } from './api/create-insight';
import { useInsightsDetails } from './api/insight-details';
import { useUpdateInsights } from './api/update-insight';
import { InsightsForm } from './InsightsForm';
import { InsightsFormValues } from './types/insight-form-values';

interface InsightsModalProps {
    insightsId?: number;
}

export const InsightsModal = NiceModal.create<InsightsModalProps>((props) => {
    const modal = useModal();
    const { visible, remove } = modal;

    const { data: insightsDetails } = useInsightsDetails({ id: props.insightsId });
    const update = useUpdateInsights();
    const create = useCreateInsights();

    const handleSubmit = (values: InsightsFormValues) => {
        if (props.insightsId) update.mutate({ id: props.insightsId, data: values });
        else create.mutate(values);
        remove();
    };
    const handleCancel = () => {
        remove();
    };

    return (
        <Modal centered onClose={handleCancel} opened={visible} size={1200} title="Edit insights">
            <InsightsForm
                initialValues={insightsDetails}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
            ></InsightsForm>
        </Modal>
    );
});
