import React from 'react';

import { Translations } from '@/features/admin/components/translations/Translations';

import { useAdminStyles } from '../components/admin-styles';

export function AdminTranslations() {
    const { classes } = useAdminStyles();

    return (
        <div className={classes.container}>
            <Translations />
        </div>
    );
}
