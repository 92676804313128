import { useMutation } from '@tanstack/react-query';

import {
    showNodeStartsFailureNotification,
    showNodeStartsSuccessNotification,
} from '@/features/admin/api/notifications';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { NODES } from '@/types/query-keys';

export interface UseMutationStartNodeDto {
    id: string;
}

const startNode = async (data: UseMutationStartNodeDto): Promise<any> => {
    return (await axios.put(`/admin/nodes/${data.id}/start`)).data;
};

type UseStartNodeOptions = {
    config?: MutationConfig<typeof startNode>;
};

export const useStartNode = ({ config }: UseStartNodeOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: startNode,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([NODES]);
            showNodeStartsSuccessNotification();
        },
        onError: (error: any) => {
            showNodeStartsFailureNotification();
        },
    });
};
