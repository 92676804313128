import { Route, Routes } from 'react-router-dom';

import { AccessKeysPage } from '../component/AccessKeysPage';

export const UserAccessKeysRoutes = () => {
    return (
        <Routes>
            <Route element={<AccessKeysPage />} path="" />
        </Routes>
    );
};
