import { useModal } from '@ebay/nice-modal-react';
import { Button, Group } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { useQueryNodes } from '@/features/admin/api/nodes';
import { AddNodeModal } from '@/features/admin/components/nodes/AddNodeModal';

import { NodesList } from './NodesList';

export const Nodes: FunctionComponent = () => {
    const nodesQuery = useQueryNodes();
    const addNodeModal = useModal(AddNodeModal);

    return (
        <>
            <Group mb={8} position="right">
                <Button onClick={() => addNodeModal.show()}>Add Resource</Button>
            </Group>
            <NodesList nodes={nodesQuery.data || []}></NodesList>
        </>
    );
};
