import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { DOCKER_IMAGES } from '@/types/query-keys';

export interface UseMutationDeleteModelDto {
    imageName: string;
    tag: string;
}

const deleteModel = async (data: UseMutationDeleteModelDto): Promise<any> => {
    return (await axios.delete(`/admin/registry`, { params: data })).data;
};

type UseDeleteModelOptions = {
    config?: MutationConfig<typeof deleteModel>;
    imageName: string;
};

export const useDeleteDockerImage = ({ config, imageName }: UseDeleteModelOptions) => {
    return useMutation({
        ...config,
        mutationFn: deleteModel,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([DOCKER_IMAGES, imageName]);
        },
    });
};
