import { createStyles } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { Layout } from '@/features/auth/component/Layout';
import { Paper } from '@/features/auth/component/Paper';

const useStyles = createStyles((theme, _params) => {
    return {
        link: {
            color: theme.colors['forest'][7],
            textDecoration: 'none',
            fontWeight: 500,
        },
    };
});

export const FullScreenLicenses: FunctionComponent = () => {
    const { classes } = useStyles();
    return (
        <Layout title="Licenses">
            <Paper minWidth={600} px={32} py={0}>
                <div>
                    <h2>Licenses</h2>
                    <p>MiViA uses the following third-party assets:</p>
                    <ul>
                        <li>
                            <a
                                className={classes.link}
                                href="https://www.freepik.com/free-vector/emails-concept-illustration_7119119.htm#query=7119119&position=0&from_view=search"
                                rel="nofollow noopener noreferrer"
                            >
                                Image by storyset
                            </a>{' '}
                            on Freepik,
                        </li>
                        <li>
                            <a
                                className={classes.link}
                                href="https://www.freepik.com/free-vector/my-password-concept-illustration_10594778.htm#query=storyset%20phone%20password&position=2&from_view=search&track=ais"
                                rel="nofollow noopener noreferrer"
                            >
                                Image by storyset
                            </a>{' '}
                            on Freepik.
                        </li>
                        <li>
                            <a
                                className={classes.link}
                                href="https://storyset.com/data"
                                rel="nofollow noopener noreferrer"
                            >
                                Data illustrations by Storyset
                            </a>{' '}
                            on Freepik.
                        </li>
                    </ul>
                </div>
            </Paper>
        </Layout>
    );
};
