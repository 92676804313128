import {z} from "zod";

const barChartDataItem = z.object({
        label: z.string(),
        color: z.string(),
    });

export type BarChartDataItem = z.infer<typeof barChartDataItem>;

export const barChartResult = z.object({
    type: z.literal('bar-chart'),
});

export type BarChartResultType = z.infer<typeof barChartResult> & {
    keys: string[];
    data: BarChartDataItem[];
    xLabel?: string;
    yLabels?: string[];
};
