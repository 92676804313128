import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { INSIGHTS } from '@/types/query-keys';

import { InsightsFormValues } from '../types/insight-form-values';
import { InsightDetailType } from '../types/insight-type';

const updateInsight = async ({
    id,
    data,
}: {
    id: number;
    data: InsightsFormValues;
}): Promise<InsightDetailType> => {
    return (await axios.put(`/admin/insights/${id}`, data)).data;
};

type UseUpdateInsightsOptions = {
    config?: MutationConfig<typeof updateInsight>;
};

export const useUpdateInsights = ({ config }: UseUpdateInsightsOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: updateInsight,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [INSIGHTS] });
            queryClient.invalidateQueries([INSIGHTS]); // TODO: Remove after updating react-query
        },
    });
};
