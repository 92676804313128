import { useModal } from '@ebay/nice-modal-react';
import { Button, Group } from '@mantine/core';
import React, { FC } from 'react';

import { NewsModal } from '@/features/admin/components/news/NewsModal';

export const NewsPanel: FC = () => {
    const dialog = useModal(NewsModal);

    return (
        <Group mt={5} position="right">
            <Button onClick={() => dialog.show()}>Add</Button>
        </Group>
    );
};
