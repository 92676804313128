import { Button, Stack, Text } from '@mantine/core';
import React, { FC } from 'react';

import { MiviaLoader } from '@/components/MiviaLoader';

interface ModelUpdatingLoaderProps {
    onUnlockClick: () => void;
}

export const ModelUpdatingLoader: FC<ModelUpdatingLoaderProps> = ({ onUnlockClick }) => {
    return (
        <Stack m={16} spacing={'sm'}>
            <MiviaLoader />
            <Text align={'center'} m={4}>
                ⚠️ Updating… Please wait.
            </Text>
            <Button onClick={onUnlockClick} variant="outline">
                Unlock process
            </Button>
        </Stack>
    );
};
