import { Table } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { SortableTableHeader } from '@/components/Layout/SortableTableHeader';
import { useAdminStyles } from '@/features/admin/components/admin-styles';
import { LicenseRow } from '@/features/admin/components/licenses/LicenseRow';
import { License, LicenseLimits } from '@/features/admin/types/license';

// TODO: Find smarter way to flatten license limits
export type LicenseRowData = License & {
    [key in keyof LicenseLimits]: LicenseLimits[key];
};

interface VoucherTableProps {
    licenses: License[];
    onSort: (sortBy: keyof LicenseRowData, reverseSort: boolean) => void;
    sortBy: string;
    reverseSortDirection: boolean;
}

export const LicenseTable: FunctionComponent<VoucherTableProps> = ({
    licenses,
    onSort,
    sortBy,
    reverseSortDirection,
}) => {
    const { classes, cx } = useAdminStyles();

    const setSorting = (field: keyof LicenseRowData) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        onSort(field, reversed);
    };

    const rows = licenses.map((license) => <LicenseRow key={license.id} license={license} />);

    return (
        <Table highlightOnHover>
            <thead className={cx(classes.header)}>
                <tr>
                    <SortableTableHeader
                        onSort={() => setSorting('name')}
                        reversed={reverseSortDirection}
                        sorted={sortBy === 'name'}
                        width={250}
                    >
                        User name
                    </SortableTableHeader>
                    <SortableTableHeader
                        onSort={() => setSorting('createdAt')}
                        reversed={reverseSortDirection}
                        sorted={sortBy === 'createdAt'}
                        width={250}
                    >
                        Created at
                    </SortableTableHeader>
                    <SortableTableHeader
                        onSort={() => setSorting('type')}
                        reversed={reverseSortDirection}
                        sorted={sortBy === 'type'}
                        width={250}
                    >
                        Type
                    </SortableTableHeader>
                    <SortableTableHeader
                        onSort={() => setSorting('used')}
                        reversed={reverseSortDirection}
                        sorted={sortBy === 'used'}
                        width={250}
                    >
                        Limit images
                    </SortableTableHeader>
                    <SortableTableHeader
                        onSort={() => setSorting('finish')}
                        reversed={reverseSortDirection}
                        sorted={sortBy === 'finish'}
                        width={250}
                    >
                        Limit time
                    </SortableTableHeader>
                    <SortableTableHeader
                        onSort={() => setSorting('createdBy')}
                        reversed={reverseSortDirection}
                        sorted={sortBy === 'createdBy'}
                        width={250}
                    >
                        Created by
                    </SortableTableHeader>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
};
