import { Input } from '@mantine/core';
import { usePrevious } from '@mantine/hooks';
import { Link, RichTextEditor } from '@mantine/tiptap';
import { IconPhotoCircle } from '@tabler/icons-react';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { Extension, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { FC, useEffect } from 'react';

import ResizableImageExtension from '@/components/TextEditor/ResizableImage';
import { TextEditorImage } from '@/components/TextEditor/TextEditorImage';
import { TextEditorTable } from '@/components/TextEditor/TextEditorTable';

interface TextEditorProps {
    value?: string;
    onChange?: (value: string) => void;
    onKeyboardShortcut?: () => void;
    label?: string;
    required?: boolean;
    error?: string;
}

export const TextEditor: FC<TextEditorProps> = (props) => {
    const { value, onChange, onKeyboardShortcut, label, required, error } = props;

    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
            Extension.create({
                name: 'saveShortcuts',
                addKeyboardShortcuts() {
                    return {
                        'Ctrl-s'() {
                            onKeyboardShortcut?.();
                            return true;
                        },
                    };
                },
            }),
            ResizableImageExtension.configure({
                inline: true,
            }),
            Table.configure({
                resizable: true,
            }),
            TableRow,
            TableHeader,
            TableCell,
        ],
        content: value,
        onUpdate({ editor }) {
            if (onChange) onChange(editor.getHTML());
        },
    });

    const previousValue = usePrevious(value);

    useEffect(() => {
        if (!previousValue && value) {
            editor?.commands.setContent(value);
        }
    }, [editor, previousValue, value]);

    return (
        <Input.Wrapper defaultValue={value} error={error} label={label} required={required}>
            <RichTextEditor editor={editor}>
                <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                        <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.H1 />
                        <RichTextEditor.H2 />
                        <RichTextEditor.H3 />
                        <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Hr />
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignJustify />
                        <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Link />
                        <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>
                    <RichTextEditor.ControlsGroup>
                        <TextEditorImage />
                    </RichTextEditor.ControlsGroup>

                    {/*<RichTextEditor.ControlsGroup>*/}
                    {/*    <TextEditorTable />*/}
                    {/*</RichTextEditor.ControlsGroup>*/}
                </RichTextEditor.Toolbar>

                <RichTextEditor.Content />
            </RichTextEditor>
        </Input.Wrapper>
    );
};
