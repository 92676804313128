import { useModal } from '@ebay/nice-modal-react';
import { Center, Loader } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { SortableTableHeader } from '@/components/Layout/SortableTableHeader';
import { ScrollableTable } from '@/components/ScrollableTable';
import { useActivateUser } from '@/features/admin/api/activate-user';
import { useAssignRole } from '@/features/admin/api/assign-role';
import { useChangeUserPrefs } from '@/features/admin/api/change-user-prefs';
import { useDeleteUser } from '@/features/admin/api/delete-user';
import { useImpersonateUser } from '@/features/admin/api/impersonate-user';
import { useAdminStyles } from '@/features/admin/components/admin-styles';
import { LicenseModal } from '@/features/admin/components/LicenseModal';
import { ConfirmUserDeleteDialog } from '@/features/admin/components/users/ConfirmUserDeleteDialog';
import { UsersTableRow } from '@/features/admin/components/users/UsersTableRow';
import { UserAccount } from '@/features/admin/types/user-account';

export type UserRowData = UserAccount & {
    company: string;
    trainingConsent: boolean;
};

interface UsersTableProps {
    data: UserAccount[] | undefined;
    onSort: (sortBy: keyof UserRowData, reverseSort: boolean) => void;
    sortBy: keyof UserRowData;
    reverseSortDirection: boolean;
}

interface UserTableHeader {
    fieldName: keyof UserRowData;
    label: string;
    width?: number;
}

const userTableHeaders: UserTableHeader[] = [
    { fieldName: 'surname', label: 'Name', width: 250 },
    { fieldName: 'company', label: 'Company' },
    { fieldName: 'license', label: 'License' },
    { fieldName: 'role', label: 'Role', width: 50 },
    { fieldName: 'trainingConsent', label: 'Training', width: 120 },
    { fieldName: 'timeJoined', label: 'Joined', width: 150 },
    { fieldName: 'lastLogin', label: 'Last activity', width: 150 },
];

export const UsersTable: FunctionComponent<UsersTableProps> = ({
    onSort,
    sortBy,
    reverseSortDirection,
    data,
}) => {
    const { classes, cx } = useAdminStyles();
    const confirmDeleteDialog = useModal(ConfirmUserDeleteDialog);
    const licenseModal = useModal(LicenseModal);

    const deleteUser = useDeleteUser();
    const assignRole = useAssignRole();
    const activateUser = useActivateUser();
    const changePrefs = useChangeUserPrefs();
    const impersonateUser = useImpersonateUser();

    const handleAssignRole = (userId: string, role: string) => {
        assignRole.mutate({ userId, role });
    };

    const setSorting = (field: keyof UserRowData) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        onSort(field, reversed);
    };

    const handleDeleteUser = (user: UserAccount) => {
        if (!user) return;

        confirmDeleteDialog.show({ userName: user.name }).then((confirmed) => {
            if (confirmed) {
                deleteUser.mutate(user.id);
            }
        });
    };

    const handleActivateUser = (userId: string) => {
        activateUser.mutate(userId);
    };

    const handleAssignLicense = (userId: string) => {
        licenseModal.show({ userId });
    };

    const handleAllowPermanentDataDeletion = (userId: string, value: boolean) => {
        changePrefs.mutate({ userId, removeDataPermanently: value });
    };

    const handleImpersonate = (userId: string) => {
        impersonateUser.mutate(userId);
    };

    const rows = data?.map((user) => (
        <UsersTableRow
            key={user.id}
            onActivate={handleActivateUser}
            onAllowPermanentDataDeletion={handleAllowPermanentDataDeletion}
            onAssignLicense={handleAssignLicense}
            onAssignRole={handleAssignRole}
            onDelete={handleDeleteUser}
            onImpersonate={handleImpersonate}
            user={user}
        />
    ));

    if (!data) {
        return (
            <Center>
                <Loader size="xl" variant="dots" />
            </Center>
        );
    }

    return (
        <ScrollableTable deltaHeight={145}>
            <thead className={cx(classes.header)}>
                <tr>
                    <th style={{ width: 50 }}></th>

                    {userTableHeaders.map((header) => (
                        <SortableTableHeader
                            key={header.fieldName}
                            onSort={() => setSorting(header.fieldName)}
                            reversed={reverseSortDirection}
                            sorted={sortBy === header.fieldName}
                            width={header.width}
                        >
                            {header.label}
                        </SortableTableHeader>
                    ))}

                    <th style={{ width: 50 }}>Action</th>
                </tr>
            </thead>
            <tbody data-cy="admin-panel-user-section-table-rows">{rows}</tbody>
        </ScrollableTable>
    );
};
