import { Stack } from '@mantine/core';
import React, { FC } from 'react';

import { NewsPanel } from '@/features/admin/components/news/NewsPanel';
import { NewsTable } from '@/features/admin/components/news/NewsTable';

export const News: FC = () => {
    return (
        <Stack>
            <NewsPanel></NewsPanel>
            <NewsTable></NewsTable>
        </Stack>
    );
};
