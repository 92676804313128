import { useModal } from '@ebay/nice-modal-react';
import { ActionIcon } from '@mantine/core';
import { IconArrowsMaximize } from '@tabler/icons-react';
import { BarChartResultType } from 'mivia-shared';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BarChart } from '@/features/models/component/BarChart';
import { BarChartModal } from '@/features/models/component/BarChartModal';
import { useChartStyles } from '@/features/models/component/useChartStyles';

type BarChartWrapperProps = Omit<BarChartResultType, 'type'>;

export const BarChartWrapper: FunctionComponent<BarChartWrapperProps> = ({
    xLabel,
    yLabels,
    data,
    keys,
}) => {
    const [key, setKey] = useState<string>('fraction');
    const { t } = useTranslation();
    const barChartModal = useModal(BarChartModal);
    const { classes } = useChartStyles();

    const xLabelTranslated = xLabel ? t(`model.${xLabel}`) : '';
    const dataTranslated = data.map((item) => ({ ...item, label: t(`model.${item.label}`) }));

    // Assign yLabel based on key, translated
    let yLabelTranslated = '';
    const yLabelIndex = keys.indexOf(key);
    if (yLabelIndex >= 0) {
        const yLabel = yLabels?.[yLabelIndex];
        yLabelTranslated = yLabel ? t(`model.${yLabel}`) : '';
    }

    return (
        <div className={classes.wrapper}>
            <BarChart
                activeKey={'fraction'}
                data={dataTranslated}
                xLabel={xLabelTranslated}
                yLabel={yLabelTranslated}
            />
            <ActionIcon
                className={classes.icon}
                onClick={() =>
                    barChartModal.show({
                        data: dataTranslated,
                        activeKey: key,
                        xLabel: xLabelTranslated,
                        yLabel: yLabelTranslated,
                    })
                }
            >
                <IconArrowsMaximize size={20} />
            </ActionIcon>
            {/*<BarChartSelectKey className={classes.select} keys={keys} onSelect={setKey} />*/}
        </div>
    );
};
