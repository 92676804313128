import * as React from 'react';

import { SvgrComponent } from '@/types/SvgrComponent';

export const FlagGb: SvgrComponent = (props) => (
    <svg viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0 0h640v480H0z" fill="#012169" />
        <path
            d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"
            fill="#FFF"
        />
        <path
            d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"
            fill="#C8102E"
        />
        <path d="M241 0v480h160V0H241zM0 160v160h640V160H0z" fill="#FFF" />
        <path d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z" fill="#C8102E" />
    </svg>
);
