import { createStyles, Paper, PaperProps, Title } from '@mantine/core';
import React, { FunctionComponent } from 'react';

const useStyles = createStyles((theme) => ({
    title: {
        color: theme.colors.stone[7],
        fontWeight: 600,
    },

    paper: {
        marginTop: theme.spacing.sm,
    },

    captionBar: {
        display: 'flex',
        borderBottom: '1px solid #ccc',
        paddingBottom: theme.spacing.sm,
        marginBottom: theme.spacing.sm,
    },

    fillWidth: {
        flexGrow: 1,
    },
}));

interface PanelProps extends PaperProps {
    showCaption?: boolean;
    title?: string | React.ReactNode;
    extra?: React.ReactNode;
    extraPosition?: 'left' | 'right';
    className?: string | undefined;
    children: React.ReactNode;
    height?: number;
    style?: React.CSSProperties;
}

export const Panel: FunctionComponent<PanelProps> = (props: PanelProps) => {
    const {
        children,
        title,
        extra,
        className,
        height,
        style,
        extraPosition = 'right',
        showCaption = true,
    } = props;
    const { classes, cx } = useStyles();
    return (
        <Paper
            className={cx(classes.paper, className)}
            p="md"
            radius="sm"
            style={{
                height: height,
                ...style,
            }}
            withBorder
        >
            {showCaption && (
                <div className={classes.captionBar}>
                    <Title className={classes.title} order={4}>
                        {title}
                    </Title>
                    <div className={extraPosition == 'right' ? classes.fillWidth : undefined} />
                    {extra}
                </div>
            )}

            {children}
        </Paper>
    );
};
