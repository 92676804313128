import { Badge } from '@mantine/core';

import { NodeStatus } from '@/features/admin/types/nodeType';

export const NodeStatusBadge = ({ status }: { status: NodeStatus }) => {
    switch (status) {
        case 'ONLINE':
            return <Badge color="green">Online</Badge>;
        case 'OFFLINE':
            return <Badge color="red">Offline</Badge>;
        default:
            return <Badge color="gray">Unknown</Badge>;
    }
};
