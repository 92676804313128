import {HistogramDataItem} from "../types/histogramOutput";

/**
 * Round a number to a specified number of decimal places
 * @param value The number to be rounded
 * @param places The number of decimal places (default is 1)
 */
export const roundNumber = (value: number, places: number = 1): string => {
    const multiplier = Math.pow(10, places);
    return String(Math.round(value * multiplier) / multiplier);
};

/**
 * Convert a tuple of two numbers to a range string
 * @param value A tuple of two numbers
 */
export const tupleToRangeString = (value: [number, number]): string => {
    return `${roundNumber(value[0])}-${roundNumber(value[1])}`;
};

/**
 * Format the value for a tick mark
 * @param value The tick value, either a number or an array of numbers
 */
export const tickFormat = (value: number | number[]): string => {
    if (typeof value === 'number') return roundNumber(value);
    else if (Array.isArray(value) && value.length === 2) return tupleToRangeString(value as [number, number]);
    return String(value);
};

/**
 * Check if the given value is a number
 * @param value The value to check
 */
const isNumber = (value: any): value is number => {
    return typeof value === 'number';
}

type HistogramStringDataItem = HistogramDataItem & {x: string};

/**
 * Convert an array of x-axis values to an array of strings
 * @param data HistogramDataItem[] An array of x-axis values
 */
export const mapXAxisToString = (data: HistogramDataItem[]): HistogramStringDataItem[] => {
    return data.map((v) => {
        if (isNumber(v.x)) return {...v, x: roundNumber(v.x)};
        else if (Array.isArray(v.x) && v.x.length === 2) return {...v, x: tupleToRangeString(v.x as [number, number])};
        return {...v, x: String(v.x)};
    });
}
