import { Select } from '@mantine/core';
import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { YesNoBadge } from '@/components/YesNoBadge';
import { useAdminStyles } from '@/features/admin/components/admin-styles';
import { UserAvatar } from '@/features/admin/components/users/UserAvatar';
import { UserAdminButtonMenu } from '@/features/admin/components/users/UserButtonMenu';
import { getLicenseName } from '@/features/admin/types/license';
import { UserAccount } from '@/features/admin/types/user-account';

interface UsersTableRowProps {
    user: UserAccount;
    onActivate: (userId: string) => void;
    onDelete: (user: UserAccount) => void;
    onAssignRole: (userId: string, role: string) => void;
    onAssignLicense: (userId: string) => void;
    onAllowPermanentDataDeletion: (userId: string, value: boolean) => void;
    onImpersonate: (userId: string) => void;
}

const dateFormat = 'DD/MM/YYYY';
const rolesData = [
    { value: 'BLOCKED', label: 'Blocked' },
    { value: 'USER', label: 'User' },
    { value: 'ADMIN', label: 'Administrator' },
];

export const UsersTableRow: FunctionComponent<UsersTableRowProps> = ({
    user,
    onActivate,
    onDelete,
    onAssignLicense,
    onAssignRole,
    onAllowPermanentDataDeletion,
    onImpersonate,
}) => {
    const { classes } = useAdminStyles();

    const permDel = user.preferences.removeDataPermanently;
    const notVerified = !user.verified;

    let rowClassName = undefined;
    if (permDel) {
        rowClassName = classes.userWithPermanentDeletionRow;
    } else if (notVerified) {
        rowClassName = classes.userNotConfirmedRow;
    }

    return (
        <tr className={rowClassName}>
            <td>
                <UserAvatar user={user} />
            </td>
            <td>
                <Link className={classes.link} to={`/admin/users/${user.id}/profile`}>
                    {user.name} {user.surname}
                </Link>
            </td>

            <td>{user.address?.company}</td>
            <td>{getLicenseName(user.license)}</td>
            <td>
                <Select
                    data={rolesData}
                    defaultValue={user.role}
                    onChange={(role: string) => onAssignRole(user.id, role)}
                    style={{ width: 150 }}
                    variant="unstyled"
                    withinPortal={false}
                />
            </td>
            <td>
                <YesNoBadge value={user.preferences.trainingConsent} />
            </td>
            <td>{dayjs(user.timeJoined).format(dateFormat)}</td>
            <td>{user.lastLogin ? dayjs(user.lastLogin).format(dateFormat) : '-'}</td>
            <td data-cy="admin-panel-user-section-table-rows-button">
                <UserAdminButtonMenu
                    onActivateClick={onActivate}
                    onAllowPermanentDataDeletion={onAllowPermanentDataDeletion}
                    onAssignLicenseClick={onAssignLicense}
                    onDeleteClick={onDelete}
                    onImpersonateClick={onImpersonate}
                    user={user}
                />
            </td>
        </tr>
    );
};
