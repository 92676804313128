import { useQuery } from '@tanstack/react-query';

import {
    parseUserAccessKey,
    UserAccessKeyElement,
} from '@/features/user-access-keys/types/UserAccessKey';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ACCESS_KEYS } from '@/types/query-keys';

export const getAccessKeys = async (): Promise<UserAccessKeyElement[]> => {
    const data = (await axios.get('access-keys')).data;
    return parseUserAccessKey(data);
};

type QueryFnType = typeof getAccessKeys;

type UseAccessKeysOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useAccessKeys = ({ config }: UseAccessKeysOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [ACCESS_KEYS],
        queryFn: getAccessKeys,
        ...config,
    });
};
