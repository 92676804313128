import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { AuthUser } from '@/types/auth-user';
import { CURRENT_USER } from '@/types/query-keys';

export const myInfo = async (): Promise<AuthUser> => {
    return (await axios.get('/users/me')).data;
};

type QueryFnType = typeof myInfo;

type UseQueryUserInfoOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useUserInfo = ({ config }: UseQueryUserInfoOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [CURRENT_USER],
        queryFn: myInfo,
        ...config,
    });
};
