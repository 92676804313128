import { ActionIcon, Group, Tooltip } from '@mantine/core';
import { IconInfoSmall, IconPencil, IconPhotoCheck, IconTrash } from '@tabler/icons-react';
import React, { FC } from 'react';

import { ComputeModel } from '@/features/admin/types/compute-model';

interface ComputeModelsRowProps {
    model: ComputeModel;
    onDelete: (item: ComputeModel) => void;
    onEdit: (item: ComputeModel) => void;
    onInfo: (item: ComputeModel) => void;
    onExample: (item: ComputeModel) => void;
}

export const ComputeModelsRow: FC<ComputeModelsRowProps> = ({
    model,
    onDelete,
    onEdit,
    onInfo,
    onExample,
}) => {
    return (
        <tr>
            <td>{model.displayName}</td>
            <td>{model.dockerImage}</td>
            <td>{model.accessType}</td>
            <td>{model.stats.lastWeekCount}</td>
            <td>{model.stats.lastMonthCount}</td>
            <td>{model.stats.totalCount}</td>
            <td>{model.trainable?.count || 0}</td>
            <td style={{ display: 'flex', justifyContent: 'end' }}>
                <Group spacing={0}>
                    <ActionIcon onClick={() => onEdit(model)} radius="xl">
                        <IconPencil size={16}></IconPencil>
                    </ActionIcon>
                    <Tooltip label={'User manual'}>
                        <ActionIcon onClick={() => onInfo(model)} radius="xl">
                            <IconInfoSmall size={40}></IconInfoSmall>
                        </ActionIcon>
                    </Tooltip>
                    <Tooltip label={'Example results'}>
                        <ActionIcon onClick={() => onExample(model)} radius="xl">
                            <IconPhotoCheck size={16}></IconPhotoCheck>
                        </ActionIcon>
                    </Tooltip>
                    <ActionIcon color="red" onClick={() => onDelete(model)} radius="xl">
                        <IconTrash size={16}></IconTrash>
                    </ActionIcon>
                </Group>
            </td>
        </tr>
    );
};
