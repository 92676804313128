import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Dialog, Group, MantineColor, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

interface ConfirmDialogProps {
    questionText: ReactNode;
    colorsTheme?: MantineColor;
}

export const ConfirmDialog = NiceModal.create<ConfirmDialogProps>(
    ({ questionText, colorsTheme }) => {
        const { remove, visible, resolve } = useModal();

        return (
            <Dialog onClose={() => remove()} opened={visible} radius="md" size="lg" withBorder>
                <Text size="sm" style={{ marginBottom: 10 }}>
                    {questionText}
                </Text>

                <Group position="right">
                    <Button
                        color={colorsTheme}
                        onClick={() => {
                            resolve(true);
                            remove();
                        }}
                    >
                        Yes
                    </Button>
                    <Button color={colorsTheme} onClick={() => remove()} variant="outline">
                        No
                    </Button>
                </Group>
            </Dialog>
        );
    }
);
