import { Button, createStyles, Paper, Text, Title } from '@mantine/core';
import { IconArrowNarrowRight, IconMail } from '@tabler/icons-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { LicenseType } from '@/features/admin/components/stripe/types/license-product';
import { makeStripeLink } from '@/features/user-license/api/make-link';
import type { LicenseProductType } from '@/features/user-license/types/license-product';

export type LicenseBlockProps = {
    license: LicenseProductType;
};

const oneLineTitle = (label1: string) => (
    <Title align="center" order={1}>
        {label1}
    </Title>
);

const twoLineTitle = (label1: string, label2: string) => (
    <>
        <Title align="center" order={1}>
            {label1}
        </Title>
        <Text
            align="center"
            size="xl"
            sx={(theme) => ({
                color: theme.colors.gray[9],
            })}
            weight={500}
        >
            {label2}
        </Text>
    </>
);

const useStyles = createStyles((theme, _params, getRef) => ({
    feature: {
        textAlign: 'left',
        minHeight: 100,
        marginTop: theme.spacing.sm,
        marginBottom: theme.spacing.sm,
    },
}));

const getTranslatedLicense = (license: LicenseProductType, lang: string) => {
    const label = lang === 'en' ? license.titleEn : license.titleDe;
    const description = lang === 'en' ? license.descriptionEn : license.descriptionDe;
    const price = lang === 'en' ? license.priceEn : license.priceDe;
    const features = lang === 'en' ? license.featuresEn : license.featuresDe;
    const canBuy = license.licenseType !== LicenseType.NONE;
    return { label, description, price, features, canBuy };
};

export const LicenseBlock: FunctionComponent<LicenseBlockProps> = ({ license }) => {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { classes } = useStyles();

    const lang = i18n.language;
    const { label, description, price, features, canBuy } = getTranslatedLicense(license, lang);

    const titleText = description ? twoLineTitle(label, description) : oneLineTitle(label);
    const handleStripeNavigate = async () => {
        setIsLoading(true);
        const { url } = await makeStripeLink({ licenseId: license.id });
        setIsLoading(false);
        window.location.href = url;
    };

    return (
        <Paper
            maw={'23.5%'}
            miw={'23.5%'}
            p="xl"
            shadow="xs"
            style={{ display: 'flex', flexDirection: 'column' }}
            withBorder
        >
            <div style={{ wordBreak: 'break-all' }}>
                {titleText}
                <Title align="center" order={2}>
                    {price}
                </Title>
            </div>
            <div
                className={classes.feature}
                dangerouslySetInnerHTML={{ __html: features ?? '' }}
                style={{ flexGrow: 1 }}
            ></div>

            <div>
                {canBuy ? (
                    <Button
                        fullWidth
                        loading={isLoading}
                        onClick={() => handleStripeNavigate()}
                        rightIcon={<IconArrowNarrowRight size={16} />}
                    >
                        {t('purchase.buy')}
                    </Button>
                ) : (
                    <Button
                        component={'a'}
                        fullWidth
                        href={`mailto:info@mivia.ai?subject=${t('purchase.request')}`}
                        leftIcon={<IconMail size={16} />}
                        onClick={() => handleStripeNavigate()}
                    >
                        {t('purchase.contact')}
                    </Button>
                )}
            </div>
        </Paper>
    );
};
