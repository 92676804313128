import { useQuery } from '@tanstack/react-query';
import qs from 'qs';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { USERS } from '@/types/query-keys';
import { SortOrder } from '@/types/sort-order';

import { UserAccount } from '../types/user-account';

export const getUsers = async (
    sortBy?: string,
    sortOrder?: SortOrder,
    search?: string
): Promise<UserAccount[]> => {
    const query = qs.stringify({ search, sortBy, sortOrder });
    return (await axios.get(`/admin/users?${query}`))?.data;
};

type QueryFnType = typeof getUsers;

type UseProfileOptions = {
    search?: string;
    sortBy?: string;
    sortOrder?: SortOrder;
    config?: QueryConfig<QueryFnType>;
};

export const useQueryUsers = ({ config, search, sortBy, sortOrder }: UseProfileOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [USERS, sortBy, sortOrder, search],
        queryFn: () => getUsers(sortBy, sortOrder, search),
        ...config,
    });
};
