import { Button, Dialog, Group, Text } from '@mantine/core';
import { addYears } from 'date-fns';
import React, { FC, ReactNode, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

const COOKIE_CONSENT = 'auth-cookie-consent';
export const CookieConsent: FC = ({ children }: { children?: ReactNode }) => {
    const [cookies, setCookie] = useCookies([COOKIE_CONSENT]);
    const [opened, setOpened] = useState(() => !cookies[COOKIE_CONSENT]);
    const { t } = useTranslation();

    const handleCookieConsent = () => {
        setOpened(false);
        setCookie(COOKIE_CONSENT, true, {
            path: '/',
            expires: addYears(Date.now(), 10),
        });
    };

    return (
        <Dialog
            opened={opened}
            position={{ bottom: 15, left: 15 }}
            radius="md"
            shadow="xl"
            size={500}
            withBorder={true}
        >
            <Text mb="xs" weight={500}>
                {t('cookies.title')}
            </Text>
            <Text mb="xs" size="sm">
                {t('cookies.dialog')}
            </Text>

            <Group align="flex-end" position="right">
                <Button data-cy={'cookie-understood-button'} onClick={() => handleCookieConsent()}>
                    {t('cookies.understood')}
                </Button>
            </Group>
        </Dialog>
    );
};
