import {z} from "zod";

export const histogramResult = z.object({
    type: z.literal('histogram'),
});

const histogramDataItem = z.object({
        x: z.union([z.number(), z.string(), z.tuple([z.number(), z.number()])]),    // allow for both single and range values, 0.1 and [0.1, 0.2]
        y: z.number(),
    color: z.string()
    }
);

export type HistogramDataItem = z.infer<typeof histogramDataItem>;

export type HistogramResultType = z.infer<typeof histogramResult> & {
    label?: string;
    data: HistogramDataItem[]
    xLabel?: string;
    yLabel?: string;
}
