import { Button, Group, Select, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { RichTextEditor, Link } from '@mantine/tiptap';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { FunctionComponent, useEffect } from 'react';

import { TextEditor } from '@/components/TextEditor/TextEditor';
import { useManual } from '@/features/admin/api/manual';
import { UpdateComputeModelManualDto } from '@/features/admin/types/manual';

interface ComputeModelInfoFormProps {
    onSubmit: (values: UpdateComputeModelManualDto) => void;
    onCancel: () => void;
    modelId: string;
}

export const ComputeModelInfoForm: FunctionComponent<ComputeModelInfoFormProps> = ({
    onSubmit,
    onCancel,
    modelId,
}) => {
    const form = useForm<UpdateComputeModelManualDto>({
        initialValues: {
            text: '',
            position: 'TOP',
            language: 'en',
        },
    });
    const { data: initText } = useManual({
        id: modelId,
        position: form.values.position,
        language: form.values.language,
    });

    useEffect(() => {
        form.setValues({ text: initText });
    }, [initText]);

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Stack>
                <Group>
                    <Select
                        data={[
                            { value: 'TOP', label: 'Model info' },
                            { value: 'BOTTOM', label: 'Result info' },
                        ]}
                        {...form.getInputProps('position')}
                    />
                    <Select
                        data={[
                            { value: 'en', label: 'English' },
                            { value: 'de', label: 'German' },
                        ]}
                        {...form.getInputProps('language')}
                    />
                </Group>

                <TextEditor {...form.getInputProps('text')}></TextEditor>

                <Group mt="md" position="right">
                    <Button onClick={() => onCancel()} variant="outline">
                        Close
                    </Button>
                    <Button type="submit">Save</Button>
                </Group>
            </Stack>
        </form>
    );
};
