import { useQuery } from '@tanstack/react-query';

import { License } from '@/features/admin/types/license';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { LICENSES } from '@/types/query-keys';

export const getLicenses = async (
    sortBy: string,
    sortOrder: 'asc' | 'desc'
): Promise<License[]> => {
    const params: any = { sortBy, sortOrder };

    return (
        await axios.get('/admin/licenses', {
            params,
        })
    )?.data;
};

type QueryFnType = typeof getLicenses;

type UseLicensesOptions = {
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
    config?: QueryConfig<QueryFnType>;
};

export const useLicenses = ({
    config,
    sortBy = 'createdAt',
    sortOrder = 'desc',
}: UseLicensesOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [LICENSES, sortBy, sortOrder],
        queryFn: () => getLicenses(sortBy, sortOrder),
        ...config,
    });
};
