import { Accordion, AccordionControlProps, Box, Group } from '@mantine/core';
import React from 'react';

import { ModelFeedbackIcon } from '@/features/models/component/ModelFeedbackIcon';
import { ResultCheckbox } from '@/features/models/component/ResultCheckbox';
import { UserVoteIcon } from '@/features/models/component/UserVoteIcon';
import { UserJobType } from '@/features/models/types/userJobType';

interface AccordionControlJobProps extends AccordionControlProps {
    job: UserJobType;
    selectable?: boolean;
    hideControls?: boolean;
}

export const AccordionControl = (props: AccordionControlJobProps) => {
    const job = props.job;
    const feedback = props.job.result?.feedback;
    const { selectable, hideControls, ...rest } = props;
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Accordion.Control {...rest} />
            {!hideControls && (
                <Group noWrap spacing="sm">
                    <UserVoteIcon feedback={job.userFeedback} jobId={job.id} />
                    {feedback && <ModelFeedbackIcon feedback={feedback} />}
                    {selectable && <ResultCheckbox id={job.id}></ResultCheckbox>}
                </Group>
            )}
        </Box>
    );
};
