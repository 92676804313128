import { useModal } from '@ebay/nice-modal-react';
import { Button, Group, Text } from '@mantine/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from '@/components/Panel';
import { useOtpStatus } from '@/features/user-profile/api/otp-status';
import { TwoFactorModal } from '@/features/user-profile/component/TwoFactorModal';

export const TwoFactorPanel: FC = () => {
    const { t } = useTranslation();
    const twoFactorModal = useModal(TwoFactorModal);
    const { data: isEnabled } = useOtpStatus();

    return (
        <Panel showCaption={false}>
            <Group position="apart" px={8}>
                <Text>{t('otp.twoFactorAuth')}</Text>

                {isEnabled ? (
                    <Button disabled={true}>{t('common.enabled')}</Button>
                ) : (
                    <Button onClick={() => twoFactorModal.show()}>{t('common.enable')}</Button>
                )}
            </Group>
        </Panel>
    );
};
