import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mantine/core';
import React from 'react';

import { useCreateNode } from '@/features/admin/api/create-node';
import { AddNodeForm, AddNodeFormValues } from '@/features/admin/components/nodes/AddNodeForm';

export const AddNodeModal = NiceModal.create((props) => {
    const modal = useModal();
    const createNode = useCreateNode();

    const { hide, visible } = modal;

    const handleOnSubmit = (values: AddNodeFormValues) => {
        createNode.mutate(values);
        hide();
    };

    return (
        <Modal centered onClose={() => hide()} opened={visible} title="Add Computation Resource">
            <AddNodeForm onCancel={() => hide()} onSubmit={handleOnSubmit} />
        </Modal>
    );
});
