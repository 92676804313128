import { Paper as MantinePaper, PaperProps } from '@mantine/core';
import React, { FunctionComponent } from 'react';

interface TPaperProps extends PaperProps {
    minWidth?: number;
}

export const Paper: FunctionComponent<TPaperProps> = ({ minWidth, ...props }) => {
    return (
        <MantinePaper
            p="xl"
            radius="md"
            sx={(theme) => ({
                minWidth,
            })}
            withBorder
            {...props}
        >
            {props.children}
        </MantinePaper>
    );
};
