import { Checkbox, CheckboxProps } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { SelectedResultsContext } from '@/features/models/component/ResultListContext';

interface ResultCheckboxProps extends CheckboxProps {
    id: string;
    disabled?: boolean;
}

export const ResultCheckbox: FunctionComponent<ResultCheckboxProps> = ({
    label,
    id,
    disabled = false,
}) => {
    const { toggleSelected, selected } = React.useContext(SelectedResultsContext);

    return (
        <Checkbox
            checked={selected.find((items) => items == id) != null}
            disabled={disabled}
            onChange={(e) => toggleSelected(id)}
            onClick={(e) => e.stopPropagation()}
        ></Checkbox>
    );
};
