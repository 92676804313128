import { Table, Tooltip } from '@mantine/core';
import { TFunction } from 'i18next';
import { DataTableDataItem, DataTableResultType } from 'mivia-shared';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface DataTableProps {
    results: DataTableResultType;
}

interface DataTableRowProps {
    item: DataTableDataItem;
    t: TFunction;
}

const roundToTwoDigits = (value: number): string => {
    // Round the number to two decimal places
    const rounded = Math.round(value * 100) / 100;
    // Convert to string and remove trailing zeros
    return rounded
        .toString()
        .replace(/(\.\d*?[1-9])0+$/, '$1')
        .replace(/\.$/, '');
};

const DataRow: FC<DataTableRowProps> = ({ item, t }) => {
    const hasTooltip = Boolean(item.labelTooltip && item.labelTooltip.length > 0);
    const label = t(`model.${item.label}`);
    const value = roundToTwoDigits(item.value);

    return hasTooltip ? (
        <Tooltip
            key={item.label}
            label={t(`model.${item.labelTooltip}`)}
            multiline
            position="top"
            width={300}
            withArrow
        >
            <tr>
                <td>
                    {label}
                    <span style={{ color: '#ab0000' }}>*</span>
                </td>
                <td>{value}</td>
            </tr>
        </Tooltip>
    ) : (
        <tr key={item.label}>
            <td>{label}</td>
            <td>{value}</td>
        </tr>
    );
};

export const DataTable = ({ results }: DataTableProps) => {
    const { t } = useTranslation();

    const rows = results.data.map((item) => <DataRow item={item} key={item.label} t={t} />);

    return (
        <div className="dataTableContainer">
            <Table striped withBorder>
                <tbody>{rows}</tbody>
            </Table>
        </div>
    );
};
