import { useMutation } from '@tanstack/react-query';

import { showModelManualUpdateSuccess } from '@/features/admin/api/notifications';
import { UpdateComputeModelManualDto } from '@/features/admin/types/manual';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { MODEL_MANUAL } from '@/types/query-keys';

const updateManualWrapper = (id: string) => {
    return async (data: UpdateComputeModelManualDto): Promise<any> => {
        return (await axios.put(`/admin/compute-models/${id}/manual`, data)).data;
    };
};

type UpdateManualType = ReturnType<typeof updateManualWrapper>;

type UseSaveManualOptions = {
    config?: MutationConfig<UpdateManualType>;
    modelId: string;
};

export const useUpdateManual = ({ config, modelId }: UseSaveManualOptions) => {
    return useMutation({
        ...config,
        mutationFn: updateManualWrapper(modelId),
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([MODEL_MANUAL, modelId]);
            showModelManualUpdateSuccess();
        },
    });
};
