import { TextInput } from '@mantine/core';
import React, { FunctionComponent } from 'react';

interface GroupNameFieldProps {
    name: string | undefined;
    onEditFinished: (name: string) => void;
}

export const GroupNameField: FunctionComponent<GroupNameFieldProps> = ({
    name,
    onEditFinished,
}) => {
    if (name) return <span>{name}</span>;

    return (
        <TextInput
            onBlur={(e) => onEditFinished(e.target.value)}
            onKeyDown={(e) => {
                if (e.key == 'Enter') onEditFinished((e.target as HTMLInputElement).value);
            }}
            placeholder="Group name"
            required
        />
    );
};
