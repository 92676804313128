import { useMutation } from '@tanstack/react-query';

import { AddNodeFormValues } from '@/features/admin/components/nodes/AddNodeForm';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { NODES } from '@/types/query-keys';

export type UseMutationCreateNodeDto = AddNodeFormValues;

const createNode = async (data: UseMutationCreateNodeDto): Promise<any> => {
    return (await axios.post('/admin/nodes', data)).data;
};

type UseCreateNodeOptions = {
    config?: MutationConfig<typeof createNode>;
};

export const useCreateNode = ({ config }: UseCreateNodeOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: createNode,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [NODES] });
        },
    });
};
