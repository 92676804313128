import React, { FunctionComponent } from 'react';

import { useAdminStyles } from '@/features/admin/components/admin-styles';
import { Licenses } from '@/features/admin/components/licenses/Licenses';

export const AdminLicenses: FunctionComponent = () => {
    const { classes } = useAdminStyles();
    return (
        <div className={classes.container}>
            <Licenses />
        </div>
    );
};
