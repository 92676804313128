import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { GROUPS } from '@/types/query-keys';

export interface UseMutationAssignRoleDto {
    groupId: string;
    usersIds: string[];
}

export const assignGroup = async (data: UseMutationAssignRoleDto): Promise<void> => {
    return (await axios.put(`/admin/groups/${data.groupId}/users`, { usersIds: data.usersIds }))
        .data;
};

type UseAssignUsersOptions = {
    config?: MutationConfig<typeof assignGroup>;
};

export const useAssignToGroup = ({ config }: UseAssignUsersOptions = {}) => {
    return useMutation({
        ...config,
        onSuccess: (data: any, ...rest) => {
            queryClient.invalidateQueries({ queryKey: [GROUPS] });
            config?.onSuccess?.(data, ...rest);
        },
        mutationFn: assignGroup,
    });
};
