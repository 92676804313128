import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

export interface UseMutationDeployModelDto {
    nodeId: string;
    computeModelId: string;
    port: number;
    version: string;
    envVariables?: string;
}

const deployModel = async (data: UseMutationDeployModelDto): Promise<any> => {
    const envVariables = data.envVariables ? JSON.parse(data.envVariables) : undefined;
    return (
        await axios.post(`/admin/nodes/${data.nodeId}/models`, {
            ...data,
            envVariables,
        })
    ).data;
};

type UseDeployModelOptions = {
    config?: MutationConfig<typeof deployModel>;
    onAfterSuccess?: () => void;
};

export const useDeployModel = ({ config, onAfterSuccess }: UseDeployModelOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: deployModel,
    });
};
