import { useMutation } from '@tanstack/react-query';

import { showVoteNotification } from '@/features/models/api/notifications';
import { UserFeedbackFormValues } from '@/features/models/component/UserFeedbackForm';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { ACTIVE_JOBS } from '@/types/query-keys';

export type UseMutationVoteDto = UserFeedbackFormValues;

type VoteType = (data: UseMutationVoteDto) => Promise<any>;

const downVoteWrapper = (jobId: string) => {
    return async (data: UseMutationVoteDto): Promise<any> => {
        return (await axios.post('/feedback', { jobId, ...data })).data;
    };
};

type UseVoteOptions = {
    config?: MutationConfig<VoteType>;
    jobId: string;
};

export const useVote = ({ config, jobId }: UseVoteOptions) => {
    return useMutation({
        ...config,
        mutationFn: downVoteWrapper(jobId),
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [ACTIVE_JOBS] });
            showVoteNotification();
        },
    });
};
