import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
require('dayjs/locale/de');

/**
 * @description Remove empty values from an object
 */
export const removeEmpty = (obj: any): any => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(
                ([_, v]) =>
                    v != null &&
                    ((typeof v == 'string' && v.trim() !== '') || // keep if string is not empty
                        typeof v == 'boolean' || // keep if boolean
                        typeof v == 'number') // keep if number
            )
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    );
};

export const capitalizeFirstLetter = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const datePickerToDateString = (range: [Date | null, Date | null]) => {
    const startDate = dayjs(range[0]);
    const endDate = dayjs(range[1]);
    if (startDate.isValid() && endDate.isValid()) {
        return {
            start: dayjs(range[0]).format('YYYY-MM-DD'),
            finish: dayjs(range[1]).format('YYYY-MM-DD'),
        };
    }
    return {
        start: '',
        finish: '',
    };
};

export const datePickerToDateString2 = (range?: [Date | null, Date | null]) => {
    const startDate = dayjs(range?.[0]);
    const endDate = dayjs(range?.[1]);
    if (startDate.isValid() && endDate.isValid()) {
        return [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')];
    }
    return [];
};

export const formatDate = (date: Date | string) => {
    return dayjs(date).format('YYYY-MM-DD');
};

export const formatDateRelative = (date: Date | string | undefined | null, locale = 'en') => {
    if (!date) return '-';
    return dayjs(date).locale(locale).fromNow();
};
