import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { JOBS_COUNTS } from '@/types/query-keys';

export interface JobCountsType {
    active: number;
    completed: number;
    delayed: number;
    failed: number;
    paused: number;
    waiting: number;
    'waiting-children': number;
}

export const getCountNodeJobs = async (id: string): Promise<JobCountsType> => {
    return (await axios.get(`/admin/nodes/${id}/job-counts`)).data;
};

type QueryFnType = typeof getCountNodeJobs;

type UseCountNodeJobsOptions = {
    id: string;
    config?: QueryConfig<QueryFnType>;
};

export const useCountNodeJobs = ({ config, id }: UseCountNodeJobsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [JOBS_COUNTS, id],
        refetchInterval: 10000,
        queryFn: () => getCountNodeJobs(id),
        ...config,
    });
};
