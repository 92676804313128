import React, { FC } from 'react';

import { JobsContextProvider } from '@/features/admin/components/jobs/JobsContext';
import { JobsFilter } from '@/features/admin/components/jobs/JobsFilter';
import { JobsTable } from '@/features/admin/components/jobs/JobsTable';

export const JobsPanel: FC = () => {
    return (
        <JobsContextProvider>
            <JobsFilter></JobsFilter>
            <JobsTable></JobsTable>
        </JobsContextProvider>
    );
};
