import { useModal } from '@ebay/nice-modal-react';
import {
    ActionIcon,
    Button,
    createStyles,
    Group,
    Image,
    Indicator,
    ScrollArea,
    SimpleGrid,
    Stack,
    Table,
    Text,
    Tooltip,
} from '@mantine/core';
import { IconCheck, IconDownload, IconPencilPlus } from '@tabler/icons-react';
import dayjs from 'dayjs';
import prettyBytes from 'pretty-bytes';
import qs from 'qs';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { getImageUrl, getMaskUrl } from '@/config';
import { AdminFeedbackModal } from '@/features/admin/components/jobs/AdminFeedbackModal';
import { JobInfoAdminFeedback } from '@/features/admin/components/jobs/JobInfoAdminFeedback';
import { JobInfoUserFeedback } from '@/features/admin/components/jobs/JobInfoUserFeedback';
import { AdminViewableUserJob } from '@/features/admin/types/user-jobs';
import { useCreateJob } from '@/features/models/api/create-job';
import { ModelResultViewPanel } from '@/features/models/component/ModelResultViewPanel';
import { JobStatus } from '@/features/models/types/userJobType';
import { queryClient } from '@/lib/react-query';
import { JOB } from '@/types/query-keys';

interface UserProfileProps {
    job: AdminViewableUserJob;
}

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const useStyles = createStyles((theme) => ({
    imageGrid: {
        width: 680,
    },
    inputImage: {
        width: 350,
    },
    meta: {
        margin: 0,
    },
}));

export const JobInfo: FunctionComponent<UserProfileProps> = ({ job }) => {
    const { classes } = useStyles();

    // Modals
    const feedbackModal = useModal(AdminFeedbackModal);

    // API calls
    const { mutate: createJob } = useCreateJob({ modelId: job.model.id });

    const type = job.status;
    const createdAt = dayjs(job.createdAt).format(dateFormat);
    const startedAt = job.startedAt ? dayjs(job.startedAt).format(dateFormat) : '-';
    const finishedAt = job.finishedAt ? dayjs(job.finishedAt).format(dateFormat) : '-';
    const startDelay = Math.abs(dayjs(job.startedAt).diff(job.createdAt, 'seconds', true));
    let jobDuration = dayjs(job.finishedAt).diff(job.startedAt, 'seconds', true);
    if (isNaN(jobDuration)) jobDuration = 0;

    const startLabel = type === JobStatus.NEW ? `${startedAt} (delay: ${startDelay}s)` : '-';
    const finishLabel = `${finishedAt} (duration: ${jobDuration}s)`;
    const version = job.modelInstance?.version || 'unknown';

    const handleFeedbackFormShow = () => {
        feedbackModal.show({ jobId: job.id });
    };

    const handleRequestMasks = () => {
        if (!job.image) return;

        createJob(
            {
                imageIds: [job.image.id],
                requestMasks: true,
                replaceJobId: job.id,
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: [JOB, job.id] });
                },
            }
        );
    };

    return (
        <Table striped>
            <tbody>
                <tr>
                    <td style={{ minWidth: 200 }}>Model</td>
                    <td>{job.model?.displayName}</td>
                </tr>
                <tr>
                    <td>Model version</td>
                    <td>{version}</td>
                </tr>
                <tr>
                    <td>Compute on</td>
                    <td>{job.computeNode?.name}</td>
                </tr>
                <tr>
                    <td>Job status</td>
                    <td>{type}</td>
                </tr>
                <tr>
                    <td>Created at</td>
                    <td>{createdAt}</td>
                </tr>
                <tr>
                    <td>Started at</td>
                    <td>{startLabel}</td>
                </tr>
                <tr>
                    <td>Finished at</td>
                    <td>{finishLabel}</td>
                </tr>
                <tr>
                    <td>Input image</td>
                    <td>
                        {job.image?.orginalFilename} ({job.image?.filename})
                    </td>
                </tr>
                <tr>
                    <td>Input</td>
                    <td>
                        <SimpleGrid className={classes.imageGrid} cols={2}>
                            <div>
                                <img
                                    alt="input"
                                    className={classes.inputImage}
                                    src={getImageUrl(job.image?.id || '')}
                                />
                            </div>

                            <ul className={classes.meta}>
                                <li>
                                    Resolution: {job.image?.meta?.width} x {job.image?.meta?.height}
                                </li>
                                <li>Type: {job.image?.meta?.format}</li>
                                <li>Size: {prettyBytes(job.image?.size || 0)}</li>
                            </ul>
                        </SimpleGrid>
                    </td>
                </tr>
                {job.status == JobStatus.FAILED && (
                    <tr>
                        <td>Errors</td>
                        <td>
                            <ScrollArea h={300} w={1500}>
                                <pre style={{ fontSize: '0.7rem' }}>{job.error?.error || ''}</pre>
                            </ScrollArea>
                        </td>
                    </tr>
                )}
                <tr>
                    <td>Results</td>
                    <td>{job.result && <ModelResultViewPanel job={job} />}</td>
                </tr>
                <tr>
                    <td>
                        <Group spacing={'xs'}>
                            <Text>Masks</Text>
                            <Tooltip label="Request masks for this job">
                                <ActionIcon
                                    disabled={job.status == 'PENDING'}
                                    onClick={handleRequestMasks}
                                >
                                    <IconDownload />
                                </ActionIcon>
                            </Tooltip>
                        </Group>
                    </td>
                    <td>
                        <SimpleGrid cols={3} mt={16} spacing={'xl'} w={'100%'}>
                            {job.masks?.map((mask) => (
                                <Stack key={mask.filename}>
                                    <Text>{mask.label}</Text>
                                    <Indicator
                                        disabled={!mask.isSubmitted}
                                        label={<IconCheck size={24} />}
                                        position={'top-end'}
                                        radius={'xl'}
                                        size={32}
                                    >
                                        <Image
                                            alt="mask"
                                            src={getMaskUrl(mask.filename)}
                                            w={'100%'}
                                        />
                                    </Indicator>
                                    <Button
                                        component={Link}
                                        disabled={job.status == 'PENDING'}
                                        to={`/admin/mask-editor?${qs.stringify({ mask: mask.filename, image: job.image.id })}`}
                                        variant="outline"
                                    >
                                        Edit
                                    </Button>
                                </Stack>
                            ))}
                        </SimpleGrid>
                    </td>
                </tr>
                <tr>
                    <td>User Feedback</td>
                    <td>
                        <JobInfoUserFeedback data={job.userFeedback} owner={job.owner} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <Group spacing={'xs'}>
                            <Text>Admin feedbacks</Text>
                            <ActionIcon onClick={handleFeedbackFormShow}>
                                <IconPencilPlus />
                            </ActionIcon>
                        </Group>
                    </td>
                    <td>
                        <JobInfoAdminFeedback data={job.adminFeedback} jobId={job.id} />
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};
