import { useQuery } from '@tanstack/react-query';

import { JOB_FETCH_INTERVAL } from '@/config';
import { UserJobType } from '@/features/models/types/userJobType';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { SANDBOX } from '@/types/query-keys';

export const getSandboxJobs = async (): Promise<{
    jobs: UserJobType[];
    total: number;
    pageSize: number;
}> => {
    return (await axios.get('/sandbox')).data;
};

type QueryFnType = typeof getSandboxJobs;

type UseSandboxJobsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useSandboxJobs = ({ config }: UseSandboxJobsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [SANDBOX],
        queryFn: getSandboxJobs,
        refetchInterval: JOB_FETCH_INTERVAL,
        ...config,
    });
};
