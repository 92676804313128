import { useModal } from '@ebay/nice-modal-react';
import { Accordion, Center } from '@mantine/core';
import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ErrorIcon from '@/features/models/assets/error.svg';
import { ErrorModal } from '@/features/models/component/ErrorModal';
import { UserJobType } from '@/features/models/types/userJobType';
import { useDelayedVisibility } from '@/hooks/useDelayedVisibility';
import { useAuthUser } from '@/hooks/useUser';

import { AccordionControl } from './AccordionControl';

interface JobFailedProps {
    job: UserJobType;
    isVisible?: boolean;
    postfixTitle?: string;
    hideControls?: boolean;
}

export const JobFailed: FunctionComponent<JobFailedProps> = ({
    job,
    isVisible = false,
    postfixTitle,
    hideControls,
}) => {
    const shouldRenderPanel = useDelayedVisibility(isVisible, 1000);
    const errorModal = useModal(ErrorModal);
    const user = useAuthUser();

    const isAdmin = user?.role === 'ADMIN';

    return (
        <Accordion.Item key={job.id} value={job.id}>
            <AccordionControl hideControls={hideControls} job={job} selectable={true}>
                {job.image.orginalFilename} {postfixTitle}
            </AccordionControl>
            <Accordion.Panel>
                {shouldRenderPanel && (
                    <Center>
                        <img alt="Error" src={ErrorIcon} width={200} />

                        <div>
                            <p>
                                <Trans i18nKey="model.error">
                                    We apologize for the inconvenience.
                                    <br />
                                    The model has encountered a problem and is unable to produce
                                    results at this time.
                                </Trans>
                            </p>
                            {isAdmin && (
                                <p>
                                    <Trans i18nKey="model.errorDetails">
                                        Error details:{' '}
                                        <Link
                                            onClick={() => errorModal.show({ jobId: job.id })}
                                            to="#"
                                        >
                                            Click here
                                        </Link>
                                    </Trans>
                                </p>
                            )}
                        </div>
                    </Center>
                )}
            </Accordion.Panel>
        </Accordion.Item>
    );
};
