import { LicenseProductType } from '@/features/user-license/types/license-product';

export enum LicenseType {
    NONE = 'NONE',
    IMAGES_LIMITED = 'IMAGES_LIMITED',
    TIME_LIMITED = 'TIME_LIMITED',
    DEMO = 'DEMO',
}

export enum LicenseBillingType {
    ONE_TIME = 'ONE_TIME',
    MONTHLY = 'MONTHLY',
    ANNUAL = 'ANNUAL',
}

export interface AdminProductType extends LicenseProductType {
    stripePriceId?: string;
    billingType: LicenseBillingType | null;
    active: boolean;
    renewalReminderDays: number | null;

    demoImages?: number;
    demoTime?: number;
    extendedDemoTime?: number;
}

export type ProductFormValues = AdminProductType;
