import {
    DefaultOptions,
    QueryClient,
    UseInfiniteQueryOptions,
    UseMutationOptions,
    UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

const queryConfig: DefaultOptions = {
    queries: {
        useErrorBoundary: false,
        refetchOnWindowFocus: false,
        retry: false,
    },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<ReturnType<FnType>>;

export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
    UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
    'queryKey' | 'queryFn' | 'initialData'
>;

export type InfinityQueryConfig<QueryFnType extends (...args: any) => any> = Omit<
    UseInfiniteQueryOptions<ExtractFnReturnType<QueryFnType>>,
    'queryKey' | 'queryFn' | 'initialData'
>;

export type MutationConfig<MutationFnType extends (...args: any) => any> = UseMutationOptions<
    ExtractFnReturnType<MutationFnType>,
    AxiosError,
    Parameters<MutationFnType>[0]
>;
