import { showNotification } from '@mantine/notifications';

// Default settings for notifications
const DEFAULT_AUTO_CLOSE_DURATION = 5000;
const DEFAULT_NOTIFICATION_COLOR = 'primary';
const DEFAULT_FAILURE_COLOR = 'red';

// Generic function to show success notifications
export function showSuccessNotification(message: string) {
    showNotification({
        autoClose: DEFAULT_AUTO_CLOSE_DURATION,
        title: 'Success',
        message,
        color: DEFAULT_NOTIFICATION_COLOR,
    });
}

export function showFailureNotification(message: string) {
    showNotification({
        autoClose: DEFAULT_AUTO_CLOSE_DURATION,
        title: 'Failure',
        message,
        color: DEFAULT_FAILURE_COLOR,
    });
}

// Specific notification functions
export const showProductUpdatedSuccessfullyNotification = () => {
    showSuccessNotification('Product has been updated.');
};

export const showProductFailedToUpdatedNotification = (message: string | undefined) => {
    showFailureNotification('Product failed to update. ' + message);
};

export const showTermsUpdatedSuccessfullyNotification = () => {
    showSuccessNotification('Terms have been updated.');
};

export const showUserActivatedNotification = () => {
    showSuccessNotification('Account has been activated. User can now log in.');
};

export const showPriorityUpdateSuccess = () => {
    showSuccessNotification('Priority has been updated.');
};

export const showTranslationsUpdateSuccess = () => {
    showSuccessNotification('Translations have been updated.');
};

export const showModelManualUpdateSuccess = () => {
    showSuccessNotification('Model manual has been updated.');
};

export const showMaintenanceModeUpdated = () => {
    showSuccessNotification('Maintenance mode has been updated.');
};

export const showExampleResultsUpdated = () => {
    showSuccessNotification('Example results have been updated.');
};

export const showInsightsReset = () => {
    showSuccessNotification('Insights visibility has been reset for all users.');
};

export const showAccessGranted = () => {
    showSuccessNotification('Access has been granted.');
};

export const showAccessRevoked = () => {
    showSuccessNotification('Access to the model has been revoked.');
};

export const showModelUpdated = () => {
    showSuccessNotification('Model has been updated.');
};

export const showNodeStartsSuccessNotification = () => {
    showSuccessNotification('Node is starting. This may take a few minutes.');
};

export const showNodeStoppingNotification = () => {
    showSuccessNotification('Node is stopping. This may take a few minutes.');
};

export const showNodeStartsFailureNotification = () => {
    showFailureNotification(
        `Failed to start node. It may already be running or there is an issue with the configuration.`
    );
};

export const showNodeStoppingFailureNotification = () => {
    showFailureNotification(
        `Failed to stop node. It may already be stopping or there is an issue with the configuration.`
    );
};

export const showModelRestartSuccessNotification = () => {
    showSuccessNotification('Model has been restarted.');
};

export const showModelRestartFailureNotification = () => {
    showFailureNotification('Failed to restart model.');
};
