import { useModal } from '@ebay/nice-modal-react';
import { Table } from '@mantine/core';
import React, { FC } from 'react';

import { useDeleteInsights } from './api/delete-insight';
import { useInsights } from './api/insights';
import { InsightRow } from './InsightRow';
import { InsightsDeleteConfirmDialog } from './InsightsDeleteConfirmDialog';
import { InsightsModal } from './InsightsModal';
import { InsightType } from './types/insight-type';

export const InsightsTable: FC = () => {
    const { data: insights } = useInsights();
    const deleteInsights = useDeleteInsights();
    const deleteInsightsDialog = useModal(InsightsDeleteConfirmDialog);
    const editInsightsDialog = useModal(InsightsModal);

    const handleDeleteModel = (model: InsightType) => {
        deleteInsightsDialog.show().then((result) => {
            if (result) {
                deleteInsights.mutate(model.id);
            }
        });
    };

    const handleEditModel = (insights: InsightType) => {
        editInsightsDialog.show({ insightsId: insights.id });
    };

    const rows =
        insights?.map((item) => (
            <InsightRow
                item={item}
                key={item.id}
                onDelete={handleDeleteModel}
                onEdit={handleEditModel}
            ></InsightRow>
        )) || [];

    return (
        <Table highlightOnHover>
            <thead>
                <tr>
                    <th>Title</th>
                    <th style={{ width: 150 }}>Created at</th>
                    <th style={{ width: '10%' }}>Actions</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
};
