import { useMutation } from '@tanstack/react-query';

import {
    showProductFailedToUpdatedNotification,
    showProductUpdatedSuccessfullyNotification,
} from '@/features/admin/api/notifications';
import {
    AdminProductType,
    ProductFormValues,
} from '@/features/admin/components/stripe/types/license-product';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { AxiosErrorMessage } from '@/types/axios-error-message';
import { ADMIN_PRODUCTS } from '@/types/query-keys';

const createProduct = async (data: ProductFormValues): Promise<AdminProductType> => {
    return (await axios.post(`/admin/products`, data)).data;
};

type UseCreateProductOptions = {
    config?: MutationConfig<typeof createProduct>;
};

export const useCreateProduct = ({ config }: UseCreateProductOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: createProduct,
        onError: (error: AxiosErrorMessage, data, _) => {
            const message = error.response?.data?.message?.join(', ') || '';
            showProductFailedToUpdatedNotification(message);
            config?.onError?.(error, data, _);
        },
        onSuccess: (data: any, variables: any, context: any) => {
            showProductUpdatedSuccessfullyNotification();
            queryClient.invalidateQueries({ queryKey: [ADMIN_PRODUCTS] });
            config?.onSuccess?.(data, variables, context);
        },
    });
};
