import { useQuery } from '@tanstack/react-query';

import { AdminProductType } from '@/features/admin/components/stripe/types/license-product';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ADMIN_PRODUCTS } from '@/types/query-keys';

export const queryProducts = async (): Promise<AdminProductType[]> => {
    return (await axios.get('/admin/products')).data;
};

type QueryFnType = typeof queryProducts;

type UseProductsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useQueryProducts = ({ config }: UseProductsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [ADMIN_PRODUCTS],
        queryFn: () => queryProducts(),
        ...config,
    });
};
