import { useMutation } from '@tanstack/react-query';

import {
    showClaimVoucherError,
    showClaimVoucherSuccess,
} from '@/features/user-license/api/notifications';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

export interface UseMutationClaimVoucherDto {
    key: string;
}

const claimVoucher = async (data: UseMutationClaimVoucherDto): Promise<any> => {
    return (await axios.put('/users/voucher', data)).data;
};

type UseClaimVoucherOptions = {
    config?: MutationConfig<typeof claimVoucher>;
};

export const useClaimVoucher = ({ config }: UseClaimVoucherOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: claimVoucher,
        onSuccess: (data: any) => {
            showClaimVoucherSuccess();
        },
        onError: (error: any) => {
            showClaimVoucherError();
        },
    });
};
