import { Route, Routes } from 'react-router-dom';

import { Subscriptions } from '@/features/user-license/components';

export const UserSubscriptionsRoutes = () => {
    return (
        <Routes>
            <Route element={<Subscriptions />} path="" />
        </Routes>
    );
};
