import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { USERS_LIST } from '@/types/query-keys';

import { BaseUser } from '../types/user-account';

export const getUsersByName = async (userName?: string): Promise<BaseUser[]> => {
    // if (!userName) return Promise.resolve([]);
    return (await axios.get(`/admin/users/`, { params: { onlyNameId: true, name: userName } }))
        .data;
};

type QueryFnType = typeof getUsersByName;

type UseUserJobOptions = {
    userName?: string;
    config?: QueryConfig<QueryFnType>;
};

export const useUsersList = ({ config, userName }: UseUserJobOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [USERS_LIST, userName],
        // enabled: !!userName,
        queryFn: () => getUsersByName(userName),
        ...config,
    });
};
