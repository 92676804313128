import { Helmet } from 'react-helmet-async';

import { appUrl } from '@/config';

type HeadProps = {
    title?: string;
    description?: string;
};

export const Head = ({ title = '', description = '' }: HeadProps = {}) => {
    return (
        <Helmet
            defaultTitle="MiViA - Microvision Analysis"
            title={title ? `${title} | MiVia` : undefined}
        >
            <meta content={description} name="description" />
            <script data-domain={appUrl} defer src="https://traffic.mivia.ai/js/script.js"></script>
        </Helmet>
    );
};
