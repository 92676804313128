import { useMutation } from '@tanstack/react-query';
import { SupportedLanguage } from 'mivia-shared';

import { showTranslationsUpdateSuccess } from '@/features/admin/api/notifications';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { TRANSLATIONS } from '@/types/query-keys';

type UpdateTranslationsType = (data: string) => Promise<string>;

const updateTranslationsWrapper = (lang: SupportedLanguage) => {
    return async (data: string): Promise<string> => {
        const jsonData = JSON.parse(data);
        return (await axios.put(`/languages/${lang}`, jsonData)).data;
    };
};

type UseUpdateTranslationsOptions = {
    config?: MutationConfig<UpdateTranslationsType>;
    lang: SupportedLanguage;
};

export const useUpdateTranslations = ({ config, lang }: UseUpdateTranslationsOptions) => {
    return useMutation({
        ...config,
        mutationFn: updateTranslationsWrapper(lang),
        onSuccess: (data: string) => {
            showTranslationsUpdateSuccess();
            queryClient.invalidateQueries([TRANSLATIONS, lang]);
        },
    });
};
