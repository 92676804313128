import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mantine/core';

import { useCreateProduct } from '@/features/admin/components/stripe/api/create-product';
import { useQueryProduct } from '@/features/admin/components/stripe/api/product';
import { useUpdateProduct } from '@/features/admin/components/stripe/api/update-product';
import { ProductForm } from '@/features/admin/components/stripe/ProductForm';
import { ProductFormValues } from '@/features/admin/components/stripe/types/license-product';

interface ProductModalProps {
    productId?: string;
}

export const ProductModal = NiceModal.create<ProductModalProps>(({ productId }) => {
    const modal = useModal();
    const { visible, remove } = modal;
    const { data: productDetails } = useQueryProduct({ id: productId });
    const updateProduct = useUpdateProduct({
        id: productId,
        config: { onSuccess: () => remove() },
    });
    const createProduct = useCreateProduct({ config: { onSuccess: () => remove() } });

    const handleSubmit = (values: ProductFormValues) => {
        if (productId) updateProduct.mutate(values);
        else createProduct.mutate(values);
    };
    const handleCancel = () => {
        remove();
    };

    return (
        <Modal centered onClose={handleCancel} opened={visible} size={'70%'} title="Edit license">
            <ProductForm
                initialValues={productDetails}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
            ></ProductForm>
        </Modal>
    );
});
