import { useMutation } from '@tanstack/react-query';
import { AxiosProgressEvent } from 'axios';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { PUBLIC_FILES } from '@/types/query-keys';

export interface UseMutationUploadImageDto {
    file: File;
    progressCallBack?: (event: AxiosProgressEvent) => void;
}

const uploadPublicImage = async (data: UseMutationUploadImageDto): Promise<any> => {
    const { file, progressCallBack } = data;

    const formData = new FormData();
    formData.append('files', file);

    return (
        await axios.post('/admin/images/public', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                progressCallBack?.(progressEvent);
            },
        })
    ).data;
};

type UseUploadImageOptions = {
    config?: MutationConfig<typeof uploadPublicImage>;
};

export const useUploadPublicImage = ({ config }: UseUploadImageOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: uploadPublicImage,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([PUBLIC_FILES]);
        },
    });
};
