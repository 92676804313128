import { ResponsiveLineCanvas } from '@nivo/line';
import React, { FunctionComponent } from 'react';

import { PerformanceStat } from '@/features/admin/types/performance';

interface LineChartProps {
    data: PerformanceStat[];
}

export const LineChart: FunctionComponent<LineChartProps> = ({ data }) => {
    return (
        <ResponsiveLineCanvas
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Time',
                tickValues: 'every 4 hours',
                legendOffset: 36,
                legendPosition: 'middle',
                format: '%Y-%m-%d %H:%M',
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Value %',
                legendOffset: -40,
                legendPosition: 'middle',
            }}
            axisRight={null}
            axisTop={null}
            colors={{ scheme: 'category10' }}
            data={data}
            legends={[
                {
                    anchor: 'top-right',
                    direction: 'column',
                    translateX: 100,
                    translateY: 0,
                    itemWidth: 80,
                    itemHeight: 20,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                },
            ]}
            margin={{ top: 20, right: 70, bottom: 100, left: 60 }}
            pointSize={0}
            tooltip={() => null}
            xScale={{
                type: 'time',
                format: '%Y-%m-%dT%H:%M:%S.%LZ',
                precision: 'hour',
                min: 'auto',
                max: 'auto',
            }}
            yScale={{
                type: 'linear',
                min: 0,
                max: 100,
                stacked: false,
                reverse: false,
            }}
        />
    );
};
