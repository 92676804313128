import { useCallback, useEffect, useRef } from 'react';

/**
 * `useThrottleFn` is a hook that limits the number of calls to the passed function over time.
 *
 * @function
 * @param {((...args: any[]) => any) | undefined} fn - The function to be throttled.
 * @param {number} delay - The time, in milliseconds, between function calls.
 * @returns {(...args: any[]) => void} - The throttled function.
 *
 * @example
 * function ExampleComponent() {
 *   const throttledLog = useThrottleFn(() => {
 *     console.log('Function called!');
 *   }, 1000);
 *
 *   return <button onClick={throttledLog}>Click me</button>;
 * }
 */
export function useThrottleFn(
    fn: ((...args: any[]) => any) | undefined,
    delay: number
): (...args: any[]) => void {
    const lastCalled = useRef(0);

    const throttledFn = useCallback(
        (...args: any[]) => {
            if (fn && Date.now() - lastCalled.current >= delay) {
                fn(...args);
                lastCalled.current = Date.now();
            }
        },
        [fn, delay]
    );

    useEffect(() => {
        return () => {
            lastCalled.current = 0;
        };
    }, []);

    return throttledFn;
}
