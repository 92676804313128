import { Center, createStyles, Group, Text, UnstyledButton } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons-react';
import React, { FunctionComponent } from 'react';

interface SortableTableHeaderProps {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;
    width?: number;
    onSort(): void;
}

const useStyles = createStyles((theme) => ({
    th: {
        padding: '0 !important',
    },

    control: {
        width: '100%',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    },

    icon: {
        width: 21,
        height: 21,
        borderRadius: 21,
    },
}));

export const SortableTableHeader: FunctionComponent<SortableTableHeaderProps> = ({
    children,
    reversed,
    sorted,
    onSort,
    width = 200,
}) => {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <th className={classes.th} style={{ width }}>
            <UnstyledButton className={classes.control} onClick={onSort}>
                <Group position="apart">
                    <Text size="sm" weight={500}>
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={14} />
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    );
};
