import React from 'react';

import { Registry } from '@/features/admin/components/compute-models-registry/Registry';

import { useAdminStyles } from '../components/admin-styles';

export const AdminModels = () => {
    const { classes } = useAdminStyles();
    return (
        <div className={classes.container}>
            <Registry />
        </div>
    );
};
