import { ActionIcon, Group } from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import React, { FC } from 'react';

import { ModelNewsType } from '@/features/admin/components/news/types/model-news-type';
import { formatDate } from '@/lib/helpers';

interface NewsRowProps {
    item: ModelNewsType;
    onDelete: (item: ModelNewsType) => void;
    onEdit: (item: ModelNewsType) => void;
}

export const NewsRow: FC<NewsRowProps> = ({ item, onDelete, onEdit }) => {
    return (
        <tr>
            <td>{item.titleEn}</td>
            <td>{formatDate(item.createdAt)}</td>
            <td>
                <Group spacing={0}>
                    <ActionIcon onClick={() => onEdit(item)} radius="xl">
                        <IconPencil size={16}></IconPencil>
                    </ActionIcon>
                    <ActionIcon color="red" onClick={() => onDelete(item)} radius="xl">
                        <IconTrash size={16}></IconTrash>
                    </ActionIcon>
                </Group>
            </td>
        </tr>
    );
};
