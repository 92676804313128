import { Bar, ComputedDatum } from '@nivo/bar';
import { HistogramDataItem, roundNumber, tickFormat, mapXAxisToString } from 'mivia-shared';
import { FC } from 'react';

export interface HistogramProps {
    height?: number;
    width?: number;
    fontSize?: number;
    margin?: { top: number; right: number; bottom: number; left: number };
    data: HistogramDataItem[];
    xLabel?: string;
    yLabel?: string;
}

//  ComputedDatum<BarChartData>
const getColor = (bar: ComputedDatum<HistogramDataItem>) => {
    return bar.data.color || '#000000';
};

export const Histogram: FC<HistogramProps> = ({
    data,
    width,
    height,
    fontSize,
    margin,
    xLabel,
    yLabel,
}: HistogramProps) => {
    const universalData = mapXAxisToString(data);

    // Max length of xTick to adjust offsets
    const xTickMaxLength = universalData.reduce((max, item) => {
        return item.x.length > max ? item.x.length : max;
    }, 0);

    return (
        <Bar
            axisBottom={{
                tickRotation: 45,
                format: tickFormat,
                legend: xLabel || '',
                legendPosition: 'middle',
                legendOffset: xTickMaxLength * 5 + 20,
            }}
            axisLeft={{
                legend: yLabel || '',
                legendPosition: 'middle',
                legendOffset: -42,
            }}
            colorBy="indexValue"
            colors={getColor}
            data={universalData}
            enableLabel={false}
            height={height || 300}
            indexBy="x"
            keys={['y']}
            margin={margin || { top: 10, right: 20, bottom: xTickMaxLength * 5 + 30, left: 50 }}
            padding={0.1}
            theme={{
                axis: {
                    ticks: {
                        text: {
                            fontSize: fontSize,
                        },
                    },
                },
            }}
            tooltip={({ id, value, color, indexValue }) => (
                <div
                    style={{
                        padding: 12,
                        color: '#000',
                        background: '#ffffff',
                        border: `1px solid ${color}`,
                    }}
                >
                    <span>
                        {xLabel}: {indexValue}
                        <br />
                        {yLabel}: {roundNumber(value)}
                    </span>
                </div>
            )}
            valueScale={{ type: 'linear', nice: true }}
            width={width || 600}
        />
    );
};
