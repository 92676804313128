import { Button, Group, JsonInput, Stack } from '@mantine/core';
import { getHotkeyHandler } from '@mantine/hooks';
import { SupportedLanguage } from 'mivia-shared';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useTranslations } from '@/features/admin/api/translations';
import { useUpdateTranslations } from '@/features/admin/api/update-translations';

interface TranslationTabProps {
    language: SupportedLanguage;
}

export const TranslationTab: FunctionComponent<TranslationTabProps> = ({ language }) => {
    const queryTerms = useTranslations({ lang: language });
    const [value, onChange] = useState<string>('{}');
    const updateTerms = useUpdateTranslations({ lang: language });

    useEffect(() => {
        const text = queryTerms.data;
        if (text) {
            onChange(text);
        }
    }, [queryTerms.data]);

    const handleUpdateTerms = () => {
        if (value) {
            updateTerms.mutate(value);
        }
    };

    return (
        <Stack>
            <JsonInput
                autosize={true}
                formatOnBlur
                onChange={onChange}
                onKeyDown={getHotkeyHandler([['ctrl+S', handleUpdateTerms]])}
                value={value}
            />

            <Group position="right">
                <Button onClick={handleUpdateTerms}>Save</Button>
            </Group>
        </Stack>
    );
};
