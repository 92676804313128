import { Accordion } from '@mantine/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { JobFailed } from '@/features/models/component/JobFailed';
import { JobSuccess } from '@/features/models/component/JobSuccess';
import { JobStatus } from '@/features/models/types/userJobType';
import { useSandboxJobs } from '@/features/sandbox/api/sandbox-jobs';

export const SandboxResults: FC = () => {
    const {
        data: results,
        isLoading: isResultsLoading,
        isError: isResultsError,
    } = useSandboxJobs();
    const { t } = useTranslation();
    const [visibleResults, setVisibleResults] = useState<string[]>([]);

    useEffect(() => {
        if (results) {
            setVisibleResults(results.jobs.map((job) => job.id));
        }
    }, [results]);

    const items = results?.jobs.map((job) =>
        job.status === JobStatus.FAILED ? ( // Using strict equality
            <JobFailed
                hideControls
                isVisible
                job={job}
                key={job.id}
                postfixTitle={`(${job.model.displayName})`}
            />
        ) : (
            <JobSuccess
                hideControls
                isVisible
                job={job}
                key={job.id}
                postfixTitle={`(${job.model.displayName})`}
            />
        )
    );

    if (isResultsLoading) {
        return <div>{t('common.loading')}</div>;
    }

    if (isResultsError) {
        return <div>{t('common.error')}</div>;
    }

    return (
        <Accordion multiple={true} onChange={setVisibleResults} value={visibleResults}>
            {items}
        </Accordion>
    );
};
