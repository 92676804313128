import { useQuery } from '@tanstack/react-query';

import { DockerImage } from '@/features/admin/types/docker-image';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { DOCKER_IMAGES } from '@/types/query-keys';

export const queryRegistryTagsByModelId = async (
    computeModelId: string
): Promise<DockerImage[]> => {
    const params = { computeModelId };
    const { data } = await axios.get(`/admin/registry`, { params });
    return data;
};

export const queryRegistryTagsByImageName = async (imageName: string): Promise<DockerImage[]> => {
    const params = { imageName };
    const { data } = await axios.get(`/admin/registry`, { params });
    return data;
};

type QueryFnType = typeof queryRegistryTagsByImageName;

type UseModelsOptions = {
    imageName: string;
    config?: QueryConfig<QueryFnType>;
};

export const useDockerImages = ({ config, imageName }: UseModelsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [DOCKER_IMAGES, imageName],
        queryFn: () => queryRegistryTagsByImageName(imageName),
        ...config,
    });
};
