import { SimpleGrid } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { useQueryNodes } from '@/features/admin/api/nodes';

import { NodesPerformancePanel } from './NodesPerformancePanel';

export const AdminDashboardPanel: FunctionComponent = () => {
    const nodesQuery = useQueryNodes();

    const nodes = nodesQuery.data;
    const nodesIds = nodes?.map((node) => node.id) ?? [];

    return (
        <SimpleGrid cols={2}>
            {nodesIds.map((nodeId) => (
                <NodesPerformancePanel key={nodeId} nodeId={nodeId}></NodesPerformancePanel>
            ))}
        </SimpleGrid>
    );
};
