import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BarChart, BarChartProps } from '@/features/models/component/BarChart';

export const BarChartModal = NiceModal.create<BarChartProps>((props) => {
    const { t } = useTranslation();
    const modal = useModal();
    const { hide, visible } = modal;
    const title = t(`model.${props.activeKey}`);

    return (
        <Modal centered onClose={() => hide()} opened={visible} size={850} title={title}>
            <BarChart
                {...props}
                height={500}
                isFullSize={true}
                margin={{ top: 2, right: 20, bottom: 120, left: 60 }}
                width={800}
            ></BarChart>
        </Modal>
    );
});
