import { Button, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from '@/components/Panel';
import {
    useClaimVoucher,
    UseMutationClaimVoucherDto,
} from '@/features/user-license/api/claim-voucher';

type ClaimVoucherForm = UseMutationClaimVoucherDto;

export const ClaimVoucher: FunctionComponent = () => {
    const { t } = useTranslation();
    const claimVoucher = useClaimVoucher();

    const form = useForm<ClaimVoucherForm>({
        initialValues: {
            key: '',
        },

        validate: {
            key: (value) =>
                /^\w{5}-\w{5}-\w{5}-\w{5}$/.test(value) ? null : t('purchase.invalid'),
        },
    });

    const handleVoucherClaim = (values: ClaimVoucherForm) => {
        claimVoucher.mutate({ key: values.key });
        form.reset();
    };

    return (
        <Panel title={t('purchase.claim')}>
            <form onSubmit={form.onSubmit(handleVoucherClaim)}>
                <TextInput
                    data-cy="voucher-text-input"
                    label={t('purchase.voucher')}
                    placeholder="XXXXX-XXXXX-XXXXX-XXXXX"
                    {...form.getInputProps('key')}
                />
                <Group mt={10} position="right">
                    <Button data-cy="voucher-text-submit-button" type="submit">{t('common.submit')}</Button>
                </Group>
            </form>
        </Panel>
    );
};
