import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { PUBLIC_FILES } from '@/types/query-keys';

export interface UseMutationRemovePublicImageDto {
    filename: string;
}

const removePublicImage = async ({ filename }: UseMutationRemovePublicImageDto): Promise<any> => {
    return (await axios.delete(`/admin/images/public/${filename}`)).data;
};

type UseRemovePublicImageOptions = {
    config?: MutationConfig<typeof removePublicImage>;
};

export const useRemovePublicImage = ({ config }: UseRemovePublicImageOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: removePublicImage,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([PUBLIC_FILES]);
        },
    });
};
