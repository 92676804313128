import {z} from "zod";

export const dataTableResult = z.object({
    type: z.literal('data-table'),
});

const dataTableDataItem = z.object({
        label: z.string(),
        labelTooltip: z.string().optional(),
        value: z.number(),
    }
);

export type DataTableDataItem = z.infer<typeof dataTableDataItem>;

export type DataTableResultType = z.infer<typeof dataTableResult> & {
    data: DataTableDataItem[]
}


