import { Button, Group, List } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { Voucher } from '@/features/admin/types/voucher';

interface VouchersListProps {
    vouchers: Voucher[];
    onOk: () => void;
}

export const VouchersList: FunctionComponent<VouchersListProps> = ({ vouchers, onOk }) => {
    const items = vouchers.map((voucher) => <List.Item key={voucher.id}>{voucher.key}</List.Item>);
    return (
        <>
            <List mt={10}>{items}</List>
            <Group mt={5} position="right">
                <Button onClick={onOk}>OK</Button>
            </Group>
        </>
    );
};
