import React from 'react';

import { Groups } from '@/features/admin/components/Groups';

import { useAdminStyles } from '../components/admin-styles';

export function AdminGroups() {
    const { classes } = useAdminStyles();

    return (
        <div className={classes.container}>
            <Groups />
        </div>
    );
}
