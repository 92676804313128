import { useModal } from '@ebay/nice-modal-react';
import { Avatar, Radio, Tooltip } from '@mantine/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageModal } from '@/components/Layout/ImageModal';
import { ScrollableTable } from '@/components/ScrollableTable';
import { getImageThumbnailUrl, getImageUrl } from '@/config';
import { Image } from '@/features/user-dashboard/types/image';

import styles from './FilesList.module.css';

interface FilesListProps {
    onChange(id: string): void;
    selected: string | undefined;
    images: Image[] | undefined;
}

const FileName = ({ filename }: { filename: string }) => {
    const name = filename.length > 70 ? filename.substring(0, 70) + '...' : filename;
    return <span>{name}</span>;
};

const ImageAvatar = ({
    id,
    validated,
    tooltip,
}: {
    id: string;
    validated: boolean;
    tooltip: string;
}) => {
    const avatar = (
        <Avatar
            className={!validated ? styles.invalidFileAvatar : ''}
            color={'red'}
            radius={26}
            size={26}
            src={getImageThumbnailUrl(id)}
        />
    );

    if (validated) return avatar;
    else return <Tooltip label={tooltip}>{avatar}</Tooltip>;
};

export const FilesList: FunctionComponent<FilesListProps> = ({ onChange, selected, images }) => {
    const { t } = useTranslation();
    const imageModal = useModal(ImageModal);

    if (!images) return null;

    const notValidTooltip = t('dashboard.notValidated');

    const handleRowClick = (image: Image, event: React.MouseEvent) => {
        onChange(image.id);
        event.stopPropagation();
    };

    const handleImageClick = (image: Image, event: React.MouseEvent) => {
        imageModal.show({
            imageUrl: getImageUrl(image.id),
            caption: image.orginalFilename,
        });
        event.stopPropagation();
    };

    const rows = images.map((element) => (
        <tr
            key={element.id}
            onClick={(e) => handleRowClick(element, e)}
            style={{ cursor: 'pointer' }}
        >
            <td>
                <a href="#" onClick={(e) => handleImageClick(element, e)}>
                    <ImageAvatar
                        id={element.id}
                        tooltip={notValidTooltip}
                        validated={element.validated}
                    />
                </a>
            </td>
            <td>
                <FileName filename={element.orginalFilename} />
            </td>
            <td>
                <Radio value={element.id} />
            </td>
        </tr>
    ));

    return (
        <Radio.Group onChange={onChange} value={selected}>
            <ScrollableTable height={300} striped>
                <thead>
                    <tr>
                        <th>{t('dashboard.preview')}</th>
                        <th>{t('dashboard.filename')}</th>
                        <th>{t('dashboard.select')}</th>
                    </tr>
                </thead>
                <tbody data-cy="image-list-table">{rows}</tbody>
            </ScrollableTable>
        </Radio.Group>
    );
};
