import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { NODES } from '@/types/query-keys';

const testInstance = async (id: string): Promise<any> => {
    return (
        await axios.post(`/admin/compute-models-tester/`, {
            instanceId: id,
        })
    ).data;
};

type UseTestInstanceOptions = {
    config?: MutationConfig<typeof testInstance>;
};

export const useTestInstance = ({ config }: UseTestInstanceOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: testInstance,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [NODES] });
        },
    });
};
