import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { COMPUTE_MODELS } from '@/types/query-keys';

const deleteComputeModel = async (id: string): Promise<void> => {
    return (await axios.delete(`/admin/compute-models/${id}`)).data;
};

type UseDeleteComputeModelOptions = {
    config?: MutationConfig<typeof deleteComputeModel>;
};

export const useDeleteComputeModel = ({ config }: UseDeleteComputeModelOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: deleteComputeModel,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [COMPUTE_MODELS] });
        },
    });
};
