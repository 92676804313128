import {
    Button,
    Checkbox,
    createStyles,
    Group,
    PasswordInput,
    Select,
    SimpleGrid,
    Stack,
    Text,
    TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCompass, IconSettings } from '@tabler/icons-react';
import { passwordValidator, phoneNumberPlaceholder, phoneValidator } from 'mivia-shared';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PanelWithIcon } from '@/components/Panel/PanelWithIcon';
import { AccountRemovalPanel } from '@/features/user-profile/component/AccountRemovalPanel';
import { TwoFactorPanel } from '@/features/user-profile/component/TwoFactorPanel';

import { useProfile } from '../api/me';
import { useUpdateAuth } from '../api/update-auth';
import { useUpdateProfile } from '../api/update-profile';
import { Address, UserProfileForm } from '../types/user-profile';

import { CountrySelect } from './CountrySelect';

const useStyles = createStyles((theme) => ({
    title: {
        color: theme.colors.stone[7],
    },
}));

const ProfileTitle = ({ title }: { title: string }) => {
    const { classes } = useStyles();
    return (
        <Text align="center" className={classes.title} size="lg" weight={700}>
            {title}
        </Text>
    );
};

export const Profile = () => {
    const { t, i18n } = useTranslation();
    const updateAuth = useUpdateAuth();
    const updateProfile = useUpdateProfile();
    const { data: profileData } = useProfile();

    const authForm = useForm<UserProfileForm>({
        initialValues: {
            name: '',
            surname: '',
            email: '',
            oldPassword: '',
            newPassword: '',
            group: '',
            subscribe: false,
            trainingConsent: false,
        },

        validate: {
            newPassword: (value) => {
                if (!value) return null;
                const res = passwordValidator(value);
                return res ? t(res) : null; // returns null if ok, or error messages if not
            },
        },
    });

    const addressForm = useForm<Address>({
        initialValues: {
            company: '',
            address: '',
            postalCode: '',
            city: '',
            country: '',
            taxNumber: '',
            phone: '',
        },
        validate: {
            phone: (value: string | undefined) => {
                const res = phoneValidator(value);
                return res ? t(res) : null; // returns null if ok, or error messages if not
            },
        },
    });

    useEffect(() => {
        if (!profileData) return;

        authForm.setValues({
            newPassword: '',
            oldPassword: '',
            name: profileData.name,
            surname: profileData.surname,
            email: profileData.email,
            group: profileData.group?.name || '',
            subscribe: profileData.subscribe,
            trainingConsent: profileData.trainingConsent,
        });

        if (profileData.address) {
            const { company, address, city, country, postalCode, taxNumber, phone } =
                profileData.address;
            addressForm.setValues({
                company,
                address,
                city,
                country,
                postalCode,
                taxNumber,
                phone,
            });
        }
    }, [profileData]);

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
        location.reload();
    };

    return (
        <div>
            <SimpleGrid cols={2} mb={16}>
                <PanelWithIcon icon={<IconSettings size={34} />}>
                    <ProfileTitle title={t('profile.account')} />
                    <form onSubmit={authForm.onSubmit((values) => updateAuth.mutate(values))}>
                        <Stack spacing="sm">
                            <TextInput
                                data-cy="profile-name"
                                label={t('profile.name')}
                                required
                                {...authForm.getInputProps('name')}
                            />

                            <TextInput
                                data-cy="profile-surname"
                                label={t('profile.surname')}
                                required
                                {...authForm.getInputProps('surname')}
                            />

                            <TextInput
                                disabled
                                label={t('profile.group')}
                                {...authForm.getInputProps('group')}
                            />

                            <TextInput
                                data-cy="profile-email"
                                label={t('profile.email')}
                                placeholder="your@email.com"
                                required
                                {...authForm.getInputProps('email')}
                            />

                            <PasswordInput
                                data-cy="profile-current-password"
                                label={t('profile.currentPassword')}
                                required
                                {...authForm.getInputProps('oldPassword')}
                            />

                            <PasswordInput
                                data-cy="profile-new-password"
                                label={t('profile.newPassword')}
                                {...authForm.getInputProps('newPassword')}
                            />

                            <Select
                                data={[
                                    { value: 'en', label: t('profile.english') },
                                    { value: 'de', label: t('profile.german') },
                                ]}
                                defaultValue={i18n.language}
                                label={t('profile.interfaceLanguage')}
                                onChange={handleLanguageChange}
                            />
                            <Checkbox
                                label={t('profile.subscribe')}
                                {...authForm.getInputProps('subscribe', { type: 'checkbox' })}
                            />
                            <Checkbox
                                description={t('profile.trainingConsentDescription')}
                                disabled={profileData?.canChangeTrainingConsent === false}
                                label={t('profile.trainingConsent')}
                                {...authForm.getInputProps('trainingConsent', { type: 'checkbox' })}
                            />
                        </Stack>
                        <Group mt="md" position="right">
                            <Button data-cy="profile-change-submit-button" type="submit">{t('common.submit')}</Button>
                        </Group>
                    </form>
                </PanelWithIcon>

                <PanelWithIcon icon={<IconCompass size={34} />}>
                    <ProfileTitle title={t('profile.profile')} />
                    <form onSubmit={addressForm.onSubmit((values) => updateProfile.mutate(values))}>
                        <Stack spacing="sm">
                            <TextInput
                                label={t('profile.company')}
                                {...addressForm.getInputProps('company')}
                            />
                            <TextInput
                                label={t('profile.address')}
                                {...addressForm.getInputProps('address')}
                            />
                            <TextInput
                                label={t('profile.postalCode')}
                                {...addressForm.getInputProps('postalCode')}
                            />
                            <TextInput
                                label={t('profile.city')}
                                {...addressForm.getInputProps('city')}
                            />
                            <CountrySelect
                                label={t('profile.country')}
                                required
                                {...addressForm.getInputProps('country')}
                            />
                            <TextInput
                                label={t('profile.tax')}
                                {...addressForm.getInputProps('taxNumber')}
                            />
                            <TextInput
                                label={t('profile.phone')}
                                placeholder={phoneNumberPlaceholder}
                                {...addressForm.getInputProps('phone')}
                            />
                        </Stack>

                        <Group mt="md" position="right">
                            <Button type="submit">{t('common.save')}</Button>
                        </Group>
                    </form>
                </PanelWithIcon>
                <TwoFactorPanel />
                <AccountRemovalPanel />
            </SimpleGrid>
        </div>
    );
};
