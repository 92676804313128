import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TWO_FACTOR_ENABLED } from '@/types/query-keys';

export const getTwoFactorStatus = async (): Promise<boolean> => {
    return (await axios.get('/users/2fa')).data;
};

type QueryFnType = typeof getTwoFactorStatus;

type UseTwoFactorStatusOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useOtpStatus = ({ config }: UseTwoFactorStatusOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [TWO_FACTOR_ENABLED],
        queryFn: getTwoFactorStatus,
        ...config,
    });
};
