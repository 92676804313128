import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { INSIGHTS } from '@/types/query-keys';

import { InsightDetailType } from '../types/insight-type';

export const getInsightsDetails = async (id?: number): Promise<InsightDetailType> => {
    return (await axios.get(`/insights/${id}`)).data;
};

type QueryFnType = typeof getInsightsDetails;

type UseInsightsDetailsOptions = {
    id?: number;
    config?: QueryConfig<QueryFnType>;
};

export const useInsightsDetails = ({ config, id }: UseInsightsDetailsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [INSIGHTS, id],
        queryFn: () => getInsightsDetails(id),
        ...config,
    });
};
