import { Button, Group, Text } from '@mantine/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from '@/components/Panel';
import { useRequestAccountRemove } from '@/features/user-profile/api/remove-account-request';

type AccountRemovalStep = 'init' | 'confirm' | 'mail';

export const AccountRemovalPanel: FC = () => {
    const { t } = useTranslation();
    const [step, setStep] = React.useState<AccountRemovalStep>('init');
    const deleteProfile = useRequestAccountRemove();

    const handleDelete = () => {
        setStep('mail');
        // @ts-ignore
        deleteProfile.mutate();
    };

    return (
        <Panel showCaption={false}>
            <Group position="apart" px={8}>
                <Text>{t('accountRemoval.title')}</Text>

                {step == 'init' && (
                    <Button color="red" data-cy="account-removal-start" onClick={() => setStep('confirm')} variant="outline">
                        {t('accountRemoval.start')}
                    </Button>
                )}

                {step == 'confirm' && (
                    <>
                        <Text>{t('accountRemoval.confirmQuestion')}</Text>
                        <Group position="right">
                            <Button color="red" data-cy="account-removal-confirm" onClick={() => handleDelete()}>
                                {t('accountRemoval.confirm')}
                            </Button>
                            <Button onClick={() => setStep('init')}>{t('common.cancel')}</Button>
                        </Group>
                    </>
                )}

                {step == 'mail' && <Text>{t('accountRemoval.confirmMail')}</Text>}
            </Group>
        </Panel>
    );
};
