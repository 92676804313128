import { Select, SelectProps } from '@mantine/core';
import React, { FC, useState } from 'react';

import { useUsersList } from '@/features/admin/api/users';

type UsernameSelectProps = Omit<SelectProps, 'data'>;

/**
 * Select component with users list, search by name and surname
 * @param props
 * @constructor
 */
export const UsernameSelect: FC<UsernameSelectProps> = (props) => {
    const [searchValue, setSearchValue] = useState('');
    const users = useUsersList({ userName: searchValue });

    const data =
        users.data?.map((u) => ({
            label: `${u.name} ${u.surname}`,
            value: u.id,
        })) ?? [];

    return (
        <Select
            {...props}
            clearable
            data={data}
            onSearchChange={setSearchValue}
            searchValue={searchValue}
            searchable
            withinPortal
        />
    );
};
