import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Dialog, Group, Text } from '@mantine/core';
import React from 'react';

interface ConfirmModelDeleteDialogProps {
    modelName: string;
    nodeName: string;
}

export const ConfirmInstanceDeleteDialog = NiceModal.create<ConfirmModelDeleteDialogProps>(
    ({ modelName, nodeName }) => {
        const modal = useModal();
        const { remove, visible, resolve } = modal;

        return (
            <Dialog onClose={() => remove()} opened={visible} radius="md" size="lg" withBorder>
                <Text size="sm" style={{ marginBottom: 10 }}>
                    Are you sure you want to delete &quot;{modelName}&quot; from &quot;{nodeName}
                    &quot;?
                </Text>

                <Group position="right">
                    <Button
                        color="red"
                        onClick={() => {
                            resolve(true);
                            remove();
                        }}
                    >
                        Yes
                    </Button>
                    <Button color="red" onClick={() => remove()} variant="outline">
                        No
                    </Button>
                </Group>
            </Dialog>
        );
    }
);
