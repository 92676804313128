import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { NODES } from '@/types/query-keys';

const deleteModelInstance = async (id: string): Promise<any> => {
    return (await axios.delete(`/admin/compute-models-instances/${id}`)).data;
};

type UseDeleteModelInstanceOptions = {
    config?: MutationConfig<typeof deleteModelInstance>;
};

export const useDeleteModelInstance = ({ config }: UseDeleteModelInstanceOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: deleteModelInstance,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [NODES] });
        },
    });
};
