import { showNotification } from '@mantine/notifications';

export const showClaimVoucherError = () => {
    showNotification({
        id: 'error-voucher',
        withCloseButton: true,
        autoClose: 5000,
        title: 'Error!',
        message: 'Voucher code is invalid or already taken',
        color: 'red',
    });
};

export const showClaimVoucherSuccess = () => {
    showNotification({
        id: 'success-voucher',
        withCloseButton: true,
        autoClose: 5000,
        title: 'Success!',
        message: 'The voucher has been collected successfully. Your license is now active.',
        color: 'primary',
    });
};
