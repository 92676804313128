import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { ADMIN_PRODUCTS } from '@/types/query-keys';

export interface UseMutationOrderProductDto {
    id: string;
    order: 'up' | 'down';
}

const orderProduct = async (data: UseMutationOrderProductDto): Promise<any> => {
    return (await axios.put(`/admin/products/${data.id}/order`, { order: data.order })).data;
};

type UseOrderProductOptions = {
    config?: MutationConfig<typeof orderProduct>;
};

export const useChangeProductOrder = ({ config }: UseOrderProductOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: orderProduct,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [ADMIN_PRODUCTS] });
        },
    });
};
