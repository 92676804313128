import { useInfiniteQuery } from '@tanstack/react-query';
import qs from 'qs';

import { useImagesContext } from '@/features/admin/components/images/ImagesContext';
import { ImagesFilterFormType } from '@/features/admin/components/images/ImagesFilterFormType';
import { ImageType } from '@/features/admin/types/imageType';
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { SYSTEM_IMAGES } from '@/types/query-keys';
import { removeNullishFields } from '@/utils/removeNullFields';

const getImagesWrapper = ({ pageParam = 1 }) => {
    return async (
        filter: ImagesFilterFormType | undefined
    ): Promise<{ data: ImageType[]; nextPage: number | null }> => {
        const filtered = removeNullishFields(filter);
        const query = qs.stringify({ ...filtered, pageParam }, { skipNulls: true });
        const response = await axios.get(`/admin/images?${query}`);

        const nextPage = parseInt(response.headers['x-next-page']);
        return {
            nextPage: isNaN(nextPage) ? null : nextPage,
            data: response.data,
        };
    };
};

type QueryFnType = ReturnType<typeof getImagesWrapper>;

type UseImagesOptions = {
    config?: QueryConfig<QueryFnType>;
    filter?: ImagesFilterFormType | undefined;
};

export const useInfinityImages = ({ config }: UseImagesOptions = {}) => {
    const { filter } = useImagesContext();

    const queryKey = [SYSTEM_IMAGES, ...Object.values(filter || {})];

    return useInfiniteQuery({
        queryKey,
        queryFn: (params) => getImagesWrapper(params)(filter),
        getNextPageParam: (lastPage, pages) => {
            return lastPage.nextPage;
        },
    });
};
