import { Group, List, Text } from '@mantine/core';
import React, { FC } from 'react';

import { useCountNodeJobs } from '@/features/admin/components/nodes/api/count-node-jobs';
import { GpuBadge } from '@/features/admin/components/nodes/GpuBadge';
import { NodeStatusBadge } from '@/features/admin/components/nodes/NodeStatusBadge';
import { PriorityChangeInput } from '@/features/admin/components/nodes/PriorityChangeInput';
import { StatProgress } from '@/features/admin/components/nodes/StatProgress';
import { NodeType } from '@/features/admin/types/nodeType';

interface NodeItemStatsProps {
    node: NodeType;
}

export const NodeItemStats: FC<NodeItemStatsProps> = ({ node }) => {
    const { data: jobsCount } = useCountNodeJobs({ id: node.id });

    return (
        <List
            listStyleType="none"
            mb={8}
            styles={{
                itemWrapper: {
                    width: '100%',
                },
            }}
        >
            <List.Item mb={8}>
                <Group>
                    <div>
                        Status: <NodeStatusBadge status={node.status} />
                    </div>
                    <div>
                        GPU: <GpuBadge hasGpu={node.hasGpu} />
                    </div>
                    <div>
                        <Group spacing={0}>
                            Priority:{' '}
                            <PriorityChangeInput
                                defaultValue={node.priority}
                                nodeId={node.id}
                            ></PriorityChangeInput>
                        </Group>
                    </div>
                </Group>
            </List.Item>
            <List.Item>
                <StatProgress label="CPU Utilization" value={node.stats?.[0]?.cpu}></StatProgress>
            </List.Item>
            <List.Item>
                <StatProgress label="RAM Utilization" value={node.stats?.[0]?.ram}></StatProgress>
            </List.Item>
            <List.Item>
                <StatProgress label="HDD Utilization" value={node.stats?.[0]?.disk}></StatProgress>
            </List.Item>
            <List.Item>
                <StatProgress label="VRAM Utilization" value={node.stats?.[0]?.vram}></StatProgress>
            </List.Item>
            <List.Item>
                <Text>Jobs:</Text>
                <ul>
                    <li>
                        Waiting: <strong>{jobsCount?.waiting || 0}</strong>
                    </li>
                    <li>
                        Active: <strong>{jobsCount?.active || 0}</strong>
                    </li>
                    <li>
                        Completed: <strong>{jobsCount?.completed || 0}</strong> in last 24h
                    </li>
                    <li>
                        Failed: <strong>{jobsCount?.failed || 0}</strong> in last 24h
                    </li>
                </ul>
            </List.Item>
        </List>
    );
};
