import { ActionIcon, Popover, Stack } from '@mantine/core';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlagGb } from '@/components/LangSelector/FlagGb';
import { FlagGermany } from '@/components/LangSelector/FlagGermany';

interface LangSelectorProps {
    color?: 'default' | 'dark';
    size?: number;
}

const popoverStyles = {
    dropdown: {
        backgroundColor: 'rgb(49,49,51)',
        borderColor: 'rgb(55, 58, 64)',
    },
};

interface FlagIconProps {
    lang: string;
    size: number;
}

const FlagIcon: FunctionComponent<FlagIconProps> = ({ lang, size }) => {
    switch (lang) {
        case 'en':
            return <FlagGb width={size} />;
        case 'de':
            return <FlagGermany width={size} />;
        default:
            return <FlagGb width={size} />;
    }
};

export const LangSelector: FunctionComponent<LangSelectorProps> = ({
    color = 'default',
    size = 24,
}) => {
    const [opened, setOpened] = useState(false);
    const { i18n } = useTranslation();

    const changeLanguage = (lang: 'en' | 'de') => {
        i18n.changeLanguage(lang);
        setOpened(false);
    };

    return (
        <Popover
            onChange={setOpened}
            opened={opened}
            position="bottom"
            shadow="md"
            styles={color == 'dark' ? popoverStyles : undefined}
        >
            <Popover.Target>
                <ActionIcon data-cy={'language-selector-button'} onClick={() => setOpened((o) => !o)}>
                    <FlagIcon lang={i18n.language} size={size} />
                </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown>
                <Stack>
                    <ActionIcon data-cy={'language-selector-button-en'} onClick={() => changeLanguage('en')} variant="subtle">
                        <FlagIcon lang="en" size={size} />
                    </ActionIcon>
                    <ActionIcon data-cy={'language-selector-button-de'} onClick={() => changeLanguage('de')} variant="subtle">
                        <FlagIcon lang="de" size={size} />
                    </ActionIcon>
                </Stack>
            </Popover.Dropdown>
        </Popover>
    );
};
