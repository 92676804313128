import { useQuery } from '@tanstack/react-query';

import { ModelNewsDetailType } from '@/features/admin/components/news/types/model-news-type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ADMIN_MODELS_NEWS } from '@/types/query-keys';

export const getModelNewsDetails = async (id?: number): Promise<ModelNewsDetailType> => {
    return (await axios.get(`/admin/compute-models-news/${id}`)).data;
};

type QueryFnType = typeof getModelNewsDetails;

type UseModelNewsDetailsOptions = {
    id?: number;
    config?: QueryConfig<QueryFnType>;
};

export const useModelNewsDetails = ({ config, id }: UseModelNewsDetailsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [ADMIN_MODELS_NEWS, id],
        queryFn: () => getModelNewsDetails(id),
        ...config,
    });
};
