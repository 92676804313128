import { ActionIcon, Group } from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import React, { FC } from 'react';

import { formatDate } from '@/lib/helpers';

import { InsightType } from './types/insight-type';

interface InsightsRowProps {
    item: InsightType;
    onDelete: (item: InsightType) => void;
    onEdit: (item: InsightType) => void;
}

export const InsightRow: FC<InsightsRowProps> = ({ item, onDelete, onEdit }) => {
    return (
        <tr>
            <td>{item.titleEn}</td>
            <td>{formatDate(item.createdAt)}</td>
            <td>
                <Group spacing={0}>
                    <ActionIcon onClick={() => onEdit(item)} radius="xl">
                        <IconPencil size={16}></IconPencil>
                    </ActionIcon>
                    <ActionIcon color="red" onClick={() => onDelete(item)} radius="xl">
                        <IconTrash size={16}></IconTrash>
                    </ActionIcon>
                </Group>
            </td>
        </tr>
    );
};
