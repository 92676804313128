import { useMutation } from '@tanstack/react-query';

import { showExampleResultsUpdated } from '@/features/admin/api/notifications';
import { ExampleResultsFormValues } from '@/features/admin/components/compute-models/types/example-results-form-values';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { MODEL_EXAMPLE_RESULTS } from '@/types/query-keys';

export type UseMutationUpdateExampleDto = ExampleResultsFormValues;

const updateExampleWrapper = (modelId: string | undefined) => {
    return async (data: UseMutationUpdateExampleDto) => {
        if (modelId === undefined) return;
        return (await axios.put(`/admin/compute-models/${modelId}/example`, data)).data;
    };
};

type UseUpdateExampleOptions = {
    modelId: string;
    config?: MutationConfig<ReturnType<typeof updateExampleWrapper>>;
};

export const useUpdateExample = ({ config, modelId }: UseUpdateExampleOptions) => {
    return useMutation({
        ...config,
        mutationFn: updateExampleWrapper(modelId),
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([MODEL_EXAMPLE_RESULTS, modelId]).then(() => {
                showExampleResultsUpdated();
            });
        },
    });
};
