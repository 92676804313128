import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { INSIGHTS } from '@/types/query-keys';

import { InsightType } from '../types/insight-type';

export const getsInsights = async (): Promise<InsightType[]> => {
    return (
        await axios.get('/insights', {
            params: {
                'without-details': true,
            },
        })
    ).data;
};

type QueryFnType = typeof getsInsights;

type UsesInsightsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useInsights = ({ config }: UsesInsightsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [INSIGHTS],
        queryFn: getsInsights,
        ...config,
    });
};
