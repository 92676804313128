import { Avatar, Tooltip } from '@mantine/core';
import React, { memo } from 'react';

import { UserAccount } from '@/features/admin/types/user-account';

interface UserAvatarProps {
    user: UserAccount;
}

const UserAvatarImpl = memo(({ user }: UserAvatarProps) => {
    const { verified, name, surname, preferences } = user;
    const permDel = preferences.removeDataPermanently;

    let avatarColor = '#00393d';
    let tooltip = undefined;

    if (!verified) {
        avatarColor = 'gray';
        tooltip = 'User not confirmed';
    } else if (permDel) {
        avatarColor = 'grape';
        tooltip = 'User with permanent data deletion';
    }

    const label = `${name.charAt(0).toUpperCase()} ${surname.charAt(0).toUpperCase()}`;

    const avatar = (
        <Avatar color={avatarColor} radius={30} size={30} variant="filled">
            {label}
        </Avatar>
    );

    return tooltip ? <Tooltip label={tooltip}>{avatar}</Tooltip> : avatar;
});

UserAvatarImpl.displayName = 'UserAvatar';
export const UserAvatar = UserAvatarImpl;
