import { Table } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { useAdminStyles } from '@/features/admin/components/admin-styles';
import { VoucherRow } from '@/features/admin/components/vouchers/VoucherRow';
import { Voucher } from '@/features/admin/types/voucher';

interface VoucherTableProps {
    vouchers: Voucher[];
    onDelete: (id: string) => void;
}

export const VoucherTable: FunctionComponent<VoucherTableProps> = ({ vouchers, onDelete }) => {
    const { classes, cx } = useAdminStyles();

    const rows = vouchers.map((voucher) => (
        <VoucherRow key={voucher.id} onDelete={onDelete} voucher={voucher} />
    ));

    return (
        <Table highlightOnHover>
            <thead className={cx(classes.header)}>
                <tr>
                    <th style={{ width: 250 }}>Key</th>
                    <th style={{ width: 150 }}>Created at</th>
                    <th style={{ width: 250 }}>Executions</th>
                    <th style={{ width: 250 }}>Valid until</th>

                    <th style={{ width: 250 }}>Taken by</th>
                    <th style={{ width: 50 }}>Action</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
};
