import { Route, Routes } from 'react-router-dom';

import { Profile } from '../component/Profile';

export const UserProfileRoutes = () => {
    return (
        <Routes>
            <Route element={<Profile />} path="" />
        </Routes>
    );
};
