import { Button, Menu, Table } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccessKeys } from '@/features/user-access-keys/api/access-keys';
import { useRemoveAccessKey } from '@/features/user-access-keys/api/remove-access-key';
import { formatDate, formatDateRelative } from '@/lib/helpers';

interface ActionButtonProps {
    id: number;
    onDelete: (id: number) => void;
}

const ActionButton = ({ id, onDelete }: ActionButtonProps) => {
    const { t } = useTranslation();

    return (
        <Menu shadow="md">
            <Menu.Target>
                <Button radius="xl" variant={'subtle'}>
                    <IconDotsVertical size={16} />
                </Button>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item onClick={() => onDelete(id)}>{t('userAccessKeys.remove')}</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};

export const AccessKeysList: FC = () => {
    const { data } = useAccessKeys();
    const removeAccessKey = useRemoveAccessKey();
    const { t, i18n } = useTranslation();

    const handleDelete = (id: number) => {
        removeAccessKey.mutate({ id });
    };

    const rows =
        data?.map((key, index) => (
            <tr key={key.id} style={!key.enabled ? { color: '#b6b6b6' } : undefined}>
                <td>{key.name}</td>
                <td>{formatDate(key.createdAt)}</td>
                <td>{formatDateRelative(key.lastUsed, i18n.language)}</td>
                <td>
                    <ActionButton id={key.id} onDelete={handleDelete} />
                </td>
            </tr>
        )) ?? [];

    return (
        <div>
            <Table>
                <thead>
                    <tr>
                        <th>{t('userAccessKeys.label')}</th>
                        <th>{t('userAccessKeys.enrollment')}</th>
                        <th>{t('userAccessKeys.last')}</th>
                        <th>{t('userAccessKeys.actions')}</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        </div>
    );
};
