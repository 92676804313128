import React, { FunctionComponent } from 'react';

import { BuyLicense } from '@/features/user-license/components/BuyLicense';
import { ClaimVoucher } from '@/features/user-license/components/ClaimVoucher';

export const Subscriptions: FunctionComponent = () => {
    return (
        <div>
            <BuyLicense />
            <ClaimVoucher />
        </div>
    );
};
