import { Global } from '@mantine/core';
import React, { FC } from 'react';

export const GlobalStyles: FC = () => {
    return (
        <Global
            styles={(theme) => ({
                '*, *::before, *::after': {
                    boxSizing: 'border-box',
                },

                body: {
                    ...theme.fn.fontStyles(),
                    backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.stone[1],
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                    lineHeight: theme.lineHeight,
                },

                a: {
                    color: theme.colors.forest[7],
                },
            })}
        />
    );
};
