import { Stack, Text } from '@mantine/core';
import { IconCloudComputing, IconServer2 } from '@tabler/icons-react';
import React, { FC } from 'react';

import styles from '@/features/admin/components/nodes/NodeItem.module.css';
import { NodeType } from '@/features/admin/types/nodeType';

interface NodeItemInfoProps {
    node: NodeType;
}

export const NodeItemInfo: FC<NodeItemInfoProps> = ({ node }) => {
    const NodeTypeIcon = node.nodeType === 'ON_PREMISE' ? IconServer2 : IconCloudComputing;

    return (
        <Stack align="center" spacing={0}>
            <NodeTypeIcon color="#ffffff" size={100} strokeWidth={1} />
            <Text className={styles.title} size="lg" sx={{ color: '#fff' }} weight={700}>
                {node.name}
            </Text>
            <Text mb={16} sx={{ color: '#fff' }}>
                {node.ip}
            </Text>
            <Text align="center" size="xs" sx={{ color: '#fff' }}>
                {node.description}
            </Text>
        </Stack>
    );
};
