import { useQuery } from '@tanstack/react-query';

import { ModelNewsType } from '@/features/admin/components/news/types/model-news-type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ADMIN_MODELS_NEWS } from '@/types/query-keys';

export const getModelsNews = async (): Promise<ModelNewsType[]> => {
    return (await axios.get('/admin/compute-models-news')).data;
};

type QueryFnType = typeof getModelsNews;

type UseModelsNewsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useModelsNews = ({ config }: UseModelsNewsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [ADMIN_MODELS_NEWS],
        queryFn: getModelsNews,
        ...config,
    });
};
