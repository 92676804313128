import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ADMIN_DEMO_CONFIG } from '@/types/query-keys';

export type DemoConfigType = {
    maxImages: number;
    demoDuration: number;
    extensionDuration: number;
    reminderBeforeExpiry: number;
    reminderBeforeExtensionExpiry: number;
};

export const getDemoConfig = async (): Promise<DemoConfigType> => {
    return (await axios.get('/admin/licenses/demo')).data;
};

type QueryFnType = typeof getDemoConfig;

type UseDemoConfigOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useDemoConfig = ({ config }: UseDemoConfigOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [ADMIN_DEMO_CONFIG],
        queryFn: getDemoConfig,
        ...config,
    });
};
