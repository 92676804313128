import { useModal } from '@ebay/nice-modal-react';
import { Table } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { useDeleteDockerImage } from '@/features/admin/api/delete-docker-image';
import { DockerImage } from '@/features/admin/types/docker-image';

import { useDockerImages } from '../../api/docker-images';
import { useUpdateAllInstances } from '../../api/update-all-instances';

import { ConfirmModelDeleteDialog } from './ConfirmModelDeleteDialog';
import { ConfirmModelInstallationDialog } from './ConfirmModelInstallationDialog';
import { RegistryRow } from './RegistryRow';

interface ModelsTableProps {
    modelName: string;
    modelId: string;
    imageName: string;
}

export const RegistryTable: FunctionComponent<ModelsTableProps> = ({
    modelName,
    imageName,
    modelId,
}) => {
    const installDialog = useModal(ConfirmModelInstallationDialog);
    const deleteDialog = useModal(ConfirmModelDeleteDialog);
    const dockerImages = useDockerImages({ imageName });
    const updateAllInstances = useUpdateAllInstances({ imageName: imageName });
    const deleteDockerImage = useDeleteDockerImage({ imageName: imageName });

    const data = dockerImages.data || [];

    const handleDelete = (model: DockerImage) => {
        deleteDialog.show({ modelName: modelName, modelVersion: model.tag }).then((result) => {
            if (result) {
                deleteDockerImage.mutate({ tag: model.tag, imageName: imageName });
            }
        });
    };

    const handleUpdate = (model: DockerImage) => {
        installDialog.show({ modelName: modelName, modelVersion: model.tag }).then((result) => {
            if (result) {
                updateAllInstances.mutate({
                    computeModelId: modelId,
                    version: model.tag,
                });
            }
        });
    };

    const rows = data.map((di: DockerImage) => (
        <RegistryRow
            dockerImage={di}
            key={di.tag}
            onDelete={(model) => {
                handleDelete(model);
            }}
            onUpdate={(model) => {
                handleUpdate(model);
            }}
        />
    ));

    return (
        <Table highlightOnHover>
            <thead>
                <tr>
                    <th>Tag</th>
                    <th style={{ width: 100 }}>Instances</th>
                    <th style={{ width: 80 }}>Action</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
};
