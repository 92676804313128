import { Button, Group, Input, Stack, Textarea } from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import React from 'react';
import { RequiredKeysOf } from 'type-fest';

import { TooltipRating } from '@/components/TooltipRating';

export interface AdminFeedbackFormValues {
    comment?: string;
    scaleNumber: number;
    scaleLength: number;
    resin: number;
    results: number;
}

interface UserFeedbackFormProps {
    onSubmit: (values: AdminFeedbackFormValues) => void;
    onCancel: () => void;
}

const starsDescriptions: Record<RequiredKeysOf<AdminFeedbackFormValues>, string[]> = {
    scaleNumber: [
        'Select rating',
        'No scale detected', // *
        'Wrong scale detected', // **
        'Nearly correct scale, but off', // ***
        'Slightly off scale detected', // ****
        'Correct scale detected', // *****
    ],
    scaleLength: [
        'Select rating',
        'Length greatly off many times', // *
        'Length off a few times', // **
        'Length off by some pixels', // ***
        'Length off by few pixels', // ****
        'Incorrect length detections', // *****
    ],
    resin: [
        'Select rating',
        'Incorrect resin detection', // *
        'Major resin mis-detection', // **
        'Moderate resin mis-detection', // ***
        'Minor resin mis-detection', // ****
        'Accurate resin detection', // *****
    ],
    results: [
        'Select rating',
        'Incorrect feature detection', // *
        'Major feature mis-detection', // **
        'Moderate feature mis-detection', // ***
        'Minor feature mis-detection', // ****
        'Accurate feature detection', // *****
    ],
};

const TooltipRatingWrapper: React.FC<{
    label: string;
    form: UseFormReturnType<AdminFeedbackFormValues>;
    name: string;
    onTooltipChange: (value: number) => string;
}> = ({ label, form, name, onTooltipChange }) => {
    const props = form.getInputProps(name);
    return (
        <Input.Wrapper error={props.error} label={label} required>
            <TooltipRating {...props} onTooltipChange={onTooltipChange} />
        </Input.Wrapper>
    );
};

export const AdminFeedbackForm: React.FC<UserFeedbackFormProps> = ({ onSubmit, onCancel }) => {
    const form = useForm<AdminFeedbackFormValues>({
        initialValues: {
            scaleNumber: 0,
            scaleLength: 0,
            resin: 0,
            results: 0,
            comment: '',
        },
        validate: {
            scaleNumber: (value) => (value > 0 ? null : 'Select the scale number rating'),
            scaleLength: (value) => (value > 0 ? null : 'Select the scale length rating'),
            resin: (value) => (value > 0 ? null : 'Select the resin detection rating'),
            results: (value) => (value > 0 ? null : 'Select the analysis results rating'),
        },
    });

    const handleScaleNumberChange =
        (key: RequiredKeysOf<AdminFeedbackFormValues>) => (value: number) =>
            starsDescriptions[key][value] ?? '';

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack spacing="sm">
                <TooltipRatingWrapper
                    form={form}
                    label="Scale number"
                    name="scaleNumber"
                    onTooltipChange={handleScaleNumberChange('scaleNumber')}
                />
                <TooltipRatingWrapper
                    form={form}
                    label="Scale length"
                    name="scaleLength"
                    onTooltipChange={handleScaleNumberChange('scaleLength')}
                />
                <TooltipRatingWrapper
                    form={form}
                    label="Resin"
                    name="resin"
                    onTooltipChange={handleScaleNumberChange('resin')}
                />
                <TooltipRatingWrapper
                    form={form}
                    label="Analysis results"
                    name="results"
                    onTooltipChange={handleScaleNumberChange('results')}
                />
                <Textarea {...form.getInputProps('comment')} label="Additional comments" />
                <Group mt="md" position="right">
                    <Button onClick={onCancel} variant="outline">
                        Cancel
                    </Button>
                    <Button type="submit">Submit</Button>
                </Group>
            </Stack>
        </form>
    );
};
