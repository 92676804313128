import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { SANDBOX } from '@/types/query-keys';

export interface UseMutationCreateSandboxJobsDto {
    imageId: string;
    modelsIds: string[];
}

const createSandboxJobs = async (data: UseMutationCreateSandboxJobsDto): Promise<any> => {
    return (await axios.post('/sandbox', data)).data;
};

type UseCreateSandboxJobsOptions = {
    config?: MutationConfig<typeof createSandboxJobs>;
};

export const useCreateSandboxJobs = ({ config }: UseCreateSandboxJobsOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: createSandboxJobs,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([SANDBOX]);
        },
    });
};
