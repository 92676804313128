import { Badge } from '@mantine/core';
import React, { FunctionComponent } from 'react';

export const YesNoBadge: FunctionComponent<{ value: boolean }> = ({ value }) => {
    return value ? (
        <Badge color="teal" variant="filled">
            Yes
        </Badge>
    ) : (
        <Badge color="red" variant="filled">
            No
        </Badge>
    );
};
