import { useMutation } from '@tanstack/react-query';

import { ModelNewsFormValues } from '@/features/admin/components/news/types/model-news-form-values';
import { ModelNewsDetailType } from '@/features/admin/components/news/types/model-news-type';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { ADMIN_MODELS_NEWS } from '@/types/query-keys';

const createModelNews = async (data: ModelNewsFormValues): Promise<ModelNewsDetailType> => {
    return (await axios.post('/admin/compute-models-news/', data)).data;
};

type UseCreateModelNewsOptions = {
    config?: MutationConfig<typeof createModelNews>;
};

export const useCreateModelNews = ({ config }: UseCreateModelNewsOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: createModelNews,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [ADMIN_MODELS_NEWS] });
        },
    });
};
