import { Center, Loader } from '@mantine/core';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AdminLayout } from '@/components/Layout/AdminLayout';
import { AdminComputeModels } from '@/features/admin/routes/AdminComputeModels';
import { AdminDashboard } from '@/features/admin/routes/AdminDashboard';
import { AdminGroups } from '@/features/admin/routes/AdminGroups';
import { AdminImages } from '@/features/admin/routes/AdminImages';
import { AdminInsights } from '@/features/admin/routes/AdminInsights';
import { AdminJob } from '@/features/admin/routes/AdminJob';
import { AdminJobs } from '@/features/admin/routes/AdminJobs';
import { AdminLicenses } from '@/features/admin/routes/AdminLicenses';
import { AdminModels } from '@/features/admin/routes/AdminModels';
import { AdminNews } from '@/features/admin/routes/AdminNews';
import { AdminNodes } from '@/features/admin/routes/AdminNodes';
import { AdminSettings } from '@/features/admin/routes/AdminSettings';
import { AdminStripe } from '@/features/admin/routes/AdminStripe';
import { AdminTranslations } from '@/features/admin/routes/AdminTranslations';
import { AdminUser } from '@/features/admin/routes/AdminUser';
import { AdminUsers } from '@/features/admin/routes/AdminUsers';
import { AdminVouchers } from '@/features/admin/routes/AdminVouchers';
import { lazyImport } from '@/utils/lazyImport';

const { AdminTerms } = lazyImport(() => import('@/features/admin/routes/AdminTerms'), 'AdminTerms');
const { MaskEditorWindow } = lazyImport(
    () => import('@/features/admin/components/jobs/MaskEditorWindow'),
    'MaskEditorWindow'
);

const AppAdmin = () => {
    return (
        <AdminLayout title="">
            <Suspense
                fallback={
                    <Center>
                        <Loader size="xl" variant="dots" />;
                    </Center>
                }
            >
                <Outlet />
            </Suspense>
        </AdminLayout>
    );
};

export const adminRoutes = [
    {
        path: '/admin/*',
        element: <AppAdmin />,
        children: [
            { path: '', element: <AdminDashboard /> },
            { path: 'users', element: <AdminUsers /> },
            { path: 'users/:id', element: <AdminUser /> },
            { path: 'users/:id/:tab', element: <AdminUser /> },
            { path: 'jobs/:id', element: <AdminJob /> },
            { path: 'groups', element: <AdminGroups /> },
            { path: 'vouchers', element: <AdminVouchers /> },
            { path: 'licenses', element: <AdminLicenses /> },
            { path: 'resources', element: <AdminNodes /> },
            { path: 'registry', element: <AdminModels /> },
            { path: 'compute-compute-models-registry', element: <AdminComputeModels /> },
            { path: 'stripe', element: <AdminStripe /> },
            { path: 'terms', element: <AdminTerms /> },
            { path: 'images', element: <AdminImages /> },
            { path: 'settings', element: <AdminSettings /> },
            { path: 'jobs', element: <AdminJobs /> },
            { path: 'translations', element: <AdminTranslations /> },
            { path: 'news', element: <AdminNews /> },
            { path: 'insights', element: <AdminInsights /> },
        ],
    },
    {
        path: '/admin/mask-editor',
        element: <MaskEditorWindow />,
    },
];
