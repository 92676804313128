import React from 'react';

import { useAdminStyles } from '../components/admin-styles';
import { Vouchers } from '../components/vouchers/Vouchers';

export const AdminVouchers = () => {
    const { classes } = useAdminStyles();
    return (
        <div className={classes.container}>
            <Vouchers />
        </div>
    );
};
