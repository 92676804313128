import { useIntersection } from '@mantine/hooks';
import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';

import { YesNoBadge } from '@/components/YesNoBadge';
import { getImageThumbnailUrl, getImageUrl } from '@/config';
import { ImageType } from '@/features/admin/types/imageType';
import { OverlayImage } from '@/features/history/components/OverlayImage';
import { LinkToModal } from '@/features/models/component/LinkToModal';

interface ImageRowProps {
    image: ImageType;
}

export const ImageRow: FunctionComponent<ImageRowProps> = ({ image }) => {
    const { ref, entry } = useIntersection({
        threshold: 1,
    });

    const createdAt = dayjs(image.createdAt).format('YYYY-MM-DD HH:mm:ss');
    const thumbnailUrl = getImageThumbnailUrl(image.id);
    const imageUrl = getImageUrl(image.id);

    return (
        <tr>
            <td>
                <LinkToModal caption={image.orginalFilename} imageUrl={imageUrl}>
                    <OverlayImage
                        fit="cover"
                        height={50}
                        imageRef={ref}
                        src={entry?.isIntersecting ? thumbnailUrl : undefined}
                        width={200}
                        withPlaceholder
                    ></OverlayImage>
                </LinkToModal>
            </td>
            <td>{createdAt}</td>
            <td>{image.hash?.slice(0, 10)}...</td>
            <td>
                {image.owner.name} {image.owner.surname}
            </td>
            <td>
                <YesNoBadge value={image.allowedTrainingUse} />
            </td>
            <td data-cy="admin-panel-image-section-table-deleted-tag">
                <YesNoBadge value={image.isDeleted} />
            </td>
        </tr>
    );
};
