import { Group, Text } from '@mantine/core';
import React, { FC } from 'react';

import { UserBasicInfoType, UserFeedback } from '@/features/admin/types/user-jobs';

interface UserFeedbackProps {
    owner: UserBasicInfoType;
    data: UserFeedback | undefined;
}

export const JobInfoUserFeedback: FC<UserFeedbackProps> = ({ data, owner }) => {
    if (!data) {
        return null;
    }

    const { rating, comment } = data;

    return (
        <Group spacing={'md'}>
            <div style={{ fontSize: 26, fontWeight: 500 }}>{rating.toFixed(1)}</div>
            <div>
                <Text fw={500} size="sm">
                    {owner.name} {owner.surname}
                </Text>
                {comment && <Text size="xs">{comment}</Text>}
            </div>
        </Group>
    );
};
