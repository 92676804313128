import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Dialog, Group, Text } from '@mantine/core';
import React from 'react';

interface ConfirmModelDeleteDialogProps {
    modelName: string;
    modelVersion: string;
}

export const ConfirmModelInstallationDialog = NiceModal.create<ConfirmModelDeleteDialogProps>(
    ({ modelName, modelVersion }) => {
        const modal = useModal();
        const { hide, visible, resolve } = modal;

        return (
            <Dialog onClose={() => hide()} opened={visible} radius="md" size="lg" withCloseButton>
                <Text size="sm" style={{ marginBottom: 10 }}>
                    Are you sure you want to install {modelName} ({modelVersion})?
                </Text>

                <Group position="right">
                    <Button
                        onClick={() => {
                            resolve(true);
                            hide();
                        }}
                    >
                        Yes
                    </Button>
                    <Button onClick={() => hide()} variant="outline">
                        No
                    </Button>
                </Group>
            </Dialog>
        );
    }
);
