import { Tooltip } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import React, { FC } from 'react';

interface AdminTooltipProps {
    label: string;
    width?: number;
}

export const AdminTooltip: FC<AdminTooltipProps> = ({ label, width = 300 }) => {
    return (
        <Tooltip label={label} multiline width={width} withArrow>
            <div>
                <IconAlertCircle size={18} style={{ display: 'block', opacity: 0.5 }} />
            </div>
        </Tooltip>
    );
};
