import { axios } from '@/lib/axios';

export interface UseMutationMakeLinkDto {
    licenseId: string;
}

interface Link {
    url: string;
}

export const makeStripeLink = async (data: UseMutationMakeLinkDto): Promise<Link> => {
    return (await axios.post('/payments/link', data)).data;
};
