import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mantine/core';

import { useUpdateManual } from '@/features/admin/api/update-manual';
import { ComputeModelInfoForm } from '@/features/admin/components/compute-models/ComputeModelInfoForm';
import { ComputeModel } from '@/features/admin/types/compute-model';
import { UpdateComputeModelManualDto } from '@/features/admin/types/manual';

interface ComputeModelInfoModalProps {
    model: ComputeModel;
}

export const ComputeModelInfoModal = NiceModal.create<ComputeModelInfoModalProps>(({ model }) => {
    const update = useUpdateManual({ modelId: model.id });

    const modal = useModal();
    const { remove, visible } = modal;

    const handleSubmit = (values: UpdateComputeModelManualDto) => {
        update.mutate(values);
    };
    const handleCancel = () => {
        remove();
    };

    return (
        <Modal
            centered
            onClose={() => remove()}
            opened={visible}
            size={800}
            title={model.displayName + ': User manual'}
        >
            <ComputeModelInfoForm
                modelId={model.id}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
            />
        </Modal>
    );
});
