import { useMutation } from '@tanstack/react-query';
import { SupportedLanguage } from 'mivia-shared';

import { showMaintenanceModeUpdated } from '@/features/admin/api/notifications';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { APP_ADMIN_SETTINGS } from '@/types/query-keys';

export type UpdateMaintenanceMessageDto = {
    maintenanceMessage?: Record<SupportedLanguage, string>;
    maintenanceModels?: string[];
};

const updateMaintenance = async (data: UpdateMaintenanceMessageDto): Promise<any> => {
    return (await axios.put('/admin/settings/maintenance', data)).data;
};

type UseUpdateMaintenanceOptions = {
    config?: MutationConfig<typeof updateMaintenance>;
};

export const useUpdateMaintenanceMode = ({ config }: UseUpdateMaintenanceOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: updateMaintenance,
        onSuccess: (data: any) => {
            showMaintenanceModeUpdated();
            return queryClient.invalidateQueries({ queryKey: [APP_ADMIN_SETTINGS] });
        },
    });
};
