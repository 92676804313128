import { useMutation } from '@tanstack/react-query';

import { Voucher } from '@/features/admin/types/voucher';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { VOUCHERS } from '@/types/query-keys';

const deleteVoucher = async (id: string): Promise<Voucher[]> => {
    return (await axios.delete(`/admin/vouchers/${id}`)).data;
};

type UseCreateVoucherOptions = {
    config?: MutationConfig<typeof deleteVoucher>;
};

export const useDeleteVoucher = ({ config }: UseCreateVoucherOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: deleteVoucher,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [VOUCHERS] });
        },
    });
};
