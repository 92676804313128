import { useMutation } from '@tanstack/react-query';

import { showPriorityUpdateSuccess } from '@/features/admin/api/notifications';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { NODES } from '@/types/query-keys';

const updatePriority = async ({ nodeId, priority }: { nodeId: string; priority: number }) => {
    return (await axios.put(`/admin/nodes/${nodeId}/priority`, { priority })).data;
};

type UseUpdatePriorityOptions = {
    config?: MutationConfig<typeof updatePriority>;
};

export const useUpdatePriority = ({ config }: UseUpdatePriorityOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: updatePriority,
        onSuccess: (data: any) => {
            showPriorityUpdateSuccess();
            queryClient.invalidateQueries({ queryKey: [NODES] });
        },
    });
};
