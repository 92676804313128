import { useModal } from '@ebay/nice-modal-react';
import { Table } from '@mantine/core';
import React, { FC } from 'react';

import { useComputeModels } from '@/features/admin/api/compute-models';
import { useDeleteComputeModel } from '@/features/admin/api/delete-compute-model';
import { ComputeModelExampleModal } from '@/features/admin/components/compute-models/ComputeModelExampleModal';
import { ComputeModelInfoModal } from '@/features/admin/components/compute-models/ComputeModelInfoModal';
import { ComputeModelModal } from '@/features/admin/components/compute-models/ComputeModelModal';
import { ComputeModelsRow } from '@/features/admin/components/compute-models/ComputeModelsRow';
import { ConfirmComputeModelDeleteDialog } from '@/features/admin/components/compute-models/ConfirmComputeModelDeleteDialog';
import { ComputeModel } from '@/features/admin/types/compute-model';

export const ComputeModelsTable: FC = () => {
    const { data } = useComputeModels();
    const deleteModel = useDeleteComputeModel();
    const deleteDialog = useModal(ConfirmComputeModelDeleteDialog);
    const editDialog = useModal(ComputeModelModal);
    const infoDialog = useModal(ComputeModelInfoModal);
    const exampleDialog = useModal(ComputeModelExampleModal);

    const handleDeleteModel = (model: ComputeModel) => {
        deleteDialog.show({ modelName: model.displayName }).then((result) => {
            if (result) {
                deleteModel.mutate(model.id);
            }
        });
    };

    const handleEditModel = (model: ComputeModel) => {
        editDialog.show({ initialValues: model, computeModelId: model.id });
    };

    const handleInfoModel = (model: ComputeModel) => {
        infoDialog.show({ model });
    };

    const handleExampleResults = (model: ComputeModel) => {
        exampleDialog.show({ model });
    };

    const rows =
        data?.map((item) => (
            <ComputeModelsRow
                key={item.id}
                model={item}
                onDelete={handleDeleteModel}
                onEdit={handleEditModel}
                onExample={handleExampleResults}
                onInfo={handleInfoModel}
            ></ComputeModelsRow>
        )) || [];

    return (
        <Table highlightOnHover>
            <thead>
                <tr>
                    <th style={{ width: '20%' }}>Name</th>
                    <th style={{ width: '20%' }}>Docker image</th>
                    <th style={{ width: '15%' }}>Access</th>
                    <th style={{ width: '5%' }}>Usage last week</th>
                    <th style={{ width: '5%' }}>Usage last month</th>
                    <th style={{ width: '5%' }}>Usage total</th>
                    <th style={{ width: '5%' }}>Trainable</th>
                    <th style={{ width: '15%', textAlign: 'right' }}>Actions</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
};
