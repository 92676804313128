import { ActionIcon, Button, Flex, Menu, Switch } from '@mantine/core';
import { useForm } from '@mantine/form';
import {
    IconCaretDown,
    IconDownload,
    IconEraser,
    IconEraserOff,
    IconFilterSearch,
    IconSchool,
    IconSchoolOff,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import qs from 'qs';
import React, { FC } from 'react';

import { MaskedDateInput } from '@/components/MaskedDateInput';
import { REACT_APP_API_BASE_URL } from '@/config';
import { UsernameSelect } from '@/features/admin/components/jobs/UsernameTextInput';
import { removeEmpty } from '@/lib/helpers';

import { useImagesContext } from './ImagesContext';
import { ImagesFilterFormType } from './ImagesFilterFormType';

export const ImagesFilterForm: FC = () => {
    const { setFilter } = useImagesContext();

    const form = useForm<ImagesFilterFormType>({
        initialValues: {
            startDate: undefined,
            endDate: dayjs().format('YYYY-MM-DD'),
            allowedTrainingUse: false,
            userId: '',
        },
    });

    const handleSubmit = (values: ImagesFilterFormType) => {
        setFilter(values);
    };

    const setStartDateMinus = (days: number) => {
        // If endDate is not set or invalid, set it to today
        const endDateValueDate = dayjs(form.values.endDate);
        const endDate = endDateValueDate.isValid() ? endDateValueDate : dayjs();
        form.setFieldValue('endDate', endDate.format('YYYY-MM-DD'));

        // Set startDate to endDate - days
        const startDate = endDate.subtract(days, 'day').format('YYYY-MM-DD');
        form.setFieldValue('startDate', startDate);
    };

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Flex align="center" direction="row" gap="xs" justify="flex-end" wrap="wrap">
                <UsernameSelect {...form.getInputProps('userId')} placeholder="Filter by user" />
                <MaskedDateInput
                    placeholder="Start date"
                    rightSection={
                        <Menu>
                            <Menu.Target>
                                <ActionIcon variant="transparent">
                                    <IconCaretDown size="1.05rem"></IconCaretDown>
                                </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Item onClick={() => setStartDateMinus(1)}>
                                    Since yesterday
                                </Menu.Item>
                                <Menu.Item onClick={() => setStartDateMinus(7)}>
                                    Last 7 days
                                </Menu.Item>
                                <Menu.Item onClick={() => setStartDateMinus(30)}>
                                    Last 30 days
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    }
                    style={{ width: 135 }}
                    {...form.getInputProps('startDate')}
                />
                <MaskedDateInput
                    placeholder="End date"
                    style={{ width: 110 }}
                    {...form.getInputProps('endDate')}
                />
                <Switch
                    {...form.getInputProps('allowedTrainingUse')}
                    offLabel={<IconSchoolOff />}
                    onLabel={<IconSchool />}
                    size={'xl'}
                    styles={{ root: { display: 'inline-flex' } }}
                />
                <Button
                    leftIcon={<IconFilterSearch />}
                    styles={{
                        root: { width: 100 },
                    }}
                    type="submit"
                >
                    Filter
                </Button>
                <Button
                    component="a"
                    href={`${REACT_APP_API_BASE_URL}/admin/images/zip?${qs.stringify(
                        removeEmpty(form.values)
                    )}`}
                    leftIcon={<IconDownload />}
                >
                    Download
                </Button>
            </Flex>
        </form>
    );
};
