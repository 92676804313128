import { SimpleGrid } from '@mantine/core';
import React, { FC } from 'react';

import { DemoPanel } from '@/features/admin/components/settings/DemoPanel';

import { MaintenancePanel } from './MaintenancePanel';
import { ServicesPanel } from './ServicesPanel';

export const Settings: FC = () => {
    return (
        <SimpleGrid
            breakpoints={[
                { maxWidth: 'md', cols: 1, spacing: 'md' },
                { maxWidth: 'sm', cols: 1, spacing: 'sm' },
                { maxWidth: 'xs', cols: 1, spacing: 'sm' },
            ]}
            cols={2}
        >
            <MaintenancePanel />
            <ServicesPanel />
            <DemoPanel />
        </SimpleGrid>
    );
};
