import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
    Text,
    List,
    Group,
    ScrollArea,
    Modal,
    FileButton,
    Button,
    Flex,
    Table,
    ActionIcon,
    Stack,
    Progress,
} from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconPhotoCircle, IconTrash } from '@tabler/icons-react';
import { Editor } from '@tiptap/react';
import { AxiosProgressEvent } from 'axios';
import React, { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';

import { PublicImage, usePublicImages } from '@/components/TextEditor/api/public-images';
import { useRemovePublicImage } from '@/components/TextEditor/api/remove-public-image';
import { useUploadPublicImage } from '@/components/TextEditor/api/upload-public-image';
import { getImageUrl } from '@/config';

interface TextEditorImageProps {
    editor: Editor;
}

export const TextEditorImageModal = NiceModal.create<TextEditorImageProps>(({ editor }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const modal = useModal();
    const { data: files } = usePublicImages();
    const upload = useUploadPublicImage();
    const remove = useRemovePublicImage();
    const [file, setFile] = useState<File | null>(null);
    const viewport = useRef<HTMLDivElement>(null);
    const { hide, visible } = modal;

    const handleUploadProgress = (event: AxiosProgressEvent) => {
        let percent = 0;
        if (event.total) {
            percent = (100 * event.loaded) / event.total;
        }
        setUploadProgress(percent);
    };

    useEffect(() => {
        if (file) {
            upload.mutate(
                { file, progressCallBack: handleUploadProgress },
                {
                    onSuccess: () => {
                        setFile(null);
                    },
                }
            );
        }
    }, [file]);

    const handleInsert = (e: React.MouseEvent<HTMLAnchorElement>, file: PublicImage) => {
        e.preventDefault();
        const url = getImageUrl(`public/${file.filename}`);
        editor.commands.setImage({ src: url, alt: file.metadata['X-Amz-Meta-Filename'] });
        hide();
    };

    const rows = files?.map((file) => (
        <tr key={file.filename}>
            <td>
                <a href="#" onClick={(e) => handleInsert(e, file)}>
                    {file.metadata['X-Amz-Meta-Filename']}
                </a>
            </td>
            <td>
                <ActionIcon color="red" onClick={() => remove.mutate({ filename: file.filename })}>
                    <IconTrash />
                </ActionIcon>
            </td>
        </tr>
    ));

    return (
        <Modal
            centered
            onClose={() => hide()}
            opened={visible}
            size={600}
            title="Upload or select image"
        >
            <Stack>
                <div>
                    <FileButton accept="image/png,image/jpeg" onChange={setFile}>
                        {(props) => (
                            <Button {...props} radius={0} w={'100%'}>
                                Upload image
                            </Button>
                        )}
                    </FileButton>
                    <Progress color="orange" size="xs" value={uploadProgress} />
                </div>
                <div>
                    <ScrollArea h={250} type="auto" viewportRef={viewport}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Filename</th>
                                    <th style={{ width: 50 }}>Remove</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </ScrollArea>
                </div>
            </Stack>
        </Modal>
    );
});

export const TextEditorImage: FC = () => {
    const { editor } = useRichTextEditorContext();
    const modal = useModal(TextEditorImageModal);

    return (
        <RichTextEditor.Control aria-label="Settings" onClick={() => modal.show({ editor })}>
            <IconPhotoCircle size="1rem" stroke={1.5} />
        </RichTextEditor.Control>
    );
};
