import { useModal } from '@ebay/nice-modal-react';
import React, { FunctionComponent } from 'react';

import { ImageModal } from '@/components/Layout/ImageModal';

interface LinkToModalProps {
    imageUrl: string | undefined;
    caption: string | undefined;
    children: React.ReactNode;
}

export const LinkToModal: FunctionComponent<LinkToModalProps> = ({
    imageUrl,
    caption,
    children,
}) => {
    const imageModal = useModal(ImageModal);

    // Here, <a> is used instead of Link, because in modal React.Context is not available and error is thrown
    // Alternative: https://opensource.ebay.com/nice-modal-react/#declarative
    return (
        <a
            href=""
            onClick={(e) => {
                e.preventDefault();
                imageModal.show({
                    imageUrl,
                    caption,
                });
            }}
        >
            {children}
        </a>
    );
};
