import { useMutation } from '@tanstack/react-query';

import {
    showProductFailedToUpdatedNotification,
    showProductUpdatedSuccessfullyNotification,
} from '@/features/admin/api/notifications';
import {
    AdminProductType,
    ProductFormValues,
} from '@/features/admin/components/stripe/types/license-product';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { AxiosErrorMessage } from '@/types/axios-error-message';
import { ADMIN_PRODUCTS } from '@/types/query-keys';

const updateProductWrapper = (id: string | undefined) => {
    return async (data: ProductFormValues): Promise<AdminProductType | undefined> => {
        if (id === undefined) return;
        return (await axios.put(`/admin/products/${data.id}`, data)).data;
    };
};

type QueryFnType = ReturnType<typeof updateProductWrapper>;

type UseUpdateProductOptions = {
    id: string | undefined;
    config?: MutationConfig<QueryFnType>;
};

export const useUpdateProduct = ({ config, id }: UseUpdateProductOptions) => {
    return useMutation({
        ...config,
        mutationFn: updateProductWrapper(id),
        onError: (error: AxiosErrorMessage, data, _) => {
            const message = error.response?.data?.message?.join(', ') || '';
            showProductFailedToUpdatedNotification(message);
            config?.onError?.(error, data, _);
        },
        onSuccess: (data: any, variables: any, context: any) => {
            showProductUpdatedSuccessfullyNotification();
            queryClient.invalidateQueries({ queryKey: [ADMIN_PRODUCTS] });
            config?.onSuccess?.(data, variables, context);
        },
    });
};
