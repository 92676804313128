import { Paper } from '@mantine/core';
import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { REACT_APP_FRONTEND_URL } from '@/config';

export const AccessKeysInfo: FC = () => {
    const url = REACT_APP_FRONTEND_URL + '/docs';
    const ghUrl = 'https://github.com/MiViA-GmbH/MiViA-API/releases';

    return (
        <Paper px={16} py={16} style={{ fontSize: '0.9rem' }} ta={'justify'}>
            <Trans i18nKey="userAccessKeys.info">
                MiViA offers API keys to allow users secure and streamlined access to its core
                functionalities. With an API key, users can effortlessly integrate their systems or
                applications with MiViA’s versatile features, optimizing their operational
                efficiency and productivity.
                <br />
                The API documentation is made available in the OpenAPI format. Users looking to
                explore the API documentation can access it through the following link:{' '}
                <a href={url}>MiViA OpenAPI Documentation</a>.
                <br />
                For a successful and secure API integration, users are advised to manage and protect
                their API keys diligently, refraining from sharing them publicly or with
                unauthorized parties.
                <br />
                To provide a practical example of how our API can be used, we have also developed a
                desktop client for Microsoft Windows that uses the MiViA API. This sample client,
                along with its source code in C#, is available for download directly from{' '}
                <a href={ghUrl}>GitHub</a>.
            </Trans>
        </Paper>
    );
};
