import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Center, Image, Loader, Modal } from '@mantine/core';
import React from 'react';

interface ImageModalProps {
    imageUrl: string | undefined;
    caption?: string | undefined;
}

export const ImageModal = NiceModal.create<ImageModalProps>(({ imageUrl, caption }) => {
    const modal = useModal();

    return (
        <Modal
            centered
            onClose={() => modal.hide()}
            opened={modal.visible}
            size={850}
            title={caption}
        >
            <div style={{ width: 800, marginLeft: 'auto', marginRight: 'auto' }}>
                <Image
                    alt="Image preview"
                    placeholder={
                        <Center>
                            <Loader size="xl" variant="dots" />
                        </Center>
                    }
                    radius="md"
                    src={imageUrl}
                    withPlaceholder
                />
            </div>
        </Modal>
    );
});
