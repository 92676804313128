import Session from 'supertokens-web-js/recipe/session';

export const handleSessionExpired = () => {
    // console.log(`Session expired at ${window.location.pathname}`);
    if (!window.location.pathname.includes('auth') && window.location.pathname != '/')
        window.location.assign('/auth/login');
};
export const logout = async () => {
    await Session.signOut();
    // return (await axios.post('/auth/signout')).data;
};
