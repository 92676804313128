import { useMutation } from '@tanstack/react-query';

import { Group } from '@/features/admin/types/group';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { GROUPS } from '@/types/query-keys';

export interface UseMutationCreateGroupDto {
    name: string;
}

export const createJob = async (data: UseMutationCreateGroupDto): Promise<Group> => {
    return (await axios.post('/admin/groups', data)).data;
};

type UseCreateGroupOptions = {
    config?: MutationConfig<typeof createJob>;
};

export const useCreateGroup = ({ config }: UseCreateGroupOptions = {}) => {
    return useMutation({
        ...config,
        onSuccess: (data: Group, ...rest) => {
            queryClient.invalidateQueries({ queryKey: [GROUPS] });
        },
        mutationFn: createJob,
    });
};
