import { useQuery } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { COMPUTE_MODELS_STATE } from '@/types/query-keys';

export const getComputeModelsState = async (): Promise<boolean> => {
    return (await axios.get('/admin/compute-models/is-updating')).data;
};

type QueryFnType = typeof getComputeModelsState;

type UseComputeModelsStateOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useComputeModelsState = ({ config }: UseComputeModelsStateOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [COMPUTE_MODELS_STATE],
        queryFn: getComputeModelsState,
        refetchInterval: 3000,
        ...config,
    });
};
