import {
    Button,
    Center,
    Group,
    Loader,
    MultiSelect,
    Select,
    SelectItem,
    Table,
} from '@mantine/core';
import React, { useState } from 'react';

import { useAssignToGroup } from '@/features/admin/api/assign-group';
import { useCreateGroup } from '@/features/admin/api/create-group';
import { useEditGroup } from '@/features/admin/api/edit-group';
import { useGroups } from '@/features/admin/api/groups';
import { useQueryUsers } from '@/features/admin/api/list-users';
import { GroupNameField } from '@/features/admin/components/GroupNameField';
import { Group as UsersGroup } from '@/features/admin/types/group';

import { useAdminStyles } from './admin-styles';

export function Groups() {
    const groupsQuery = useGroups();
    const usersQuery = useQueryUsers();
    const createGroup = useCreateGroup();
    const editGroup = useEditGroup();
    const assignToGroup = useAssignToGroup();
    const [addGroup, setAddGroup] = useState(false);

    const { classes, cx } = useAdminStyles();

    if (!groupsQuery.data || !usersQuery.data) {
        return (
            <Center>
                <Loader size="xl" variant="dots" />;
            </Center>
        );
    }

    const selectableUsers = usersQuery.data.map((u) => ({
        label: u.name,
        value: u.id,
        company: u.address?.company,
    }));

    const handleGroupAdminChange = (group: UsersGroup, userId: string | null) => {
        editGroup.mutate({ id: group.id, adminId: userId, name: group.name });
    };

    const handleGroupMembersChange = (groupId: string, usersIds: string[]) => {
        assignToGroup.mutate({ groupId, usersIds });
    };

    const handleMemberSearch = (value: string, selected: boolean, item: SelectItem): boolean =>
        item.company?.toLowerCase().includes(value) || item.value.toLowerCase().includes(value);

    if (addGroup) {
        groupsQuery.data.push({} as any);
    }

    const rows = groupsQuery.data.map((group) => (
        <tr key={group?.id || 'new-grp'}>
            <td>
                <GroupNameField
                    name={group.name}
                    onEditFinished={(name) => {
                        createGroup.mutate({ name });
                        setAddGroup(false);
                    }}
                />
            </td>
            <td>
                <Select
                    clearable={true}
                    data={selectableUsers}
                    defaultValue={group.adminId}
                    onChange={(value) => handleGroupAdminChange(group, value)}
                    searchable
                    variant="unstyled"
                ></Select>
            </td>
            <td>
                <MultiSelect
                    data={selectableUsers}
                    defaultValue={group.users?.map((u) => u.id)}
                    filter={handleMemberSearch}
                    onChange={(value) => handleGroupMembersChange(group.id, value)}
                    searchable
                    variant="unstyled"
                />
            </td>
        </tr>
    ));

    return (
        <>
            <Group position="right">
                <Button onClick={() => setAddGroup(true)}>Add group</Button>
            </Group>

            {/*<ScrollArea*/}
            {/*    sx={{ height: '100%' }}*/}
            {/*    onScrollPositionChange={({ y }) => setScrolled(y !== 0)}*/}
            {/*>*/}
            <Table highlightOnHover>
                <thead className={cx(classes.header)}>
                    <tr>
                        <th style={{ width: 250 }}>Name</th>
                        <th style={{ width: 250 }}>Admin</th>
                        <th>Members</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        </>
    );
}
