import { HistogramResultType } from 'mivia-shared';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Histogram } from '@/features/models/component/Histogram';
import { useChartStyles } from '@/features/models/component/useChartStyles';

export interface HistogramWrapperProps {
    results: HistogramResultType;
}

export const HistogramWrapper: FunctionComponent<HistogramWrapperProps> = ({ results }) => {
    // const barChartModal = useModal(BarChartModal);
    const { t } = useTranslation();
    const { classes } = useChartStyles();

    const xLabelTranslated = t(`model.${results.xLabel}`);
    const yLabelTranslated = t(`model.${results.yLabel}`);
    return (
        <div className={classes.wrapper}>
            <div className={classes.title}>
                <span>{t(`model.${results.label}`)}</span>
            </div>
            <Histogram data={results.data} xLabel={xLabelTranslated} yLabel={yLabelTranslated} />
            {/*<ActionIcon*/}
            {/*    className={classes.icon}*/}
            {/*    onClick={() =>*/}
            {/*        barChartModal.show({*/}
            {/*            data: data,*/}
            {/*            activeKey: key,*/}
            {/*        })*/}
            {/*    }*/}
            {/*>*/}
            {/*    <IconArrowsMaximize size={20} />*/}
            {/*</ActionIcon>*/}
        </div>
    );
};
