import { Alert } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import React, { FunctionComponent } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ContentMiviaLoader } from '@/components/ContentMiviaLoader';
import { Panel } from '@/components/Panel';
import { useQueryPerformance } from '@/features/admin/api/performance';
import { LineChart } from '@/features/admin/components/dashboard/LineChart';

interface NodesPerformancePanelProps {
    nodeId: string;
}

function ErrorFallback({ error }: { error: Error }) {
    return (
        <Alert color="red" icon={<IconAlertCircle size={16} />} title="Something went wrong!">
            <pre>{error.message}</pre>
        </Alert>
    );
}

export const NodesPerformancePanel: FunctionComponent<NodesPerformancePanelProps> = ({
    nodeId,
}) => {
    const performanceQuery = useQueryPerformance({ nodeId });

    const data = performanceQuery.data;

    if (!data || performanceQuery.isLoading) {
        return (
            <Panel height={500} title={`Loading...`} withBorder>
                <ContentMiviaLoader />
            </Panel>
        );
    }

    return (
        <Panel height={500} title={`Performance of '${data.name}'`} withBorder>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <LineChart data={data.stats} />
            </ErrorBoundary>
        </Panel>
    );
};
