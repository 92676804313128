import { Code, createStyles, getStylesRef, Group, Navbar, px } from '@mantine/core';
import {
    IconBrandFeedly,
    IconBrandStripe,
    IconChecklist,
    IconCloudDataConnection,
    IconCodeDots,
    IconDashboard,
    IconDiscount2,
    IconGitBranch,
    IconIdBadge2,
    IconInfoCircle,
    IconInfoCircleFilled,
    IconLanguageHiragana,
    IconLayoutGrid,
    IconLicense,
    IconPhoto,
    IconTool,
    IconUsers,
    TablerIconsProps,
} from '@tabler/icons-react';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { MiviaLogoSimple } from '@/components/Layout/MiviaLogo';
import { useVersion } from '@/features/admin/api/version';

const useStyles = createStyles((theme, _params) => {
    const icon = getStylesRef('icon');
    return {
        navbar: {
            backgroundColor: theme.colors[theme.primaryColor][8],
            borderRight: 0,
        },

        version: {
            backgroundColor: theme.colors[theme.primaryColor][7],
            color: theme.white,
            fontWeight: 700,
        },

        header: {
            paddingBottom: theme.spacing.md,
            marginBottom: px(theme.spacing.md) * 1.5,
            borderBottom: `1px solid ${theme.colors[theme.primaryColor][7]}`,
        },

        footer: {
            paddingTop: theme.spacing.md,
            marginTop: theme.spacing.md,
            borderTop: `1px solid ${theme.colors[theme.primaryColor][7]}`,
        },

        link: {
            ...theme.fn.focusStyles(),
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: theme.fontSizes.sm,
            color: theme.white,
            padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
            borderRadius: theme.radius.sm,
            fontWeight: 500,

            '&:hover': {
                backgroundColor: theme.colors[theme.primaryColor][7],
            },
        },

        linkIcon: {
            ref: icon,
            color: theme.white,
            opacity: 0.75,
            marginRight: theme.spacing.sm,
        },

        linkActive: {
            '&, &:hover': {
                backgroundColor: theme.colors[theme.primaryColor][7],
                [`& .${icon}`]: {
                    opacity: 0.9,
                },
            },
        },
    };
});

interface LinkProps {
    link: string;
    label: string;
    icon: (props: TablerIconsProps) => JSX.Element;
}

const data: LinkProps[] = [
    { link: '/admin', label: 'Dashboard', icon: IconDashboard },
    { link: '/admin/users', label: 'Users', icon: IconIdBadge2 },
    { link: '/admin/groups', label: 'Groups', icon: IconUsers },
    { link: '/admin/vouchers', label: 'Vouchers', icon: IconDiscount2 },
    { link: '/admin/licenses', label: 'Licenses', icon: IconLicense },
    { link: '/admin/resources', label: 'Resources', icon: IconCloudDataConnection },
    { link: '/admin/compute-compute-models-registry', label: 'Models', icon: IconCodeDots },
    { link: '/admin/news', label: 'MiViA News', icon: IconBrandFeedly },
    { link: '/admin/insights', label: 'Insights', icon: IconInfoCircle },
    { link: '/admin/registry', label: 'Models registry', icon: IconGitBranch },
    { link: '/admin/stripe', label: 'Stripe', icon: IconBrandStripe },
    { link: '/admin/terms', label: 'Terms', icon: IconChecklist },
    { link: '/admin/images', label: 'Images', icon: IconPhoto },
    { link: '/admin/jobs', label: 'Jobs', icon: IconLayoutGrid },
    { link: '/admin/translations', label: 'Translations', icon: IconLanguageHiragana },
    { link: '/admin/settings', label: 'Settings', icon: IconTool },
];

export const AdminNavbar = () => {
    const { classes, cx } = useStyles();
    const navigation = useNavigate();
    const location = useLocation();
    const versionQuery = useVersion();
    const [active, setActive] = useState(location.pathname);

    const links = data.map((item) => (
        <a
            className={cx(classes.link, { [classes.linkActive]: item.link === active })}
            href={item.link}
            key={item.label}
            onClick={(event) => {
                event.preventDefault();
                navigation(item.link);
                setActive(item.link);
            }}
        >
            <item.icon className={classes.linkIcon} />
            <span>{item.label}</span>
        </a>
    ));

    const version = versionQuery.data === 'dev' ? 'dev' : `v${versionQuery.data}`;

    return (
        <Navbar className={classes.navbar} height={'100%'} p="md" width={{ sm: 300 }}>
            <Navbar.Section data-cy="admin-panel-navbar" grow>
                <Group className={classes.header} position="center">
                    <Link to="/">
                        <MiviaLogoSimple variant="white" width={130} />
                    </Link>
                    <Code className={classes.version}>{version}</Code>
                </Group>
                {links}
            </Navbar.Section>

            {/*<Navbar.Section className={classes.footer}>*/}
            {/*    <a href="#" className={classes.link} onClick={(event) => event.preventDefault()}>*/}
            {/*        <SwitchHorizontal className={classes.linkIcon} />*/}
            {/*        <span>Change account</span>*/}
            {/*    </a>*/}

            {/*    <a href="#" className={classes.link} onClick={(event) => event.preventDefault()}>*/}
            {/*        <Logout className={classes.linkIcon} />*/}
            {/*        <span>Logout</span>*/}
            {/*    </a>*/}
            {/*</Navbar.Section>*/}
        </Navbar>
    );
};
