// External imports
import { useQuery } from '@tanstack/react-query';
// Internal imports
import { AppSettingsType } from 'mivia-shared';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { APP_ADMIN_SETTINGS } from '@/types/query-keys';

/**
 * Fetch the admin settings from the server.
 * @returns The admin settings.
 */
export const getAdminSettings = async (): Promise<AppSettingsType> => {
    const { data } = await axios.get('/admin/settings');
    return data;
};

type QueryFnType = typeof getAdminSettings;
type UseAdminSettingsOptions = {
    config?: QueryConfig<QueryFnType>;
};

/**
 * Custom hook to fetch and work with the admin settings.
 * @param config Configuration for the query.
 * @returns The query result containing the admin settings.
 */
export const useAdminSettings = ({ config }: UseAdminSettingsOptions = { config: {} }) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [APP_ADMIN_SETTINGS],
        queryFn: getAdminSettings,
        ...config,
    });
};
