import { Tabs } from '@mantine/core';
import React, { FC } from 'react';

import { TranslationTab } from '@/features/admin/components/translations/TranslationTab';

export const Translations: FC = () => {
    return (
        <Tabs defaultValue="en">
            <Tabs.List>
                <Tabs.Tab value="en">English</Tabs.Tab>
                <Tabs.Tab value="de">German</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel pt="xs" value="en">
                <TranslationTab language="en" />
            </Tabs.Panel>
            <Tabs.Panel pt="xs" value="de">
                <TranslationTab language="de" />
            </Tabs.Panel>
        </Tabs>
    );
};
