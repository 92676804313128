import { useMutation } from '@tanstack/react-query';

import { Group } from '@/features/admin/types/group';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { GROUPS } from '@/types/query-keys';

export interface UseMutationSetGroupAdminDto {
    id: string;
    adminId: string | null;
    name: string;
}

export const editGroup = async (data: UseMutationSetGroupAdminDto): Promise<Group> => {
    return (await axios.patch(`/admin/groups/${data.id}`, data)).data;
};

type UseSetGroupAdminOptions = {
    config?: MutationConfig<typeof editGroup>;
};

export const useEditGroup = ({ config }: UseSetGroupAdminOptions = {}) => {
    return useMutation({
        ...config,
        onSuccess: (data: Group, ...rest) => {
            queryClient.invalidateQueries({ queryKey: [GROUPS] });
        },
        mutationFn: editGroup,
    });
};
