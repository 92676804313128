import { NumberInput } from '@mantine/core';
import { useValidatedState } from '@mantine/hooks';
import React, { FunctionComponent } from 'react';

import { AdminTooltip } from '@/components/AdminTooltip';
import { useUpdatePriority } from '@/features/admin/api/update-node-priority';

interface PriorityInputProps {
    defaultValue: number;
    nodeId: string;
}

export const PriorityChangeInput: FunctionComponent<PriorityInputProps> = ({
    defaultValue,
    nodeId,
}) => {
    const updatePriority = useUpdatePriority();

    const [{ lastValidValue, value, valid }, setValue] = useValidatedState<number>(
        defaultValue,
        (value) => value !== undefined && value >= 0 && value <= 100
    );

    const handleUpdate = () => {
        if (valid && value !== defaultValue)
            updatePriority.mutate({ nodeId, priority: lastValidValue });
    };

    return (
        <div>
            <NumberInput
                hideControls
                max={100}
                min={0}
                onBlur={handleUpdate}
                onChange={(v) => setValue(v as number)}
                pl={5}
                required
                rightSection={
                    <AdminTooltip
                        label={'From 0 to 100. Higher priority nodes will be selected first.'}
                    />
                }
                styles={{
                    input: {
                        width: 50,
                        border: !valid ? '1px solid red' : undefined,
                    },
                }}
                type="number"
                value={value}
                variant="unstyled"
            />
        </div>
    );
};
