import { Box } from '@mantine/core';
import React, { FC } from 'react';

import { FilesList } from '@/features/sandbox/components/FilesList';
import { useImages } from '@/features/user-dashboard/api/list-images';

interface ImageSelectionPanelProps {
    selected: string | undefined;
    onChange: (v: string) => void;
}

export const ImageSelectionPanel: FC<ImageSelectionPanelProps> = ({ selected, onChange }) => {
    const { data: images } = useImages();

    return (
        <Box mx={'auto'} w={800}>
            <FilesList images={images || []} onChange={onChange} selected={selected} />
        </Box>
    );
};
