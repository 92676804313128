import { Button, Group, Stepper } from '@mantine/core';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateSandboxJobs } from '@/features/sandbox/api/create-sandbox-jobs';
import { ImageSelectionPanel } from '@/features/sandbox/components/ImageSelectionPanel';
import { ModelsList } from '@/features/sandbox/components/ModelsList';
import { SandboxResults } from '@/features/sandbox/components/SandboxResults';

export const SandboxStepper: FC = () => {
    const [active, setActive] = useState(0);

    const { t } = useTranslation();
    const [selectedImage, setSelectedImage] = useState<string>();
    const [selectedModels, setSelectedModels] = useState<string[]>([]);
    const { mutate: createSandboxJobs } = useCreateSandboxJobs();

    const handleRun = () => {
        if (selectedImage && selectedModels.length > 0)
            createSandboxJobs({ imageId: selectedImage, modelsIds: selectedModels });
    };

    const nextStep = () =>
        setActive((current) => {
            if (current == 1) {
                handleRun();
            }
            return current < 2 ? current + 1 : current;
        });

    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    return (
        <>
            <Stepper
                active={active}
                allowNextStepsSelect={false}
                breakpoint="sm"
                onStepClick={setActive}
            >
                <Stepper.Step
                    description={t('sandbox.firstStepDescription')}
                    label={t('sandbox.firstStepLabel')}
                >
                    <ImageSelectionPanel onChange={setSelectedImage} selected={selectedImage} />
                </Stepper.Step>
                <Stepper.Step
                    description={t('sandbox.secondStepDescription')}
                    label={t('sandbox.secondStepLabel')}
                >
                    <ModelsList onChange={setSelectedModels} selected={selectedModels} />
                </Stepper.Step>
                <Stepper.Step
                    description={t('sandbox.thirdStepDescription')}
                    label={t('sandbox.thirdStepLabel')}
                >
                    <SandboxResults />
                </Stepper.Step>
            </Stepper>

            <Group mt="xl" position="center">
                <Button onClick={prevStep} variant="default">
                    {t('sandbox.back')}
                </Button>
                {active < 2 && (
                    <Button
                        disabled={
                            (active == 0 && !selectedImage) ||
                            (active == 1 && selectedModels.length == 0) // Disable button if no image is selected or no models are selected
                        }
                        onClick={nextStep}
                    >
                        {active == 1 ? t('sandbox.run') : t('sandbox.next')}
                    </Button>
                )}
            </Group>
        </>
    );
};
