import { ScrollArea, Table, TableProps } from '@mantine/core';
import { useIntersection, useViewportSize } from '@mantine/hooks';
import React, { FunctionComponent, useEffect, useRef } from 'react';

import { useThrottleFn } from '@/hooks/useThrottleFn';

interface ScrollableTableProps extends TableProps {
    deltaHeight?: number;
    height?: number;
    loadMore?: () => void;
    hasMore?: boolean | undefined;
}

export const ScrollableTable: FunctionComponent<ScrollableTableProps> = ({
    children,
    height,
    deltaHeight = 130,
    loadMore,
    hasMore = false,
    ...props
}) => {
    const [intersectionCount, setIntersectionCount] = React.useState(0);
    const { height: viewportHeight } = useViewportSize(); // TODO: This is hack - should be done with pure flex box
    const containerRef = useRef<HTMLDivElement>(null);
    const targetHeight = height ?? viewportHeight - deltaHeight;
    const { ref, entry } = useIntersection({
        root: containerRef.current,
        threshold: 0.5,
    });
    const loadMoreThrottled = useThrottleFn(loadMore, 500);

    useEffect(() => {
        console.log(
            `ScrollableTable: hasMore=${hasMore}, isIntersecting=${entry?.isIntersecting}, intersectionCount=${intersectionCount}`
        );

        if (!hasMore) return;
        if (!entry?.isIntersecting) return;
        if (intersectionCount >= 1) {
            loadMoreThrottled();
        }
        setIntersectionCount((prev) => prev + 1);
        // eslint will complain about loadMoreThrottled, but it's ok to ignore it
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entry?.isIntersecting, hasMore]);

    return (
        <ScrollArea ref={containerRef} style={{ height: targetHeight }}>
            <Table highlightOnHover {...props}>
                {children}
            </Table>
            <div ref={ref} style={{ height: 5 }}></div>
        </ScrollArea>
    );
};
