import React, { useContext } from 'react';

import { useInfinityJobs } from '@/features/admin/api/jobs';

type JobsContextType = ReturnType<typeof useInfinityJobs>;

export const JobsContext = React.createContext<JobsContextType | undefined>(undefined);

interface JobsContextProviderProps {
    children: React.ReactNode;
}

/**
 * Provides jobs react-query context to all children
 * @param children
 * @constructor
 */
export const JobsContextProvider: React.FC<JobsContextProviderProps> = ({ children }) => {
    const jobsData = useInfinityJobs();
    return <JobsContext.Provider value={jobsData}>{children}</JobsContext.Provider>;
};

/**
 * Returns jobs context
 */
export const useJobsContext = () => {
    const context = useContext(JobsContext) as JobsContextType;
    if (!context) {
        throw new Error('useJobsContext must be used within a JobsContextProvider');
    }
    return context;
};
