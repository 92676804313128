import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { COMPUTE_MODELS_STATE } from '@/types/query-keys';

const unlockComputeModels = async (): Promise<any> => {
    return (await axios.put('/admin/compute-models-instances/unlock')).data;
};

type UseComputeModelsUnlockOptions = {
    config?: MutationConfig<typeof unlockComputeModels>;
};

export const useUnlockComputeModels = ({ config }: UseComputeModelsUnlockOptions = {}) => {
    return useMutation({
        ...config,
        mutationFn: unlockComputeModels,
        onSuccess: (data: any) => {
            queryClient.invalidateQueries([COMPUTE_MODELS_STATE]);
        },
    });
};
