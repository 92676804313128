import { useQuery } from '@tanstack/react-query';

import { TermsText } from '@/features/admin/types/terms';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ADMIN_TERMS } from '@/types/query-keys';

export const queryTerms = async (language: 'en' | 'de'): Promise<TermsText> => {
    const { data } = await axios.get(`/admin/terms/${language}`);
    return data;
};

type QueryFnType = typeof queryTerms;

type UseTermsOptions = {
    language: 'en' | 'de';
    config?: QueryConfig<QueryFnType>;
};

export const useQueryTerms = ({ config, language }: UseTermsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [ADMIN_TERMS, language],
        queryFn: () => queryTerms(language),
        ...config,
    });
};
