import React, { FC } from 'react';

import { useUserJob } from '../api/users-job';
import { UserJobs } from '../components/users/UserJobs';

interface AdminUserJobsProps {
    userId: string | undefined;
}

export const AdminUserJobs: FC<AdminUserJobsProps> = ({ userId }) => {
    const jobsQuery = useUserJob({ userId });

    return <UserJobs data={jobsQuery.data}></UserJobs>;
};
