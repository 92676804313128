import { LoadingOverlay, Tabs } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { MiviaLoader } from '@/components/MiviaLoader';
import { useComputeModels } from '@/features/admin/api/compute-models';
import { useComputeModelsState } from '@/features/admin/api/compute-models-state';
import { useUnlockComputeModels } from '@/features/admin/api/unlock-compute-models';
import { ModelUpdatingLoader } from '@/features/admin/components/compute-models-registry/ModelUpdatingLoader';

import { RegistryTable } from './RegistryTable';

export const Registry: FunctionComponent = () => {
    const { data: models, isLoading } = useComputeModels();
    const { data: isUpdating } = useComputeModelsState();
    const unlockModels = useUnlockComputeModels();

    const handleUnlockClick = () => {
        unlockModels.mutate(undefined);
    };

    if (isLoading) return <MiviaLoader />;

    return (
        <div style={{ position: 'relative' }}>
            <LoadingOverlay
                loader={<ModelUpdatingLoader onUnlockClick={handleUnlockClick} />}
                overlayBlur={1}
                visible={!!isUpdating}
            />
            <Tabs defaultValue={models?.[0]?.id} keepMounted={false}>
                <Tabs.List>
                    {models?.map((model) => (
                        <Tabs.Tab key={model.id} value={model.id}>
                            {model.displayName}
                        </Tabs.Tab>
                    ))}
                </Tabs.List>

                {models?.map((model) => (
                    <Tabs.Panel key={model.id} pt="xs" value={model.id}>
                        <RegistryTable
                            imageName={model.dockerImage}
                            modelId={model.id}
                            modelName={model.displayName}
                        />
                    </Tabs.Panel>
                ))}
            </Tabs>
        </div>
    );
};
