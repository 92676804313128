import { Bar } from '@nivo/bar';
import { BarChartDataItem } from 'mivia-shared';
import { FC } from 'react';

export interface BarChartProps {
    height?: number;
    width?: number;
    isFullSize?: boolean;
    margin?: { top: number; right: number; bottom: number; left: number };
    data: BarChartDataItem[];
    xLabel?: string;
    yLabel?: string;
    activeKey: string;
}

//  ComputedDatum<BarChartData>
const getColor = (bar: any) => {
    return bar.data.color;
    // return '#000000';
};

export const BarChart: FC<BarChartProps> = ({
    data,
    xLabel,
    yLabel,
    width,
    height,
    isFullSize = false,
    margin,
    activeKey,
}: BarChartProps) => {
    return (
        <Bar
            axisBottom={{
                tickRotation: 45,
                legend: xLabel || '',
                legendPosition: 'middle',
                legendOffset: isFullSize ? 80 : 60,
            }}
            axisLeft={{
                legend: yLabel || '',
                legendPosition: 'middle',
                legendOffset: isFullSize ? -50 : -40,
            }}
            axisRight={null}
            axisTop={null}
            colorBy="indexValue"
            colors={(bar: any) => getColor(bar)}
            data={data}
            enableLabel={false}
            groupMode="grouped"
            height={height || 280}
            indexBy="label"
            keys={[activeKey]}
            margin={margin || { top: 2, right: 20, bottom: 90, left: 60 }}
            padding={0.1}
            theme={{
                axis: {
                    ticks: {
                        text: {
                            fontSize: isFullSize ? 14 : 12,
                        },
                    },
                },
            }}
            tooltip={({ id, value, color, indexValue }) => (
                <div
                    style={{
                        padding: 12,
                        color: '#000',
                        background: '#ffffff',
                        border: `1px solid ${color}`,
                    }}
                >
                    <span>
                        {indexValue} - {value.toFixed(2)}
                    </span>
                </div>
            )}
            width={width || 450}
        />
    );
};
